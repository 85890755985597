import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core'
import React, { ChangeEvent, Ref } from 'react'
import { FaPlus } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Cropper } from 'react-cropper'
import { IconNames } from '@blueprintjs/icons'
import Ads from '../../entity/ads'

import 'cropperjs/dist/cropper.css'
import './edit.css'

class Edit extends React.Component<
    {
        history: any
        match: any
    },
    {
        loading: boolean
        loading_cropping: boolean
        cropping?: File
        cropping_portrait?: File
        name: String
        lang: String
        picture: String
        picture_portrait: String
        start: moment.Moment
        end: moment.Moment
        link: String
        description: String
        entreprise: String
        price: number
        minage: number
        maxage: number
        sexe: number
        location: {
            lat: number
            lng: number
        }
        distance: number
        country: String
        picture_changed: boolean
        picture_portrait_changed: boolean
    }
> {
    state = {
        loading: false,
        loading_cropping: false,
        picture_changed: false,
        picture_portrait_changed: false,
        cropping: undefined,
        cropping_portrait: undefined,
        start: moment(),
        end: moment().add(10, 'days'),
        link: '',
        lang: 'fr',
        name: '',
        entreprise: '',
        picture: '',
        picture_portrait: '',
        description: '',
        price: 0,
        minage: 18,
        maxage: 100,
        sexe: 4,
        location: {
            lat: 0,
            lng: 0,
        },
        distance: 100,
        country: '',
    }

    constructor(props: any) {
        super(props)

        this.cropper = React.createRef()
        this.cropper_portrait = React.createRef()

        Ads.get(this.props.match.params.id, (msg: any) => {
            if (msg['status'] === 'success') {
                this.setState({
                    start: moment(msg['data'].start),
                    end: moment(msg['data'].end),
                    link: msg['data'].link,
                    lang: msg['data'].lang,
                    name: msg['data'].name,
                    entreprise: msg['data'].entreprise,
                    picture: msg['data'].picture,
                    picture_portrait: msg['data'].picture_portrait,
                    description: msg['data'].description,
                    price: msg['data'].price,
                    minage: msg['data'].minage,
                    maxage: msg['data'].maxage,
                    sexe: msg['data'].sexe,
                    location: {
                        lat: 0,
                        lng: 0,
                    },
                    distance: msg['data'].distance,
                    country: msg['data'].country,
                })
            }
        })
    }

    cropper: Ref<HTMLImageElement>
    cropper_portrait: Ref<HTMLImageElement>

    add = async () => {
        console.log(this.state)
        let blob_picture = undefined
        if (this.state.picture_changed === true) {
            let p: any = this.state.picture
            blob_picture = await fetch(p).then((res) => res.blob())
        }

        let blob_portrait = undefined
        if (this.state.picture_portrait_changed === true) {
            let p_p: any = this.state.picture_portrait
            blob_portrait = await fetch(p_p).then((res) => res.blob())
        }

        Ads.edit(
            this.props.match.params.id,
            this.state.name,
            this.state.description,
            this.state.link,
            this.state.entreprise,
            this.state.lang,
            this.state.start.format('YYYY-MM-DDT00:00:00') + 'Z',
            this.state.end.format('YYYY-MM-DDT00:00:00') + 'Z',
            this.state.price,
            this.state.minage,
            this.state.maxage,
            this.state.sexe,
            this.state.location,
            this.state.distance,
            this.state.country,
            blob_picture,
            blob_portrait,
            (msg: any) => {
                if (msg['status'] === 'success') {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            this.props.history.push('/ad')
                        }
                    )
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }
        )
    }

    getCropData = () => {
        if (this.cropper) {
            let c: any = this.cropper
            this.setState({
                cropping: undefined,
                picture_changed: true,
                picture: c.current.cropper.getCroppedCanvas().toDataURL(),
            })
        }
    }

    getCropDataPortrait = () => {
        if (this.cropper_portrait) {
            let c: any = this.cropper_portrait
            this.setState({
                cropping_portrait: undefined,
                picture_portrait_changed: true,
                picture_portrait: c.current.cropper
                    .getCroppedCanvas()
                    .toDataURL(),
            })
        }
    }

    public render() {
        return (
            <div id="add" className="p20">
                <div id="add_header">
                    <div>
                        <h3>Modifier une publicité</h3>
                        <p>
                            Dans cette page vous pourrez modifier une publicité
                            sur l'application.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div
                        className="filter"
                        style={{
                            marginRight: 20,
                        }}
                    >
                        <h5>Image carré</h5>

                        {this.state.cropping ? (
                            <div>
                                <Cropper
                                    src={URL.createObjectURL(
                                        this.state.cropping
                                    )}
                                    style={{
                                        height: 400,
                                        width: '100%',
                                    }}
                                    onError={(err) => console.log(err)}
                                    aspectRatio={1 / 1}
                                    initialAspectRatio={1 / 1}
                                    guides={false}
                                    ref={this.cropper}
                                />

                                <ButtonLoad
                                    callback={this.getCropData}
                                    enable="secondary"
                                    loading={this.state.loading_cropping}
                                >
                                    Validate the picture
                                </ButtonLoad>
                            </div>
                        ) : null}

                        {!this.state.cropping && !this.state.picture ? (
                            <div style={{ position: 'relative' }}>
                                <input
                                    className="logo"
                                    type="file"
                                    onChange={(
                                        file: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            file.target.files &&
                                            file.target.files?.length > 0
                                        ) {
                                            this.setState({
                                                cropping: file.target.files[0],
                                            })
                                        }
                                    }}
                                />
                                <ButtonLoad
                                    callback={() => {}}
                                    enable="primary"
                                    loading={this.state.loading_cropping}
                                >
                                    Import a picture
                                </ButtonLoad>
                            </div>
                        ) : null}

                        {!this.state.cropping && this.state.picture ? (
                            <div>
                                <img
                                    style={{
                                        height: 400,
                                        width: 400,
                                    }}
                                    src={this.state.picture}
                                    alt="cropped"
                                />

                                <div style={{ position: 'relative' }}>
                                    <input
                                        className="logo"
                                        type="file"
                                        onChange={(
                                            file: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (
                                                file.target.files &&
                                                file.target.files?.length > 0
                                            ) {
                                                this.setState({
                                                    cropping:
                                                        file.target.files[0],
                                                })
                                            }
                                        }}
                                    />
                                    <ButtonLoad
                                        callback={() => {}}
                                        enable="primary"
                                        loading={this.state.loading_cropping}
                                    >
                                        Import a new picture
                                    </ButtonLoad>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div
                        className="filter"
                        style={{
                            marginRight: 20,
                        }}
                    >
                        <h5>Image portrait</h5>

                        {this.state.cropping_portrait ? (
                            <div>
                                <Cropper
                                    src={URL.createObjectURL(
                                        this.state.cropping_portrait
                                    )}
                                    style={{
                                        height: 400,
                                        width: '100%',
                                    }}
                                    onError={(err) => console.log(err)}
                                    aspectRatio={9 / 16}
                                    initialAspectRatio={9 / 16}
                                    guides={false}
                                    ref={this.cropper_portrait}
                                />

                                <ButtonLoad
                                    callback={this.getCropDataPortrait}
                                    enable="secondary"
                                    loading={this.state.loading_cropping}
                                >
                                    Validate the picture
                                </ButtonLoad>
                            </div>
                        ) : null}

                        {!this.state.cropping_portrait &&
                        !this.state.picture_portrait ? (
                            <div style={{ position: 'relative' }}>
                                <input
                                    className="logo"
                                    type="file"
                                    onChange={(
                                        file: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            file.target.files &&
                                            file.target.files?.length > 0
                                        ) {
                                            this.setState({
                                                cropping_portrait:
                                                    file.target.files[0],
                                            })
                                        }
                                    }}
                                />
                                <ButtonLoad
                                    callback={() => {}}
                                    enable="primary"
                                    loading={this.state.loading_cropping}
                                >
                                    Import a picture
                                </ButtonLoad>
                            </div>
                        ) : null}

                        {!this.state.cropping_portrait &&
                        this.state.picture_portrait ? (
                            <div>
                                <img
                                    style={{
                                        height: 400,
                                    }}
                                    src={this.state.picture_portrait}
                                    alt="cropped"
                                />

                                <div style={{ position: 'relative' }}>
                                    <input
                                        className="logo"
                                        type="file"
                                        onChange={(
                                            file: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (
                                                file.target.files &&
                                                file.target.files?.length > 0
                                            ) {
                                                this.setState({
                                                    cropping_portrait:
                                                        file.target.files[0],
                                                })
                                            }
                                        }}
                                    />
                                    <ButtonLoad
                                        callback={() => {}}
                                        enable="primary"
                                        loading={this.state.loading_cropping}
                                    >
                                        Import a new picture
                                    </ButtonLoad>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className="filter">
                        <h5>Informations</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <FormGroup
                                    helperText=""
                                    label="Nom"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: St Valentin"
                                        min={3}
                                        value={this.state.name.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                name: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Description"
                                    labelFor="text-input"
                                >
                                    <TextArea
                                        growVertically={true}
                                        large={true}
                                        maxLength={130}
                                        placeholder="Ex: Carimmat se mobilise pour votre bonheur. -15% jusqu'au 28 février. N'hésitez plus!"
                                        style={{
                                            fontSize: 14,
                                        }}
                                        className="bp3-fill"
                                        onChange={(msg) => {
                                            this.setState({
                                                description: msg.target.value,
                                            })
                                        }}
                                        value={this.state.description}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Entreprise"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: Carimmat"
                                        min={3}
                                        value={this.state.entreprise.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                entreprise: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Link"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: https://carimmat.com/test"
                                        min={3}
                                        value={this.state.link.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                link: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Prix de la campagne"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: 125"
                                        min={3}
                                        leftIcon={IconNames.EURO}
                                        type="number"
                                        value={this.state.price.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                price: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Disponibilité</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Date de début"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.start.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    start: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsStart
                                            startDate={this.state.start.toDate()}
                                            endDate={this.state.end.toDate()}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        helperText=""
                                        label="Date de fin"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.end.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    end: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsEnd
                                            startDate={this.state.start.toDate()}
                                            endDate={this.state.end.toDate()}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Conditions</h5>

                        <div className="bp3-callout bp3-intent-primary">
                            <h4 className="bp3-heading">Information</h4>
                            Ici vous pouvez définir toutes les conditions pour
                            la publicité.
                        </div>
                        <br />

                        <div className="row">
                            <FormGroup
                                helperText=""
                                label="Sexe"
                                labelFor="text-input"
                                style={{
                                    marginRight: 20,
                                }}
                            >
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.sexe.toString()}
                                        onChange={(msg: any) => {
                                            this.setState({
                                                sexe: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    >
                                        <option>Choose an item...</option>
                                        <option value="1">Homme</option>
                                        <option value="2">Femme</option>
                                        <option value="3">Autre</option>
                                        <option value="4">Tous</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </FormGroup>

                            <FormGroup
                                helperText=""
                                label="Age minimum"
                                labelFor="text-input"
                                style={{
                                    marginRight: 20,
                                }}
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Ex: 18"
                                    min={18}
                                    max={100}
                                    leftIcon={IconNames.USER}
                                    type="number"
                                    value={this.state.minage.toString()}
                                    onChange={(msg) => {
                                        this.setState({
                                            minage: parseInt(msg.target.value),
                                        })
                                    }}
                                />
                            </FormGroup>

                            <FormGroup
                                helperText=""
                                label="Age maximum"
                                labelFor="text-input"
                                style={{
                                    marginRight: 20,
                                }}
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Ex: 100"
                                    min={18}
                                    max={100}
                                    leftIcon={IconNames.USER}
                                    type="number"
                                    value={this.state.maxage.toString()}
                                    onChange={(msg) => {
                                        this.setState({
                                            maxage: parseInt(msg.target.value),
                                        })
                                    }}
                                />
                            </FormGroup>

                            <FormGroup
                                helperText=""
                                label="Langue"
                                labelFor="text-input"
                                style={{
                                    marginRight: 20,
                                }}
                            >
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.lang.toString()}
                                        onChange={(msg: any) => {
                                            this.setState({
                                                lang: msg.target.value,
                                            })
                                        }}
                                    >
                                        <option>Choose an item...</option>
                                        <option value="fr">Français</option>
                                        <option value="en">English - UK</option>
                                        <option value="us">English - US</option>
                                        <option value="es">Español</option>
                                        <option value="it">Italiana</option>
                                        <option value="ru">Pyccкий</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </FormGroup>

                            <FormGroup
                                helperText=""
                                label="Pays"
                                labelFor="text-input"
                                style={{
                                    marginRight: 20,
                                }}
                            >
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.country.toString()}
                                        onChange={(msg: any) => {
                                            this.setState({
                                                country: msg.target.value,
                                            })
                                        }}
                                    >
                                        <option>Choose an item...</option>
                                        <option value="france">France</option>
                                        <option value="belgium">
                                            Belgique
                                        </option>
                                        <option value="luxembourg">
                                            Luxembourg
                                        </option>
                                        <option value="spain">Espagne</option>
                                        <option value="italia">Italie</option>
                                        <option value="russia">Russie</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <ButtonLoad
                    loading={this.state.loading}
                    callback={() => {
                        if (!this.state.loading) {
                            this.setState(
                                {
                                    loading: true,
                                },
                                () => this.add()
                            )
                        }
                    }}
                    enable="secondary"
                >
                    <b style={{ fontSize: 14 }}>
                        <FaPlus /> &nbsp;&nbsp;&nbsp;Modifier la publicité
                    </b>
                </ButtonLoad>
            </div>
        )
    }
}

export default Edit
