import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core'
import React, { ChangeEvent, Ref } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import { Cropper } from 'react-cropper'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Autosuggest from 'react-autosuggest'
import Notifs from '../../entity/notifs'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'

import './edit.css'
import system from '../../system/system'

class Edit extends React.Component<
    {
        history: any
        match: any
    },
    {
        loading_cropping: boolean
        loading: boolean
        name: string
        titre: any
        texte: any
        picture: string
        picture_changed: boolean
        date: moment.Moment
        cropping?: File
        minage: number
        maxage: number
        premium: string
        platform: string
        activelang: string
        value: string
        link: string
        internal_screen: string
        attirance: number
        nbcibles: number
        suggestions: any
        testers: any
        testers_chosen: any
        icone: string
    }
> {
    langs = ['fr', 'es', 'gb', 'us', 'it', 'de', 'ru']

    cropper: Ref<HTMLImageElement>

    icones = [
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/tool.png',
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/gift.png',
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/eclair.png',
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/ampoule.png',
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/notification.png',
        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod/notifs/warning.png',
    ]

    constructor(props: any) {
        super(props)

        this.cropper = React.createRef()

        let textes: any = {}
        let titres: any = {}
        for (var l of this.langs) {
            textes[l] = ''
            titres[l] = ''
        }

        this.state = {
            loading: false,
            loading_cropping: false,
            picture_changed: false,
            name: '',
            titre: titres,
            texte: textes,
            picture: '',
            cropping: undefined,
            date: moment().tz(system.timezone).add(7, 'days'),
            minage: 18,
            maxage: 100,
            premium: '',
            platform: '',
            internal_screen: '',
            link: '',
            attirance: 7,
            nbcibles: 0,
            activelang: 'fr',
            value: '',
            icone: 'tool',
            suggestions: [],
            testers: [],
            testers_chosen: [],
        }
    }

    escapeRegexCharacters(str: String) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    getSuggestions(value: String) {
        const escapedValue = this.escapeRegexCharacters(value.trim())

        if (escapedValue === '') {
            return []
        }

        const regex = new RegExp('\\b' + escapedValue, 'i')

        return this.state.testers.filter((person: any) =>
            regex.test(this.getSuggestionValue(person))
        )
    }

    getSuggestionValue(suggestion: any) {
        return `${suggestion.firstname} (${suggestion.email_clair})`
    }

    renderSuggestion(suggestion: any, { query }: any) {
        const suggestionText = `${suggestion.firstname} (${suggestion.email_clair})`
        const matches = AutosuggestHighlightMatch(suggestionText, query)
        const parts = AutosuggestHighlightParse(suggestionText, matches)

        return (
            <span className={'suggestion-content ' + suggestion.twitter}>
                <span className="name">
                    {parts.map((part: any, index: number) => {
                        const className = part.highlight ? 'highlight' : null

                        return (
                            <span
                                className={className === null ? '' : className}
                                key={index}
                            >
                                {part.text}
                            </span>
                        )
                    })}
                </span>
            </span>
        )
    }

    componentDidMount() {
        Notifs.getTesters((msg: any) => {
            if (msg['status'] === 'success') {
                this.setState(
                    {
                        testers: msg['data'],
                    },
                    () =>
                        Notifs.get(this.props.match.params.id, (msg: any) => {
                            if (msg['status'] === 'success') {
                                let testers = this.state.testers
                                let testers_chosen: any = []
                                msg['data'].testers.forEach((t: String) => {
                                    if (t !== '') {
                                        testers_chosen.push(
                                            testers.find(
                                                (item: any) => item.id === t
                                            )
                                        )
                                        testers = testers.filter(
                                            (item: any) => item.id !== t
                                        )
                                    }
                                    console.log(t, testers_chosen, testers)
                                })

                                this.setState(
                                    {
                                        link: msg['data'].link,
                                        name: msg['data'].name,
                                        texte: msg['data'].texte,
                                        internal_screen:
                                            msg['data'].internal_screen,
                                        attirance: msg['data'].genre,
                                        minage: msg['data'].minage,
                                        maxage: msg['data'].maxage,
                                        premium: msg['data'].premium,
                                        platform: msg['data'].platform,
                                        icone: msg['data'].icone,
                                        titre: msg['data'].title,
                                        date: moment(msg['data'].send_date),
                                        testers_chosen,
                                        testers,
                                        picture: msg['data'].picture,
                                    },
                                    () => {
                                        this.nbTargets()
                                    }
                                )
                            }
                        })
                )
            }
        })
    }

    onSuggestionsFetchRequested = ({ value }: any) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }

    getCropData = () => {
        if (this.cropper) {
            let c: any = this.cropper
            this.setState({
                cropping: undefined,
                picture_changed: true,
                picture: c.current.cropper
                    .getCroppedCanvas({
                        maxWidth: 800,
                        maxHeight: 500,
                        imageSmoothingEnabled: true,
                        imageSmoothingQuality: 'high',
                    })
                    .toDataURL(),
            })
        }
    }

    nbTargets = () => {
        let langs = []
        for (let t in this.state.titre) {
            if (this.state.titre[t] !== '') {
                langs.push(t)
            }
        }

        Notifs.getNbTargets(
            this.state.minage,
            this.state.maxage,
            this.state.platform,
            this.state.premium,
            this.state.attirance,
            langs,
            (msg: any) => {
                if (msg['status'] === 'success') {
                    this.setState({
                        nbcibles: msg['data'],
                    })
                }
            }
        )
    }

    edit = async () => {
        let blob_picture
        if (this.state.picture_changed) {
            let p = this.state.picture
            blob_picture = await fetch(p)
                .then((res) => res.blob())
                .catch(() => {
                    return undefined
                })
        }

        let t = []
        for (let tester of this.state.testers_chosen) {
            t.push(tester.id)
        }

        Notifs.edit(
            this.props.match.params.id,
            this.state.name,
            this.state.titre,
            this.state.texte,
            this.state.date.tz('utc').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z',
            this.state.minage,
            this.state.maxage,
            this.state.premium,
            this.state.platform,
            this.state.internal_screen,
            this.state.link,
            this.state.attirance,
            this.state.nbcibles,
            this.state.icone,
            t,
            blob_picture,
            (msg: any) => {
                if (msg['status'] === 'success') {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            this.props.history.push('/notifs')
                        }
                    )
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }
        )
    }

    onChange = (event: any, { newValue, method }: any) => {
        this.setState({
            value: newValue,
        })
    }

    public render() {
        return (
            <div id="edit_campaign" className="p20">
                <div id="edit_header">
                    <div>
                        <h3>Ajouter une campagne de notification</h3>
                        <p>
                            Dans cette page vous pourrez ajouter une campagne de
                            notification.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>1. Notification</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <div className="row">
                                    <div className="column">
                                        <FormGroup
                                            helperText=""
                                            label="Nom de la campagne"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Ex: Campagne St Valentin"
                                                value={this.state.name.toString()}
                                                onChange={(msg) => {
                                                    let name = msg.target.value
                                                    this.setState({
                                                        name,
                                                    })
                                                }}
                                            />
                                        </FormGroup>

                                        <div className="row">
                                            {this.langs.map((l) => {
                                                return (
                                                    <button
                                                        onClick={() => {
                                                            this.setState({
                                                                activelang: l,
                                                            })
                                                        }}
                                                        className={
                                                            'lang' +
                                                            (this.state
                                                                .activelang ===
                                                            l
                                                                ? ' active'
                                                                : '')
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                'https://flagcdn.com/80x60/' +
                                                                l.toLowerCase() +
                                                                '.png'
                                                            }
                                                            height="20"
                                                            alt="lang"
                                                        />
                                                    </button>
                                                )
                                            })}
                                        </div>

                                        {this.langs.map((l) => {
                                            if (l !== this.state.activelang) {
                                                return <></>
                                            } else
                                                return (
                                                    <div className="content">
                                                        <FormGroup
                                                            helperText=""
                                                            label="Titre"
                                                            labelFor="text-input"
                                                        >
                                                            <InputGroup
                                                                id="text-input"
                                                                placeholder="Ex: St Valentin c'est aujourd'hui!"
                                                                maxLength={60}
                                                                value={this.state.titre[
                                                                    l
                                                                ].toString()}
                                                                onChange={(
                                                                    msg
                                                                ) => {
                                                                    let titre =
                                                                        this
                                                                            .state
                                                                            .titre
                                                                    titre[l] =
                                                                        msg.target.value
                                                                    this.setState(
                                                                        {
                                                                            titre,
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup
                                                            helperText=""
                                                            label="Texte"
                                                            labelFor="text-input"
                                                        >
                                                            <TextArea
                                                                growVertically={
                                                                    true
                                                                }
                                                                large={true}
                                                                maxLength={178}
                                                                placeholder="Ex: Carimmat se mobilise pour votre bonheur. -15% jusqu'au 28 février. N'hésitez plus!"
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                                className="bp3-fill"
                                                                onChange={(
                                                                    msg
                                                                ) => {
                                                                    let texte =
                                                                        this
                                                                            .state
                                                                            .texte
                                                                    texte[l] =
                                                                        msg.target.value
                                                                    this.setState(
                                                                        {
                                                                            texte,
                                                                        }
                                                                    )
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .texte[
                                                                        l
                                                                    ]
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                )
                                        })}

                                        <FormGroup
                                            label="Lien externe"
                                            labelFor="text-input"
                                            style={{
                                                marginTop: 15,
                                            }}
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Ex: https://google.fr"
                                                value={this.state.link}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        link: msg.target.value,
                                                    })
                                                }}
                                            />
                                        </FormGroup>

                                        <div className="column">
                                            <p>Lien vers une page</p>
                                            <div className="bp3-html-select">
                                                <select
                                                    value={
                                                        this.state
                                                            .internal_screen
                                                    }
                                                    onChange={(msg) => {
                                                        this.setState({
                                                            internal_screen:
                                                                msg.target
                                                                    .value,
                                                        })
                                                    }}
                                                >
                                                    <option value="">
                                                        Aucune page
                                                    </option>
                                                    <option value="notifications">
                                                        La page des
                                                        notifications
                                                    </option>
                                                    <option value="convs">
                                                        La page des
                                                        conversations
                                                    </option>
                                                    <option value="boutique">
                                                        La page de la boutique
                                                    </option>
                                                    <option value="boutique_abonnement">
                                                        La page de la boutique
                                                        abonnements
                                                    </option>
                                                    <option value="swipeup">
                                                        La page swipeup
                                                    </option>
                                                    <option value="profile">
                                                        La page du profile
                                                    </option>
                                                    <option value="passeport">
                                                        La page passeport
                                                    </option>
                                                    <option value="vehicules">
                                                        La page des véhicules
                                                    </option>
                                                </select>
                                                <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                            </div>
                                        </div>

                                        <div
                                            className="column"
                                            style={{
                                                marginTop: 15,
                                            }}
                                        >
                                            <p>Icone</p>
                                            <div
                                                className="row"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                            >
                                                {this.icones.map(
                                                    (link: string) => {
                                                        return (
                                                            <button
                                                                className={
                                                                    'icone' +
                                                                    (this.state
                                                                        .icone ===
                                                                    link
                                                                        ? ' active'
                                                                        : '')
                                                                }
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            icone: link,
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                <img
                                                                    width={26}
                                                                    height={26}
                                                                    alt="tool"
                                                                    src={link}
                                                                />
                                                            </button>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="filter"
                                            style={{
                                                marginRight: 20,
                                            }}
                                        >
                                            <p>Image (optionnel)</p>

                                            {this.state.cropping ? (
                                                <div>
                                                    <Cropper
                                                        src={URL.createObjectURL(
                                                            this.state.cropping
                                                        )}
                                                        style={{
                                                            height: 400,
                                                            width: '100%',
                                                        }}
                                                        onError={(err) =>
                                                            console.log(err)
                                                        }
                                                        aspectRatio={2 / 1}
                                                        initialAspectRatio={
                                                            2 / 1
                                                        }
                                                        guides={false}
                                                        ref={this.cropper}
                                                    />

                                                    <ButtonLoad
                                                        callback={
                                                            this.getCropData
                                                        }
                                                        enable="secondary"
                                                        loading={
                                                            this.state
                                                                .loading_cropping
                                                        }
                                                    >
                                                        Validate the picture
                                                    </ButtonLoad>
                                                </div>
                                            ) : null}

                                            {!this.state.cropping &&
                                            !this.state.picture ? (
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <input
                                                        className="logo"
                                                        type="file"
                                                        onChange={(
                                                            file: ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            if (
                                                                file.target
                                                                    .files &&
                                                                file.target
                                                                    .files
                                                                    ?.length > 0
                                                            ) {
                                                                this.setState({
                                                                    cropping:
                                                                        file
                                                                            .target
                                                                            .files[0],
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <ButtonLoad
                                                        callback={() => {}}
                                                        enable="primary"
                                                        loading={
                                                            this.state
                                                                .loading_cropping
                                                        }
                                                    >
                                                        Import a picture
                                                    </ButtonLoad>
                                                </div>
                                            ) : null}

                                            {!this.state.cropping &&
                                            this.state.picture ? (
                                                <div>
                                                    <img
                                                        style={{
                                                            height: 300,
                                                        }}
                                                        src={this.state.picture.replace(
                                                            'http://cdn-eu.carimmat.com',
                                                            'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                                        )}
                                                        alt="cropped"
                                                    />

                                                    <div
                                                        style={{
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <input
                                                            className="logo"
                                                            type="file"
                                                            onChange={(
                                                                file: ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                if (
                                                                    file.target
                                                                        .files &&
                                                                    file.target
                                                                        .files
                                                                        ?.length >
                                                                        0
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            cropping:
                                                                                file
                                                                                    .target
                                                                                    .files[0],
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                        <ButtonLoad
                                                            callback={() => {}}
                                                            enable="primary"
                                                            loading={
                                                                this.state
                                                                    .loading_cropping
                                                            }
                                                        >
                                                            Import a new picture
                                                        </ButtonLoad>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    {!this.state.picture ? (
                                        <div className="column mockup">
                                            <img
                                                width={350}
                                                alt="mockup_push_notif"
                                                src={
                                                    '/assets/images/mockup_push_notif.jpg'
                                                }
                                            />
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 70,
                                                    left: 40,
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {
                                                    this.state.titre[
                                                        this.state.activelang
                                                    ]
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 85,
                                                    left: 40,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {this.state.texte.length > 60
                                                    ? this.state.texte.substring(
                                                          0,
                                                          60
                                                      ) + '...'
                                                    : this.state.texte[
                                                          this.state.activelang
                                                      ]}
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 230,
                                                    left: 45,
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {
                                                    this.state.titre[
                                                        this.state.activelang
                                                    ]
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 245,
                                                    left: 45,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {this.state.texte.length > 60
                                                    ? this.state.texte.substring(
                                                          0,
                                                          60
                                                      ) + '...'
                                                    : this.state.texte[
                                                          this.state.activelang
                                                      ]}
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {this.state.picture ? (
                                        <div className="column mockup">
                                            <img
                                                width={350}
                                                alt="mockup_push_notif"
                                                src={
                                                    '/assets/images/mockup_push_notif_image.jpg'
                                                }
                                            />
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 70,
                                                    left: 40,
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {
                                                    this.state.titre[
                                                        this.state.activelang
                                                    ]
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 85,
                                                    left: 40,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {this.state.texte.length > 60
                                                    ? this.state.texte.substring(
                                                          0,
                                                          60
                                                      ) + '...'
                                                    : this.state.texte[
                                                          this.state.activelang
                                                      ]}
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 278,
                                                    left: 45,
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {
                                                    this.state.titre[
                                                        this.state.activelang
                                                    ]
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 293,
                                                    left: 45,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {this.state.texte.length > 60
                                                    ? this.state.texte.substring(
                                                          0,
                                                          60
                                                      ) + '...'
                                                    : this.state.texte[
                                                          this.state.activelang
                                                      ]}
                                            </span>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 103,
                                                    left: 41,
                                                    fontSize: 10,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: 51,
                                                        width: 265,
                                                        objectFit: 'cover',
                                                    }}
                                                    src={this.state.picture}
                                                    alt="cropped_mockup_android"
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 310,
                                                    left: 45,
                                                    fontSize: 10,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: 51,
                                                        width: 258,
                                                        objectFit: 'cover',
                                                    }}
                                                    src={this.state.picture}
                                                    alt="cropped_mockup_ios"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>2. Cible</h5>

                        <div className="filter_body">
                            <FormGroup
                                helperText="18 à 100"
                                label="Âge minimum"
                                labelFor="text-input"
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Âge minimum des utilisateurs"
                                    type="number"
                                    max={99}
                                    min={18}
                                    value={this.state.minage.toString()}
                                    onChange={(msg) => {
                                        this.setState(
                                            {
                                                minage: parseInt(
                                                    msg.target.value
                                                ),
                                            },
                                            () => {
                                                this.nbTargets()
                                            }
                                        )
                                    }}
                                />
                            </FormGroup>
                            <FormGroup
                                helperText="18 à 100"
                                label="Âge maximum"
                                labelFor="text-input"
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Âge maximum des utilisateurs"
                                    type="number"
                                    max={100}
                                    min={19}
                                    value={this.state.maxage.toString()}
                                    onChange={(msg) => {
                                        this.setState(
                                            {
                                                maxage: parseInt(
                                                    msg.target.value
                                                ),
                                            },
                                            () => {
                                                this.nbTargets()
                                            }
                                        )
                                    }}
                                />
                            </FormGroup>
                            <div className="column">
                                <p>Genre</p>
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.attirance}
                                        onChange={(msg) => {
                                            this.setState(
                                                {
                                                    attirance: parseInt(
                                                        msg.target.value
                                                    ),
                                                },
                                                () => {
                                                    this.nbTargets()
                                                }
                                            )
                                        }}
                                    >
                                        <option value="7">Tout le monde</option>
                                        <option value="1">Homme</option>
                                        <option value="2">Femme</option>
                                        <option value="3">
                                            Homme et Femme
                                        </option>
                                        <option value="4">Autre</option>
                                        <option value="5">
                                            Homme et Autre
                                        </option>
                                        <option value="6">
                                            Femme et Autre
                                        </option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </div>
                        </div>

                        <div className="filter_body">
                            <div className="column">
                                <p>Plateforme</p>
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.platform}
                                        onChange={(msg) => {
                                            this.setState(
                                                {
                                                    platform: msg.target.value,
                                                },
                                                () => {
                                                    this.nbTargets()
                                                }
                                            )
                                        }}
                                    >
                                        <option value="">Tout le monde</option>
                                        <option value="android">
                                            Que les androids
                                        </option>
                                        <option value="ios">Que les ios</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </div>
                            <div className="column">
                                <p>Premium</p>
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.premium}
                                        onChange={(msg) => {
                                            this.setState(
                                                {
                                                    premium: msg.target.value,
                                                },
                                                () => {
                                                    this.nbTargets()
                                                }
                                            )
                                        }}
                                    >
                                        <option value="">Tout le monde</option>
                                        <option value="yes">
                                            Que les premiums
                                        </option>
                                        <option value="no">
                                            Que les non premiums
                                        </option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>3. Planification</h5>

                        <div className="filter_body">
                            <FormGroup
                                helperText=""
                                label="Date d'envoi (UTC Paris)"
                                labelFor="text-input"
                            >
                                <DatePicker
                                    selected={this.state.date.toDate()}
                                    onChange={(date) =>
                                        this.setState({
                                            date: moment(date?.toString()),
                                        })
                                    }
                                    showTimeInput={true}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    selectsStart
                                    startDate={this.state.date.toDate()}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>4. Testeurs</h5>
                        <div
                            className="bp3-callout bp3-intent-primary"
                            style={{ marginBottom: 15 }}
                        >
                            <h4 className="bp3-heading">Information</h4>
                            Ajouter des testeurs est important. Vous pourrez
                            tester la notification directement sur leurs
                            téléphones avant le grand envoi. Si vous ne trouvez
                            pas l'utilisateur, il se peut qu'il n'ait pas de
                            token de notification.
                        </div>

                        <Autosuggest
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                            }
                            onSuggestionSelected={(e, { suggestion }: any) => {
                                let t = this.state.testers_chosen
                                t.push(suggestion)

                                let testers = this.state.testers.filter(
                                    (item: any) => item.id !== suggestion.id
                                )

                                this.setState({
                                    value: '',
                                    testers_chosen: t,
                                    testers,
                                })
                            }}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={{
                                placeholder:
                                    "Entrez le prénom ou l'email du testeur",
                                value: this.state.value,
                                onChange: this.onChange,
                            }}
                        />

                        <table className="tester">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Testeur</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            {this.state.testers_chosen.map((testeur: any) => {
                                return (
                                    <tr key={testeur.id}>
                                        <td>
                                            <img
                                                alt="avatar"
                                                width={40}
                                                height={40}
                                                src={testeur.avatar}
                                            />
                                        </td>
                                        <td>
                                            <b>{testeur.firstname}</b>
                                            <br />
                                            <span>{testeur.email_clair}</span>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    let t = this.state.testers
                                                    t.push(testeur)

                                                    let testers_chosen =
                                                        this.state.testers_chosen.filter(
                                                            (item: any) =>
                                                                item.id !==
                                                                testeur.id
                                                        )

                                                    this.setState({
                                                        testers: t,
                                                        testers_chosen,
                                                    })
                                                }}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>5. Résumé</h5>
                        <div className="bp3-callout bp3-intent-primary">
                            <h4 className="bp3-heading">Information</h4>
                            Au vu des critères selectionnés voici le nombre
                            d'utilisateurs concernés.
                        </div>
                        <div id="nbtarget">{this.state.nbcibles}</div> cibles
                    </div>
                </div>

                <ButtonLoad
                    loading={this.state.loading}
                    callback={() => {
                        if (!this.state.loading) {
                            this.setState(
                                {
                                    loading: true,
                                },
                                () => this.edit()
                            )
                        }
                    }}
                    enable="secondary"
                >
                    <b style={{ fontSize: 14 }}>
                        <FaPlus /> &nbsp;&nbsp;&nbsp;Modifier la campagne de
                        notification
                    </b>
                </ButtonLoad>
            </div>
        )
    }
}

export default Edit
