import React from 'react'
import Widget from '../widget/widget'

import './widget-stat-nombre.css'

class WidgetStatNombre extends React.Component<
    {
        loading: boolean
        title: String
        subtitle: String
        footer_title: String
        value: number
        footer_value: number
        color: String
    },
    {}
> {
    render() {
        return (
            <Widget loading={this.props.loading}>
                <div className="widget-stat-nombre">
                    <div>
                        <h3>{this.props.title}</h3>
                        <div></div>
                    </div>
                    <div className="center_value">
                        <h1 style={{ color: '' + this.props.color }}>
                            {this.props.value}
                        </h1>
                        <h2 style={{ color: '' + this.props.color }}>
                            {this.props.subtitle}
                        </h2>
                    </div>
                    <div className="footer">
                        {this.props.footer_title}:
                        <b style={{ marginLeft: 10, fontSize: 14 }}>
                            {this.props.footer_value}
                        </b>
                    </div>
                </div>
            </Widget>
        )
    }
}

export default WidgetStatNombre
