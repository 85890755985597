import { Button, FormGroup } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'
import { FaPlay } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import WidgetStatNombreBars from '../../components/widget-stat-nombre-bars/widget-stat-nombre-bars'
import WidgetStatNombreGraph from '../../components/widget-stat-nombre-graph/widget-stat-nombre-graph'
import WidgetStatNombreSmall from '../../components/widget-stat-nombre-small/widget-stat-nombre-small'
import Stats from '../../entity/stats'
import { arrondi } from '../../system/functions'
import DatePicker from 'react-datepicker'

import './financial.css'

class Financial extends React.Component<
    {},
    {
        loading: boolean
        filtre: boolean
        abonnementsactifs: number
        rateuserpremium: number
        nbresiliations: number
        nbremboursements: number
        nbpayments: number
        nbpremiumgifted: number
        volumebrutpremium: number
        volumebrutadmob: number
        volumebrutcredits: number
        volumebrut: number
        volumenet: number
        volumebrutios: number
        volumebrutstripe: number
        volumebrutandroid: number
        depensesparclient: number
        clientuniques: number
        nouveauxclients: number
        startdate: moment.Moment
        enddate: moment.Moment
        produits: {}
        churnrate: number
        nbachatperday: any
        lastmonth: any
    }
> {
    state = {
        loading: true,
        filtre: false,
        nbachatperday: [],
        abonnementsactifs: 0,
        rateuserpremium: 0,
        nbpremiumtotal: 0,
        nbpayments: 0,
        nbpremiumgifted: 0,
        nouveauxclients: 0,
        nbresiliations: 0,
        nbremboursements: 0,
        volumebrut: 0.0,
        volumenet: 0.0,
        volumebrutstripe: 0.0,
        volumebrutandroid: 0.0,
        volumebrutadmob: 0.0,
        volumebrutpremium: 0.0,
        volumebrutcredits: 0.0,
        volumebrutios: 0.0,
        startdate: moment().subtract(30, 'days'),
        enddate: moment(),
        churnrate: 0.0,
        clientuniques: 0,
        depensesparclient: 0,
        lastmonth: {
            abonnementsactifs: 0,
            rateuserpremium: 0,
            nbpremiumtotal: 0,
            nbpayments: 0,
            nbpremiumgifted: 0,
            nouveauxclients: 0,
            nbresiliations: 0,
            nbremboursements: 0,
            volumebrut: 0.0,
            volumenet: 0.0,
            volumebrutstripe: 0.0,
            volumebrutandroid: 0.0,
            volumebrutadmob: 0.0,
            volumebrutpremium: 0.0,
            volumebrutcredits: 0.0,
            volumebrutios: 0.0,
        },
        produits: {
            '30creditsandroid': 0,
            '30creditsios': 0,
            '30creditstotal': 0,
            '30creditsstripe': 0,
            '200creditsandroid': 0,
            '200creditsios': 0,
            '200creditstotal': 0,
            '200creditsstripe': 0,
            '500creditsandroid': 0,
            '500creditstotal': 0,
            '500creditsios': 0,
            '500creditsstripe': 0,
            '1moisabonnementtotal': 0,
            '1moisabonnementios': 0,
            '1moisabonnementstripe': 0,
            '1moisabonnementandroid': 0,
            '3moisabonnementios': 0,
            '3moisabonnementandroid': 0,
            '3moisabonnementstripe': 0,
            '3moisabonnementtotal': 0,
            '6moisabonnementandroid': 0,
            '6moisabonnementtotal': 0,
            '6moisabonnementstripe': 0,
            '6moisabonnementios': 0,
        },
    }

    componentDidMount() {
        this.stats()
    }

    stats = () => {
        Stats.financial(
            this.state.startdate.format('YYYY-MM-DDT00:00:00') + 'Z',
            this.state.enddate.format('YYYY-MM-DDT23:59:59Z') + 'Z',
            (res: any) => {
                let nbachatperday: any = []

                let i = ''
                for (i in res.data.nbachatperday) {
                    nbachatperday.push({
                        date: i,
                        value: res.data.nbachatperday[i],
                    })
                }

                nbachatperday = nbachatperday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                this.setState({
                    lastmonth: res.data.lastmonth,
                    abonnementsactifs: res.data.abonnementsactifs,
                    nbremboursements: res.data.nbrefund,
                    nbresiliations: res.data.nbresiliation,
                    nbpayments: res.data.nbpayments,
                    nouveauxclients: res.data.nouveauxclients,
                    volumenet: res.data.volumenet,
                    volumebrut: res.data.volumebrut,
                    nbpremiumgifted: res.data.nbpremiumgifted,
                    volumebrutcredits: res.data.volumebrutcredits,
                    volumebrutpremium: res.data.volumebrutpremium,
                    volumebrutadmob: res.data.volumebrutadmob,
                    produits: res.data.produits,
                    volumebrutios: res.data.volumebrutios,
                    volumebrutandroid: res.data.volumebrutandroid,
                    volumebrutstripe: res.data.volumebrutstripe,
                    depensesparclient: res.data.depensesparclient,
                    clientuniques: res.data.clientuniques,
                    loading: false,
                    nbachatperday,
                })
            }
        )
    }

    public render() {
        let taux_retention = 0
        let churnrate = 0
        let duree_vie = 0
        if (this.state.lastmonth != null) {
            taux_retention =
                (this.state.abonnementsactifs - this.state.nouveauxclients) /
                this.state.lastmonth.abonnementsactifs
            churnrate = 1 - taux_retention
            duree_vie = 1 / churnrate
        }

        return (
            <div id="financial">
                <div id="financial_header">
                    <div>
                        <h3>Analytics financial</h3>
                        <p>
                            Retrouver toutes les stats nécessaire à la partie
                            financière.
                        </p>
                    </div>
                    <Button
                        onClick={() =>
                            this.setState({
                                filtre: !this.state.filtre,
                            })
                        }
                        style={{ height: 30 }}
                    >
                        Filtre
                    </Button>
                </div>

                {this.state.filtre ? (
                    <div className="filter">
                        <h5>Filtre</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <FormGroup
                                    helperText=""
                                    label="Date de début"
                                    labelFor="text-input"
                                >
                                    <DatePicker
                                        selected={this.state.startdate.toDate()}
                                        onChange={(date) =>
                                            this.setState({
                                                startdate: moment(
                                                    date?.toString()
                                                ),
                                            })
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={this.state.startdate.toDate()}
                                        endDate={this.state.enddate.toDate()}
                                    />
                                </FormGroup>
                                <FormGroup
                                    helperText=""
                                    label="Date de fin"
                                    labelFor="text-input"
                                >
                                    <DatePicker
                                        selected={this.state.enddate.toDate()}
                                        onChange={(date) =>
                                            this.setState({
                                                enddate: moment(
                                                    date?.toString()
                                                ),
                                            })
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={this.state.startdate.toDate()}
                                        endDate={this.state.enddate.toDate()}
                                        minDate={this.state.startdate.toDate()}
                                    />
                                </FormGroup>

                                <ButtonLoad
                                    loading={this.state.loading}
                                    callback={() => {
                                        this.setState(
                                            {
                                                loading: true,
                                                filtre: false,
                                            },
                                            () => {
                                                this.stats()
                                            }
                                        )
                                    }}
                                    enable="secondary"
                                >
                                    <b style={{ fontSize: 14 }}>
                                        <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA
                                        RECHERCHE
                                    </b>
                                </ButtonLoad>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.nbachatperday}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Evolution du nombre d'achats"
                        value={this.state.nbpayments}
                    />

                    <WidgetStatNombreBars
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.produits}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Type d'achat acheté"
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Chiffre d'affaire brut"
                        value={arrondi(this.state.volumebrut, 2)}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Chiffre d'affaire net"
                        value={arrondi(this.state.volumenet, 2)}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="CA généré via les premiums"
                        value={arrondi(this.state.volumebrutpremium, 2)}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="CA généré via les crédits"
                        value={arrondi(this.state.volumebrutcredits, 2)}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        help="0.0035 par pub visionné"
                        loading={this.state.loading}
                        title="CA généré via des pubs admobs"
                        value={arrondi(this.state.volumebrutadmob, 2)}
                        after_value="€"
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Paiement sur stripe EUR"
                        value={this.state.volumebrutstripe}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Paiement sur ios EUR"
                        value={this.state.volumebrutios}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Paiement sur android EUR"
                        value={this.state.volumebrutandroid}
                        after_value="€"
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        help="Corresponds aux abonnements qui vont continuer le mois prochain"
                        loading={this.state.loading}
                        title="Nombre d'abonnement actifs"
                        value={this.state.abonnementsactifs}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de nouveaux clients"
                        value={this.state.nouveauxclients}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de clients uniques"
                        value={this.state.clientuniques}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de résiliations"
                        value={this.state.nbresiliations}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de remboursements"
                        value={this.state.nbremboursements}
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        help="Total des revenus (CA) / Nombre de paiement"
                        loading={this.state.loading}
                        title="Revenus moyens par abonnement (panier moyen)"
                        value={arrondi(
                            this.state.volumebrut / this.state.nbpayments,
                            2
                        )}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        help="Nombre total de commande / Nombre
                        de clients uniques"
                        loading={this.state.loading}
                        title="Fréquence d'achat"
                        value={arrondi(
                            this.state.nbpayments / this.state.clientuniques,
                            2
                        )}
                    />

                    <WidgetStatNombreSmall
                        help="Panier moyen * Fréquence d'achat"
                        loading={this.state.loading}
                        title="Valeur du client"
                        value={arrondi(
                            (this.state.nbpayments / this.state.clientuniques) *
                                (this.state.volumebrut / this.state.nbpayments),
                            2
                        )}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        help="Nombre d'abonnements actif * Revenus moyen par abonnement"
                        loading={this.state.loading}
                        title="MRR"
                        value={arrondi(
                            this.state.abonnementsactifs *
                                (this.state.volumebrut / this.state.nbpayments),
                            2
                        )}
                        after_value="€"
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        help="(Nombre d'abonnement en cours - nombre de nouveaux clients) / Nombre d'abonnement en cours du mois précédent"
                        loading={this.state.loading}
                        title="Taux de rétention"
                        after_value={'%'}
                        value={
                            this.state.lastmonth
                                ? arrondi(100 * taux_retention, 2)
                                : 'Impossible'
                        }
                    />

                    <WidgetStatNombreSmall
                        help="1 - Taux de rétention"
                        loading={this.state.loading}
                        title="Churn rate"
                        after_value={'%'}
                        value={
                            this.state.lastmonth
                                ? arrondi(100 * churnrate, 2)
                                : 'Impossible'
                        }
                    />

                    <WidgetStatNombreSmall
                        help="1 / (1 - Taux de rétention)"
                        loading={this.state.loading}
                        title="Durée de vie du client (en mois)"
                        value={
                            this.state.lastmonth
                                ? arrondi(duree_vie, 2)
                                : 'Impossible'
                        }
                    />

                    <WidgetStatNombreSmall
                        help="Fréquence d'achat * Revenus moyen par abonnement * Durée de vie du client"
                        loading={this.state.loading}
                        title="LTV"
                        after_value="€"
                        value={
                            this.state.lastmonth
                                ? arrondi(
                                      duree_vie *
                                          (this.state.nbpayments /
                                              this.state.clientuniques) *
                                          (this.state.volumebrut /
                                              this.state.nbpayments),
                                      2
                                  )
                                : 'Impossible'
                        }
                    />
                </div>
            </div>
        )
    }
}

export default Financial
