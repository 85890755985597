import React from 'react'
import { FaHourglass } from 'react-icons/fa'

import './widget-title.css'

export default function WidgetTitle(props: {
    loading: boolean
    title: string
    children?: React.ReactNode
}) {
    return (
        <div className="widget-title">
            <div className="title">{props.title}</div>
            <div className="body">
                {props.loading ? (
                    <FaHourglass className="spin" size={35} />
                ) : (
                    props.children
                )}
            </div>
        </div>
    )
}
