import moment from 'moment'
import React from 'react'
import Ads from '../../entity/ads'
import system from '../../system/system'
import ReactECharts from 'echarts-for-react'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { FaEdit, FaEye, FaEyeSlash, FaPlus } from 'react-icons/fa'

import './ad.css'

class Ad extends React.Component<
    any,
    {
        ads: any
        show: boolean
        preview: any | null
    }
> {
    state = {
        ads: [],
        show: false,
        preview: null,
    }

    // Init page
    componentDidMount() {
        this.getAds()
    }

    // Get ads
    getAds = () => {
        Ads.ads(
            moment().subtract(31, 'days').format('YYYY-MM-DDT00:00:00.000') +
                'Z',
            moment().format('YYYY-MM-DDT23:59:59.000') + 'Z',
            (res: any) => {
                this.setState({
                    ads: res.data.ads,
                })
            }
        )
    }

    enable = (_id: string) => {
        Ads.enable(_id, (msg: any) => {
            if (msg.data === true) {
                let ads: any = this.state.ads
                for (let u in ads) {
                    if (ads[u].stat._id === _id) {
                        ads[u].stat.enable = true
                    }
                }

                this.setState({
                    ads,
                })
            }
        })
    }

    disable = (_id: string) => {
        Ads.disable(_id, (msg: any) => {
            if (msg.data === true) {
                let ads: any = this.state.ads
                for (let u in ads) {
                    if (ads[u].stat._id === _id) {
                        ads[u].stat.enable = false
                    }
                }

                this.setState({
                    ads,
                })
            }
        })
    }

    public render() {
        return (
            <div id="ads" className="p20">
                <div id="ads_header">
                    <div>
                        <h3>Publicités</h3>
                        <p>
                            Retrouver toutes les publicités présentes dans l'app
                        </p>
                    </div>

                    <div>
                        {!this.state.show ? (
                            <Button
                                intent="none"
                                onClick={() => {
                                    this.setState({
                                        show: true,
                                    })
                                }}
                                style={{ height: 30, marginRight: 30 }}
                            >
                                <FaEye /> Afficher les désactivés et terminés
                            </Button>
                        ) : null}

                        <Link to="/ad/add" className="disabled">
                            <Button
                                intent="primary"
                                onClick={() => {}}
                                style={{ height: 30, marginRight: 30 }}
                            >
                                <FaPlus /> Ajouter une publicité
                            </Button>
                        </Link>
                    </div>
                </div>

                {this.state.preview !== null ? (
                    <div>
                        <h2>Preview de la pub</h2>
                        <button
                            onClick={() => {
                                this.setState({
                                    preview: null,
                                })
                            }}
                        >
                            Fermer
                        </button>

                        <div
                            className="row"
                            style={{
                                width: 800,
                                marginTop: 10,
                                position: 'relative',
                                marginBottom: 30,
                            }}
                        >
                            <img
                                width={800}
                                alt="mockup_ads"
                                src={'/assets/images/mockup_ads.png'}
                            />
                            <a
                                target={'_blank'}
                                href={(this.state.preview as any).stat.picture}
                                style={{
                                    marginRight: 15,
                                    position: 'absolute',
                                    top: 256,
                                    right: 223,
                                }}
                            >
                                <img
                                    style={{
                                        width: 86,
                                        height: 91,
                                        borderRadius: 5,
                                    }}
                                    src={(
                                        this.state.preview as any
                                    ).stat.picture.replace(
                                        'http://cdn-eu.carimmat.com',
                                        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                    )}
                                    alt="users around"
                                />
                            </a>

                            <a
                                target={'_blank'}
                                href={(this.state.preview as any).stat.picture}
                                style={{
                                    marginRight: 15,
                                    position: 'absolute',
                                    top: 70,
                                    left: 102,
                                }}
                            >
                                <img
                                    style={{
                                        width: 212,
                                        height: 408,
                                        objectFit: 'cover',
                                        borderRadius: 5,
                                    }}
                                    src={(
                                        this.state.preview as any
                                    ).stat.picture_portrait.replace(
                                        'http://cdn-eu.carimmat.com',
                                        'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                    )}
                                    alt="swipeup"
                                />
                            </a>

                            <div
                                style={{
                                    marginRight: 15,
                                    position: 'absolute',
                                    bottom: 95,
                                    left: 102,
                                    height: 409,
                                    width: 212,
                                    borderRadius: 5,
                                    background:
                                        'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6978992280505952) 35%, rgba(0,212,255,0) 100%)',
                                }}
                            ></div>

                            <a
                                target={'_blank'}
                                href={(this.state.preview as any).stat.picture}
                                style={{
                                    marginRight: 15,
                                    position: 'absolute',
                                    top: 75,
                                    left: 108,
                                }}
                            >
                                <img
                                    style={{
                                        width: 206,
                                        height: 390,
                                        objectFit: 'cover',
                                        borderRadius: 5,
                                    }}
                                    src="/assets/images/mockup_ads_button.png"
                                    alt="swipeup"
                                />
                            </a>

                            <a
                                target={'_blank'}
                                href={(this.state.preview as any).stat.picture}
                                style={{
                                    marginRight: 15,
                                    position: 'absolute',
                                    bottom: 150,
                                    left: 108,
                                    textAlign: 'left',
                                }}
                            >
                                <p
                                    style={{
                                        color: 'white',
                                        fontSize: 14,
                                        margin: 0,
                                        marginBottom: 5,
                                    }}
                                >
                                    {(this.state.preview as any).stat.name}
                                </p>
                                <p
                                    style={{
                                        color: 'white',
                                        fontSize: 10,
                                        margin: 0,
                                        width: 200,
                                        marginBottom: 5,
                                    }}
                                >
                                    {
                                        (this.state.preview as any).stat
                                            .description
                                    }
                                </p>
                                <p
                                    style={{
                                        fontSize: 8,
                                        margin: 0,
                                        color: '#fecb19',
                                    }}
                                >
                                    Contenu sponsorisé
                                </p>
                            </a>
                        </div>
                    </div>
                ) : null}

                <table>
                    <thead>
                        <tr>
                            <th>Campagne</th>
                            <th>Nom</th>
                            <th>Lang</th>
                            <th>Prix de la campagne</th>
                            <th>Stats</th>
                            <th>CPM</th>
                            <th>CPC</th>
                            <th>Activer/Désactiver</th>
                            <th>Modifier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.ads.map((a: any) => {
                            let status
                            let show = false
                            if (a.stat.enable === false) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-warning">
                                        Désactivé
                                    </span>
                                )
                                if (this.state.show) {
                                    show = true
                                }
                            } else if (
                                moment().isAfter(moment(a.stat.start)) &&
                                moment().isBefore(moment(a.stat.end)) &&
                                a.stat.enable === true
                            ) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-primary">
                                        En cours
                                    </span>
                                )
                                show = true
                            } else if (
                                moment().isBefore(moment(a.stat.start))
                            ) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-warning">
                                        En attente de la date de départ
                                    </span>
                                )
                                show = true
                            } else if (moment().isAfter(moment(a.stat.end))) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-success">
                                        Terminé
                                    </span>
                                )
                                if (this.state.show) {
                                    show = true
                                }
                            }

                            if (show) {
                                return (
                                    <tr key={a.stat._id}>
                                        <td>
                                            <div className="row">
                                                <p>
                                                    Début:{' '}
                                                    {system.getDateZ(
                                                        a.stat.start
                                                    )}
                                                </p>
                                                <p>
                                                    Fin:{' '}
                                                    {system.getDateZ(
                                                        a.stat.end
                                                    )}
                                                </p>
                                            </div>
                                            {status}
                                        </td>
                                        <td>
                                            <b>{a.stat.name}</b>
                                            <p>{a.stat.description}</p>
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        preview: a,
                                                    })
                                                }}
                                            >
                                                Preview
                                            </button>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={a.stat.link}
                                            >
                                                Lien
                                            </a>
                                        </td>
                                        <td>
                                            <img
                                                src={
                                                    'https://flagcdn.com/16x12/' +
                                                    (a.stat.lang === 'en'
                                                        ? 'us'
                                                        : a.stat.lang) +
                                                    '.png'
                                                }
                                                width="16"
                                                height="12"
                                                alt="lang"
                                            />
                                            <p>{a.stat.lang}</p>
                                        </td>
                                        <td>{a.stat.price}€</td>
                                        <td>
                                            <div
                                                style={{
                                                    width: 145,
                                                    height: 90,
                                                }}
                                            >
                                                <ReactECharts
                                                    option={{
                                                        tooltip: {
                                                            trigger: 'item',
                                                        },
                                                        series: [
                                                            {
                                                                name: 'Inscription',
                                                                type: 'pie',
                                                                radius: '50%',
                                                                data: [
                                                                    {
                                                                        value: a.views,
                                                                        name: 'Views',
                                                                    },
                                                                    {
                                                                        value: a.clicks,
                                                                        name: 'Clicks',
                                                                    },
                                                                ],
                                                                emphasis: {
                                                                    itemStyle: {
                                                                        shadowBlur: 10,
                                                                        shadowOffsetX: 0,
                                                                        shadowColor:
                                                                            'rgba(0, 0, 0, 0.5)',
                                                                    },
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    theme={'theme_name'}
                                                />
                                            </div>

                                            <div className="row">
                                                <p style={{ padding: 5 }}>
                                                    Vues
                                                    <br /> {a.views}
                                                </p>
                                                <p style={{ padding: 5 }}>
                                                    Cliqués
                                                    <br /> {a.clicks}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            {a.views === 0
                                                ? 0
                                                : system.roundFloat(
                                                      (a.stat.price * 1000) /
                                                          a.views
                                                  )}
                                            €
                                        </td>
                                        <td>
                                            {a.clicks === 0
                                                ? 0
                                                : system.roundFloat(
                                                      a.stat.price / a.clicks
                                                  )}
                                            €
                                        </td>
                                        <td>
                                            {a.stat.enable ? (
                                                <Button
                                                    onClick={() =>
                                                        this.disable(a.stat._id)
                                                    }
                                                >
                                                    <FaEyeSlash />
                                                </Button>
                                            ) : (
                                                <Button
                                                    intent="primary"
                                                    onClick={() =>
                                                        this.enable(a.stat._id)
                                                    }
                                                >
                                                    <FaEye />
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <Link
                                                to={
                                                    '/ad/' +
                                                    a.stat._id +
                                                    '/edit'
                                                }
                                                className="disabled"
                                            >
                                                <Button intent="none">
                                                    <FaEdit />
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Ad
