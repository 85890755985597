import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

import './map.css'

class Map extends React.Component<any, any> {
    state = {
        lat: 0,
        lng: 0,
    }

    mapconfig = {
        lat: 45,
        lng: 7,
        zoom: 5,
    }

    public render() {
        return (
            <LoadScript googleMapsApiKey="AIzaSyDUvrrAKPFUN8_6_t-L71TJsZwtmM8fbas">
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    center={this.mapconfig}
                    zoom={this.mapconfig.zoom}
                    onClick={(data: any) => {
                        this.setState(
                            {
                                lat: data.latLng.lat(),
                                lng: data.latLng.lng(),
                            },
                            () =>
                                this.props.onClick({
                                    lat: data.latLng.lat(),
                                    lng: data.latLng.lng(),
                                })
                        )
                    }}
                    onUnmount={(map) => {
                        // do your stuff before map is unmounted
                    }}
                >
                    {this.props.markers.map((marker: any, nb: number) => {
                        return (
                            <Marker
                                icon={
                                    marker.type === 'bot'
                                        ? require('./bot.png')
                                        : require('./user.png')
                                }
                                key={nb}
                                position={{
                                    lat: parseFloat(marker.coordinates[1]),
                                    lng: parseFloat(marker.coordinates[0]),
                                }}
                            />
                        )
                    })}

                    {this.props.center_follow ? (
                        <Marker
                            key={'center_follow'}
                            position={{
                                lat: this.state.lat,
                                lng: this.state.lng,
                            }}
                        />
                    ) : null}
                </GoogleMap>
            </LoadScript>
        )
    }
}

export default Map
