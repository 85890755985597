import React from 'react'
import WidgetStatNombreSmall from '../../components/widget-stat-nombre-small/widget-stat-nombre-small'
import WidgetStatNombreGraphDonut from '../../components/widget-stat-nombre-donut/widget-stat-nombre-graph-donut'
import Campaigns from '../../entity/campaigns'

import './marketing.css'

class Marketing extends React.Component<
    any,
    {
        loading: boolean
        nb_envois: number
        nb_recus: number
        nb_impressions: number
        nb_ouvertures: number
        repartitionhommefemmeother: any
        repartitionmembreage: any
        repartitionplateform: any
    }
> {
    state = {
        nb_envois: 0,
        nb_recus: 0,
        nb_impressions: 0,
        nb_ouvertures: 0,
        repartitionhommefemmeother: [],
        repartitionmembreage: [],
        repartitionplateform: [],
        loading: true,
    }

    // Init page
    componentDidMount() {
        this.stats()
    }

    // Get ads
    stats = () => {
        Campaigns.stats(this.props.match.params.id, (msg: any) => {
            let repartitionhommefemmeother: any = []
            let repartitionmembreage: any = []
            let repartitionplateform: any = []

            for (let i in msg.data.repartitionagebygender) {
                if (i !== '' && i !== 'Unknown') {
                    repartitionmembreage.push({
                        value: msg.data.repartitionagebygender[i],
                        name: i,
                    })
                }
            }

            repartitionplateform.push({
                value: msg.data.repartitionplateform.ios,
                name: 'iOS',
            })
            repartitionplateform.push({
                value: msg.data.repartitionplateform.android,
                name: 'Android',
            })

            repartitionhommefemmeother.push({
                value: msg.data.nbusersmen,
                name: 'Homme',
            })
            repartitionhommefemmeother.push({
                value: msg.data.nbuserswomen,
                name: 'Femme',
            })
            repartitionhommefemmeother.push({
                value: msg.data.nbusersothers,
                name: 'Other',
            })

            this.setState({
                loading: false,
                nb_envois: msg.data.nb_envois,
                nb_impressions: msg.data.nb_impressions,
                nb_ouvertures: msg.data.nb_ouvertures,
                nb_recus: msg.data.nb_recus,
                repartitionhommefemmeother,
                repartitionmembreage,
                repartitionplateform,
            })
        })
    }

    public render() {
        return (
            <div id="marketing">
                <div id="marketing_header">
                    <div>
                        <h3>Analytics marketing</h3>
                        <p>
                            Retrouver toutes les stats nécessaire à la partie
                            marketing.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre d'envoi"
                        value={this.state.nb_envois}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre reçus"
                        value={this.state.nb_recus}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre d'impressions"
                        value={this.state.nb_impressions}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre d'ouvertures"
                        value={this.state.nb_ouvertures}
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreGraphDonut
                        colors={['#74b9ff', '#fd79a8', '#fbc531']}
                        data={this.state.repartitionhommefemmeother}
                        loading={this.state.loading}
                        period={'All time'}
                        title="Ratio homme / femme / autre"
                        max={this.state.nb_ouvertures}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={[
                            '#9c88ff',
                            '#dcdde1',
                            '#fbc531',
                            '#487eb0',
                            '#00a8ff',
                            '#e84118',
                            '#273c75',
                        ]}
                        data={this.state.repartitionmembreage}
                        loading={this.state.loading}
                        period={'All time'}
                        title="Membres segment d'âge"
                        max={this.state.nb_ouvertures}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={[
                            '#9c88ff',
                            '#dcdde1',
                            '#fbc531',
                            '#487eb0',
                            '#00a8ff',
                            '#e84118',
                            '#273c75',
                        ]}
                        data={this.state.repartitionplateform}
                        loading={this.state.loading}
                        period={'All time'}
                        title="Répartition plateforme"
                        max={this.state.nb_ouvertures}
                    />
                </div>
            </div>
        )
    }
}

export default Marketing
