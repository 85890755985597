import React from 'react'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import ReactPaginate from 'react-paginate'

import './users.css'
import UserEntity from '../../entity/user'
import system from '../../system/system'
import {
    FaAndroid,
    FaApple,
    FaBan,
    FaGem,
    FaGlobeAsia,
    FaGlobeEurope,
    FaMars,
    FaPlay,
    FaTransgender,
    FaVenus,
} from 'react-icons/fa'
import Stats from '../../entity/stats'
import ButtonLoad from '../../components/button-load/button-load'
import moment from 'moment'
import WidgetStatNombreGraph from '../../components/widget-stat-nombre-graph/widget-stat-nombre-graph'
import { Link } from 'react-router-dom'

class Users extends React.Component<
    any,
    {
        users: any
        loading: boolean
        nbactifs: number
        nbnewmembers: number
        nbdeleteusers: number
        nbusersfilter: number
        page: number
        nbusers: number
        search: String
        search_personnal: String
        nbnewmembersbyday: any
        nbmembersbyday: any
        nbdeleteusersbyday: any
    }
> {
    state = {
        users: [],
        loading: true,
        nbactifs: 0,
        nbnewmembers: 0,
        nbdeleteusers: 0,
        nbusers: 0,
        search: '',
        search_personnal: '',
        nbnewmembersbyday: [],
        nbmembersbyday: [],
        nbdeleteusersbyday: [],
        page: 0,
        nbusersfilter: 0,
    }

    // Init page
    componentDidMount() {
        this.stats(() => {
            this.getUsers()
        })
    }

    // Get ads
    getUsers = () => {
        UserEntity.list(
            this.state.page,
            this.state.search,
            this.state.search_personnal,
            (res: any) => {
                this.setState({
                    users: res.data.users.sort((a: any, b: any) =>
                        a.created_at > b.created_at ? -1 : 1
                    ),
                    nbusersfilter: res.data.nbusers,
                    loading: false,
                })
            }
        )
    }

    stats = (callback: any) => {
        Stats.users(
            moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000') +
                'Z',
            moment().format('YYYY-MM-DDT23:59:59.000') + 'Z',
            (msg: any) => {
                let nbnewmembersbyday: any = []
                let nbdeleteusersbyday: any = []
                let nbmembersbyday: any = []

                for (let i = 0; i < 31; i++) {
                    nbnewmembersbyday.push({
                        date: moment().subtract(i, 'days').format('YYYY-MM-DD'),
                        value: 0,
                    })
                    nbdeleteusersbyday.push({
                        date: moment().subtract(i, 'days').format('YYYY-MM-DD'),
                        value: 0,
                    })
                    nbmembersbyday.push({
                        date: moment().subtract(i, 'days').format('YYYY-MM-DD'),
                        value: 0,
                    })
                }

                let i: any
                for (i in nbmembersbyday) {
                    if (
                        nbmembersbyday[i].date === moment().format('YYYY-MM-DD')
                    ) {
                        nbmembersbyday[i].value = msg.data.nbusers
                        break
                    }
                }

                for (let i in msg.data.nbnewmembersbyday) {
                    let j: any
                    for (j in nbnewmembersbyday) {
                        if (nbnewmembersbyday[j].date === i) {
                            nbnewmembersbyday[j].value =
                                msg.data.nbnewmembersbyday[i]
                            break
                        }
                    }
                }

                for (i in msg.data.nbdeleteusersbyday) {
                    let j: any
                    for (j in nbdeleteusersbyday) {
                        if (nbdeleteusersbyday[j].date === i) {
                            nbdeleteusersbyday[j].value =
                                msg.data.nbdeleteusersbyday[i]
                            break
                        }
                    }
                }

                nbnewmembersbyday = nbnewmembersbyday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? -1 : 1
                )

                nbmembersbyday = nbmembersbyday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? -1 : 1
                )

                for (i in nbnewmembersbyday) {
                    if (i > 0) {
                        nbmembersbyday[i].value =
                            nbmembersbyday[i - 1].value -
                            nbnewmembersbyday[i].value
                    }
                }

                nbnewmembersbyday = nbnewmembersbyday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                nbmembersbyday = nbmembersbyday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                nbdeleteusersbyday = nbdeleteusersbyday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                this.setState(
                    {
                        nbactifs: msg.data.nbactifs,
                        nbnewmembers: msg.data.nbnewmembers,
                        nbdeleteusers: msg.data.nbdeleteusers,
                        nbnewmembersbyday,
                        nbdeleteusersbyday,
                        nbmembersbyday,
                        nbusers: msg.data.nbusers,
                    },
                    callback
                )
            }
        )
    }

    // Get ads
    ban = (id: String) => {
        UserEntity.ban(id, () => {
            let users: any = this.state.users
            for (let u in users) {
                if (users[u].id === id) {
                    users[u].status = 0
                }
            }

            this.setState({
                users,
            })
        })
    }

    public render() {
        return (
            <div id="users">
                <div id="users_header">
                    <div>
                        <h3>Dashboard users</h3>
                        <p>
                            Retrouver toutes les données concernant les
                            utilisateurs sur ce dashboard.
                        </p>
                    </div>

                    <Button
                        onClick={() => {
                            this.setState(
                                {
                                    loading: true,
                                    users: [],
                                },
                                () => {
                                    this.stats(() => {
                                        this.getUsers()
                                    })
                                }
                            )
                        }}
                        style={{ height: 30 }}
                    >
                        Refresh
                    </Button>
                </div>

                <div className="row">
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.nbmembersbyday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Nombre de membres"
                        value={this.state.nbusers}
                    />

                    <WidgetStatNombreGraph
                        mainColor="#34D399"
                        color="#D1F5E8"
                        data={this.state.nbnewmembersbyday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Nouveaux membres"
                        value={this.state.nbnewmembers}
                    />

                    <WidgetStatNombreGraph
                        mainColor="#FB7185"
                        color="#FEE1E5"
                        data={this.state.nbdeleteusersbyday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Suppression de compte"
                        value={this.state.nbdeleteusers}
                    />
                </div>

                <div className="column">
                    <div id="filter">
                        <div id="filter_header">
                            <h5>Filtre</h5>
                        </div>

                        <div id="filter_body">
                            <div id="filter_pref">
                                <div className="row">
                                    <div style={{ marginRight: 20 }}>
                                        <div className="bp3-callout bp3-intent-primary">
                                            <h4 className="bp3-heading">
                                                Information non personnel
                                            </h4>
                                            Dans le champ texte vous pouvez
                                            mettre n'importe quel mot pour
                                            rechercher par rapport au : email,
                                            pays, premium (mettre premium ou
                                            gratuit), status (supprimé, bloqué,
                                            normal).
                                        </div>

                                        <FormGroup
                                            helperText="Entrez un mot que vous voulez rechercher"
                                            label="Mot(s)"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Mot à rechercher"
                                                max={100}
                                                min={10}
                                                value={this.state.search}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        search: msg.target
                                                            .value,
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <div className="bp3-callout bp3-intent-warning">
                                            <h4 className="bp3-heading">
                                                Information personnel
                                            </h4>
                                            Dans le champ texte vous pouvez
                                            mettre n'importe quel mot d'au moins
                                            3 caractères pour rechercher dans
                                            l'email, le prénom, la description,
                                            la ville, le stripe id. Cette
                                            recherche peut prendre beaucoup de
                                            temps.
                                        </div>

                                        <FormGroup
                                            helperText="Entrez un mot que vous voulez rechercher"
                                            label="Mot(s)"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Mot à rechercher"
                                                max={100}
                                                min={10}
                                                value={
                                                    this.state.search_personnal
                                                }
                                                onChange={(msg) => {
                                                    this.setState({
                                                        search_personnal:
                                                            msg.target.value,
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ButtonLoad
                            loading={this.state.loading}
                            callback={() => {
                                this.setState(
                                    {
                                        loading: true,
                                        page: 0,
                                    },
                                    () => this.getUsers()
                                )
                            }}
                            enable="secondary"
                        >
                            <b style={{ fontSize: 14 }}>
                                <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA RECHERCHE
                            </b>
                        </ButtonLoad>
                    </div>

                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Avatar</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Premium</th>
                                    <th>Pays</th>
                                    <th>Langue</th>
                                    <th>Age</th>
                                    <th>Plateforme</th>
                                    <th>Status</th>
                                    <th>Stocké</th>
                                    <th>Bannir / Débannir</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? (
                                    <tr>
                                        <td colSpan={12}>
                                            <Spinner />
                                        </td>
                                    </tr>
                                ) : null}

                                {!this.state.loading
                                    ? this.state.users.map((u: any) => {
                                          return (
                                              <tr key={u.id}>
                                                  <td className="avatar">
                                                      {u.avatar !== '' &&
                                                      u.avatar !== null ? (
                                                          <img
                                                              src={u.avatar.replace(
                                                                  'http://cdn-eu.carimmat.com',
                                                                  'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                                              )}
                                                              alt="avatar"
                                                          />
                                                      ) : (
                                                          ''
                                                      )}
                                                  </td>
                                                  <td>
                                                      <Link
                                                          to={'users/' + u.id}
                                                      >
                                                          {u.sex === 1 ? (
                                                              <FaMars />
                                                          ) : u.sex === 2 ? (
                                                              <FaVenus />
                                                          ) : u.sex === 3 ? (
                                                              <FaTransgender />
                                                          ) : null}
                                                          <b>
                                                              {u.firstname !==
                                                              ''
                                                                  ? u.firstname
                                                                  : 'Inconnu'}
                                                          </b>
                                                      </Link>
                                                  </td>
                                                  <td className="left">
                                                      {u.email_clair}
                                                      <p>
                                                          <b>Ajouté le:</b>{' '}
                                                          {system.getActualDateFromDateHourZ(
                                                              u.created_at
                                                          )}
                                                      </p>
                                                      {u.description !== '' ? (
                                                          <p>
                                                              <b>
                                                                  Description:
                                                              </b>{' '}
                                                              {u.description}
                                                          </p>
                                                      ) : null}
                                                  </td>
                                                  <td>
                                                      {u.premium_account ===
                                                      1 ? (
                                                          <FaGem
                                                              color={'#B1E5F2'}
                                                              size={20}
                                                          />
                                                      ) : (
                                                          ''
                                                      )}
                                                  </td>
                                                  <td>
                                                      {u.country_iso2 !== '' &&
                                                      u.country_iso2 !==
                                                          null ? (
                                                          <div>
                                                              <img
                                                                  src={
                                                                      'https://flagcdn.com/16x12/' +
                                                                      u.country_iso2.toLowerCase() +
                                                                      '.png'
                                                                  }
                                                                  width="16"
                                                                  height="12"
                                                                  alt="lang"
                                                              />
                                                              <p>{u.country}</p>
                                                          </div>
                                                      ) : (
                                                          ''
                                                      )}
                                                  </td>
                                                  <td>
                                                      <img
                                                          src={
                                                              'https://flagcdn.com/16x12/' +
                                                              (u.language ===
                                                              'en'
                                                                  ? 'us'
                                                                  : u.language) +
                                                              '.png'
                                                          }
                                                          width="16"
                                                          height="12"
                                                          alt="lang"
                                                      />
                                                      <p>{u.language}</p>
                                                  </td>
                                                  <td>{u.age}</td>
                                                  <td>
                                                      {u.platform ===
                                                      'android' ? (
                                                          <FaAndroid
                                                              color="#9FC037"
                                                              size={20}
                                                          />
                                                      ) : u.platform ===
                                                        'ios' ? (
                                                          <FaApple
                                                              color="#000"
                                                              size={20}
                                                          />
                                                      ) : (
                                                          ''
                                                      )}
                                                  </td>
                                                  <td className={'status'}>
                                                      <span
                                                          className={
                                                              u.status === 0
                                                                  ? 'error'
                                                                  : u.status ===
                                                                    1
                                                                  ? 'success'
                                                                  : 'warning'
                                                          }
                                                      >
                                                          {u.status === 0 &&
                                                          u.email_clair ===
                                                              'deleted@carimmat.com'
                                                              ? 'Supprimé'
                                                              : u.status === 0
                                                              ? 'Banni'
                                                              : u.status === 1
                                                              ? 'Normal'
                                                              : 'Bloqué'}
                                                      </span>
                                                  </td>
                                                  <td>
                                                      {u.stored === 'ue' ? (
                                                          <FaGlobeEurope
                                                              size={20}
                                                          />
                                                      ) : u.stored === 'ru' ? (
                                                          <FaGlobeAsia
                                                              size={20}
                                                          />
                                                      ) : (
                                                          ''
                                                      )}
                                                      <p>{u.stored}</p>
                                                  </td>
                                                  <td>
                                                      {u.status === 1 ? (
                                                          <Button
                                                              onClick={() =>
                                                                  this.ban(u.id)
                                                              }
                                                          >
                                                              <FaBan />
                                                          </Button>
                                                      ) : null}
                                                  </td>
                                              </tr>
                                          )
                                      })
                                    : null}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Suivant >"
                                onPageChange={(e) => {
                                    console.log('pagechange', e)
                                    this.setState(
                                        {
                                            loading: true,
                                            page: e.selected,
                                        },
                                        () => this.getUsers()
                                    )
                                }}
                                pageRangeDisplayed={5}
                                pageCount={Math.floor(
                                    this.state.nbusersfilter / 20
                                )}
                                previousLabel="< Précédent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users
