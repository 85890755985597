import React from 'react'
import R from '../../entity/reports'
import system from '../../system/system'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { FaBan, FaCheck } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import './reports.css'
import UserEntity from '../../entity/user'

class Reports extends React.Component<
    {},
    {
        reports: any
        loading: boolean
        nbreports: number
        page: number
    }
> {
    state = {
        reports: [],
        nbreports: 0,
        loading: true,
        page: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        R.list(this.state.page, (msg: any) => {
            this.setState({
                reports: msg['data']['reports'],
                nbreports: msg['data']['nbreports'],
                loading: false,
            })
        })
    }

    ban = (userid: string, _id: string) => {
        UserEntity.ban(userid, (msg: any) => {
            if (msg.data === true) {
                R.done(_id, (msg: any) => {
                    if (msg.data === true) {
                        let reports: any = this.state.reports
                        for (let u in reports) {
                            if (reports[u]._id === _id) {
                                reports[u].done = true
                            }
                        }

                        this.setState({
                            reports,
                        })
                    }
                })
            }
        })
    }

    done = (_id: string) => {
        R.done(_id, (msg: any) => {
            if (msg.data === true) {
                let reports: any = this.state.reports
                for (let u in reports) {
                    if (reports[u]._id === _id) {
                        reports[u].done = true
                    }
                }

                this.setState({
                    reports,
                })
            }
        })
    }

    public render() {
        return (
            <div id="reports" className="p20">
                <div id="reports_header">
                    <div>
                        <h3>Reports</h3>
                        <p>Retrouver tous les reports</p>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date du report</th>
                            <th>Status</th>
                            <th>Utilisateur signalé</th>
                            <th>Type</th>
                            <th>Utilisateur qui a signalé</th>
                            <th>Ban</th>
                            <th>Fait</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.reports.map((k: any) => {
                            return (
                                <tr key={k._id}>
                                    <td>{system.getDateWithoutHour(k.date)}</td>
                                    <td>
                                        {k.done === true ? (
                                            <span className="label label-success">
                                                Fait
                                            </span>
                                        ) : (
                                            <span className="label label-warning">
                                                A faire
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <Link
                                            to={'users/' + k.reported_user_id}
                                        >
                                            {k.reported_user_id}
                                        </Link>
                                    </td>

                                    {k.type === 1 ? (
                                        <td>
                                            <p>Comportement</p>
                                        </td>
                                    ) : null}

                                    {k.type === 2 ? (
                                        <td>
                                            <p>Profil ou photo</p>
                                        </td>
                                    ) : null}

                                    <td>
                                        <Link to={'users/' + k.user_id}>
                                            {k.user_id}
                                        </Link>
                                    </td>

                                    <td>
                                        {k.done === false ? (
                                            <Button
                                                onClick={() =>
                                                    this.ban(
                                                        k.reported_user_id,
                                                        k._id
                                                    )
                                                }
                                            >
                                                <FaBan />
                                            </Button>
                                        ) : null}
                                    </td>

                                    <td>
                                        {k.done === false ? (
                                            <Button
                                                onClick={() => this.done(k._id)}
                                            >
                                                <FaCheck />
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Suivant >"
                        onPageChange={(e) => {
                            console.log('pagechange', e)
                            this.setState(
                                {
                                    loading: true,
                                    page: e.selected,
                                },
                                () => this.getData()
                            )
                        }}
                        pageRangeDisplayed={5}
                        pageCount={Math.floor(this.state.nbreports / 20)}
                        previousLabel="< Précédent"
                    />
                </div>
            </div>
        )
    }
}

export default Reports
