import { Button } from '@blueprintjs/core'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { isMobile } from 'react-device-detect'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Route, Switch } from 'react-router-dom'
import MenuLeft from '../components/menu-left/menu-left'
import system from '../system/system'
import './connected.css'

class Connected extends React.Component<
    { refreshParent: any; logincompleted: boolean; history?: any },
    { nbusers: number; menuHidden: boolean }
> {
    state = {
        nbusers: 0,
        menuHidden: isMobile || window.innerWidth < 860 ? true : false,
    }

    /**
     * Log out
     */
    public logOut = () => {
        system.logOut()

        this.props.refreshParent()
    }

    /**
     * A special wrapper for <Route> that knows how to
     * handle "sub"-routes by passing them in a `routes`
     * prop to the component it renders.
     */
    public RouteWithSubRoutes = (route: any) => {
        let routereturn: any

        if (route.exact) {
            routereturn = (
                <Route
                    path={route.path}
                    render={(props) => (
                        <route.component {...props} routes={route.routes} />
                    )}
                    exact
                />
            )
        } else {
            routereturn = (
                <Route
                    path={route.path}
                    render={(props) => (
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            )
        }
        return routereturn
    }

    public render() {
        return (
            <div id="connected">
                <MenuLeft logOut={this.logOut} hidden={this.state.menuHidden} />

                <div id="page-container">
                    <div id="header">
                        <Button
                            intent="none"
                            onClick={() => {
                                this.setState({
                                    menuHidden: !this.state.menuHidden,
                                })
                            }}
                        >
                            {!this.state.menuHidden ? (
                                <FaTimes size={18} />
                            ) : (
                                <FaBars size={18} />
                            )}
                        </Button>
                    </div>

                    <Scrollbars
                        autoHide
                        autoHeight
                        autoHeightMin={window.innerHeight - 65}
                        renderThumbVertical={({ style, ...props }) => (
                            <div
                                {...props}
                                style={{
                                    ...style,
                                    backgroundColor: '#222',
                                }}
                            />
                        )}
                    >
                        <Switch>
                            {system.routes.map((route: any, i: any) => (
                                <this.RouteWithSubRoutes key={i} {...route} />
                            ))}
                        </Switch>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default Connected
