import React from 'react'
import E from '../../entity/emails'
import { Link } from 'react-router-dom'
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core'
import { FaEdit, FaEye, FaPaperPlane, FaPlus } from 'react-icons/fa'

import './emails.css'

class Emails extends React.Component<
    any,
    {
        emails: any
        show: boolean
        send_testers: boolean
        preview: String
        email: String
        template: String
    }
> {
    state = {
        emails: [],
        show: false,
        send_testers: false,
        preview: '',
        email: '',
        template: '',
    }

    // Init page
    componentDidMount() {
        this.getEmails()
    }

    // Get ads
    getEmails = () => {
        E.list((res: any) => {
            this.setState({
                emails: res.data,
            })
        })
    }

    public render() {
        return (
            <div id="ads" className="p20">
                <Dialog
                    title="Preview du mail"
                    isOpen={this.state.preview !== ''}
                    style={{ backgroundColor: 'white', width: 920 }}
                    onClose={() => {
                        this.setState({
                            preview: '',
                        })
                    }}
                >
                    <div
                        className={Classes.DIALOG_BODY}
                        style={{ backgroundColor: 'white', width: 900 }}
                        dangerouslySetInnerHTML={{ __html: this.state.preview }}
                    ></div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        preview: '',
                                    })
                                }}
                                intent="none"
                            >
                                Fermer
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    title="Envoi à un testeur"
                    isOpen={this.state.send_testers}
                    onClose={() => {
                        this.setState({
                            send_testers: false,
                        })
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            helperText=""
                            label="Email"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: contact@carimmat.com"
                                min={3}
                                value={this.state.email.toString()}
                                onChange={(msg) => {
                                    this.setState({
                                        email: msg.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        send_testers: false,
                                    })
                                }}
                                intent="none"
                            >
                                Fermer
                            </Button>

                            <Button
                                onClick={() => {
                                    this.setState(
                                        {
                                            send_testers: false,
                                        },
                                        () => {
                                            E.send_testers(
                                                this.state.template,
                                                this.state.email,
                                                (msg: any) => {}
                                            )
                                        }
                                    )
                                }}
                                intent="primary"
                            >
                                Envoyer
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <div id="ads_header">
                    <div>
                        <h3>Emails</h3>
                        <p>Retrouver tous les emails présents dans l'app</p>
                    </div>

                    <div>
                        <Link to="/emails/add" className="disabled">
                            <Button
                                intent="primary"
                                onClick={() => {}}
                                style={{ height: 30, marginRight: 30 }}
                            >
                                <FaPlus /> Ajouter un email
                            </Button>
                        </Link>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Preview</th>
                            <th>Nom</th>
                            <th>Subject</th>
                            <th>Lang</th>
                            <th>Layout</th>
                            <th>Testers</th>
                            <th>Modifier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.emails.map((a: any) => {
                            return (
                                <tr key={a._id}>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    preview: a.html,
                                                })
                                            }}
                                        >
                                            <FaEye />
                                        </Button>
                                    </td>
                                    <td>{a.name}</td>
                                    <td>{a.subject}</td>
                                    <td>
                                        <img
                                            src={
                                                'https://flagcdn.com/16x12/' +
                                                (a.language === 'en'
                                                    ? 'gb'
                                                    : a.language) +
                                                '.png'
                                            }
                                            width="16"
                                            height="12"
                                            alt="lang"
                                        />
                                        <p>{a.language}</p>
                                    </td>
                                    <td>{a.layout}</td>
                                    <td>
                                        {!a.is_layout ? (
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        send_testers: true,
                                                        template: a._id,
                                                    })
                                                }}
                                            >
                                                <FaPaperPlane />
                                            </Button>
                                        ) : null}
                                    </td>
                                    <td>
                                        <Link
                                            to={'/emails/' + a._id + '/edit'}
                                            className="disabled"
                                        >
                                            <Button intent="none">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Emails
