import React from 'react'
import Stats from '../../entity/stats'
import ReactECharts from 'echarts-for-react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Spinner, SpinnerSize } from '@blueprintjs/core'
import { arrondi } from '../../system/functions'

import './stats-users.css'

class StatsUsers extends React.Component<
    any,
    {
        nbavatarswomen: number
        nbavatarsmen: number
        nbusers: number
        nbavatars: number
        nblocations: number
        maxrepartitions: number
        nbwomen: number
        nbmen: number
        nbdeleteusers: number
        nbactifs: number
        nbvisites: number
        nbnewmembers: number
        startdate: moment.Moment
        enddate: moment.Moment
        loading: boolean
        repartitionagebygender: any
        repartitionagebygenderpremium: any
    }
> {
    state = {
        nbavatars: 0,
        nbavatarsmen: 0,
        nbavatarswomen: 0,
        maxrepartitions: 0,
        nbusers: 0,
        nbwomen: 0,
        nblocations: 0,
        nbmen: 0,
        nbdeleteusers: 0,
        nbactifs: 0,
        nbvisites: 0,
        nbnewmembers: 0,
        startdate: moment().subtract(7, 'days'),
        enddate: moment(),
        loading: true,
        repartitionagebygender: {
            'Homme 18 - 25': 0,
            'Femme 18 - 25': 0,
            'Homme 26 - 40': 0,
            'Femme 26 - 40': 0,
            'Homme 41 - 50': 0,
            'Femme 41 - 50': 0,
            'Homme + de 51': 0,
            'Femme + de 51': 0,
        },
        repartitionagebygenderpremium: {
            'Homme 18 - 25': 0,
            'Femme 18 - 25': 0,
            'Homme 26 - 40': 0,
            'Femme 26 - 40': 0,
            'Homme 41 - 50': 0,
            'Femme 41 - 50': 0,
            'Homme + de 51': 0,
            'Femme + de 51': 0,
        },
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        Stats.users(
            this.state.startdate.format('YYYY-MM-DDT00:00:00'),
            this.state.enddate.format('YYYY-MM-DDT23:59:59'),
            (res: any) => {
                let maxrepartitions = 0

                for (var key in res.data.repartitionagebygender) {
                    if (key !== 'Unknown' && key !== '') {
                        maxrepartitions += res.data.repartitionagebygender[key]
                    }
                }

                this.setState({
                    nbavatarsmen: res.data.nbavatarsmen,
                    nbavatarswomen: res.data.nbavatarswomen,
                    nbavatars: res.data.nbavatars,
                    nbusers: res.data.nbusers,
                    nbwomen: res.data.nbwomen,
                    nbvisites: res.data.nbvisites,
                    nblocations: res.data.nblocations,
                    nbdeleteusers: res.data.nbdeleteusers,
                    nbmen: res.data.nbmen,
                    nbnewmembers: res.data.nbnewmembers,
                    nbactifs: res.data.nbactifs,
                    loading: false,
                    repartitionagebygender: res.data.repartitionagebygender,
                    repartitionagebygenderpremium:
                        res.data.repartitionagebygenderpremium,
                    maxrepartitions,
                })
            }
        )
    }

    public render() {
        return (
            <div id="stats-users">
                <h3>Users</h3>

                <div
                    className="row"
                    style={{
                        marginBottom: 15,
                    }}
                >
                    <DatePicker
                        selected={this.state.startdate.toDate()}
                        onChange={(date) =>
                            this.setState(
                                {
                                    startdate: moment(date?.toString()),
                                    loading: true,
                                },
                                () => {
                                    this.refresh()
                                }
                            )
                        }
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={this.state.startdate.toDate()}
                        endDate={this.state.enddate.toDate()}
                    />

                    <DatePicker
                        selected={this.state.enddate.toDate()}
                        onChange={(date) =>
                            this.setState(
                                {
                                    enddate: moment(date?.toString()),
                                    loading: true,
                                },
                                () => {
                                    this.refresh()
                                }
                            )
                        }
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        startDate={this.state.startdate.toDate()}
                        endDate={this.state.enddate.toDate()}
                        minDate={this.state.startdate.toDate()}
                    />
                </div>

                <div className="row bt">
                    <div className="box f3">
                        <div className="column f1">
                            <div className="row">
                                <div className="column f1">
                                    <h2>Nouveaux membres</h2>
                                </div>
                            </div>

                            <div
                                className="f1"
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                {this.state.loading ? (
                                    <Spinner
                                        size={SpinnerSize.STANDARD}
                                        intent={'none'}
                                    />
                                ) : (
                                    <ReactECharts
                                        option={{
                                            dataset: {
                                                dimensions: [
                                                    'product',
                                                    'ios',
                                                    'android',
                                                    'total',
                                                ],
                                                source: [],
                                            },
                                            xAxis: { type: 'category' },
                                            yAxis: {},
                                            series: [
                                                { type: 'bar' },
                                                { type: 'bar' },
                                                { type: 'bar' },
                                            ],
                                        }}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        theme={'theme_name'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="column f1">
                        <div className="box">
                            <h2>Nombre de membres</h2>
                            <span>{this.state.nbusers}</span>
                        </div>
                        <div className="box">
                            <h2>Nombre d'actifs</h2>
                            <span>
                                {this.state.nbactifs} (
                                {arrondi(
                                    (this.state.nbactifs * 100) /
                                        this.state.nbusers,
                                    2
                                )}
                                %)
                            </span>
                        </div>
                        <div className="box">
                            <h2>Nouveaux membres</h2>
                            <span>{this.state.nbnewmembers}</span>
                        </div>
                    </div>
                </div>

                <h3>Avancées</h3>

                <div className="row bt">
                    <div className="box column f1">
                        <h2>Churn rate</h2>
                        <span>-</span>
                    </div>
                    <div className="box column f1">
                        <h2>Chiffre d'affaire net</h2>
                        <span>-</span>
                    </div>
                    <div className="box column f1">
                        <h2>Suppression de compte</h2>
                        <span>{this.state.nbdeleteusers}</span>
                    </div>
                </div>

                <h3>Stats</h3>

                <div className="row">
                    <div className="box column f1">
                        <div
                            className="row f1"
                            style={{
                                justifyContent: 'space-around',
                            }}
                        >
                            <div>
                                <h2>Nombre de femmes</h2>
                                <span>
                                    {this.state.nbwomen} (
                                    {arrondi(
                                        (this.state.nbwomen * 100) /
                                            (this.state.nbmen +
                                                this.state.nbwomen),
                                        2
                                    )}
                                    %)
                                </span>
                            </div>
                            <div>
                                <h2>Nombre d'hommes</h2>
                                <span>
                                    {this.state.nbmen} (
                                    {arrondi(
                                        (this.state.nbmen * 100) /
                                            (this.state.nbmen +
                                                this.state.nbwomen),
                                        2
                                    )}
                                    %)
                                </span>
                            </div>
                        </div>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: "Nombre d'inscrits",
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value: this.state.nbmen,
                                                        name: 'Homme',
                                                    },
                                                    {
                                                        value: this.state
                                                            .nbwomen,
                                                        name: 'Femme',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>

                    <div className="box column f1">
                        <h2>Nombre de membres ayant terminé l'inscription</h2>
                        <span>
                            {this.state.nbusers -
                                (this.state.nbusers -
                                    (this.state.nbmen +
                                        this.state.nbwomen))}{' '}
                            (
                            {arrondi(
                                ((this.state.nbmen + this.state.nbwomen) *
                                    100) /
                                    this.state.nbusers,
                                2
                            )}
                            %)
                        </span>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value:
                                                            this.state.nbusers -
                                                            (this.state
                                                                .nbusers -
                                                                (this.state
                                                                    .nbmen +
                                                                    this.state
                                                                        .nbwomen)),
                                                        name: 'Terminée',
                                                    },
                                                    {
                                                        value:
                                                            this.state.nbusers -
                                                            (this.state
                                                                .nbusers -
                                                                (this.state
                                                                    .nbusers -
                                                                    (this.state
                                                                        .nbmen +
                                                                        this
                                                                            .state
                                                                            .nbwomen))),
                                                        name: 'Non terminée',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>

                    <div className="box column f1">
                        <h2>Nombre de membres avec une photo</h2>
                        <span>
                            {this.state.nbavatars} (
                            {arrondi(
                                (this.state.nbavatars * 100) /
                                    this.state.nbusers,
                                2
                            )}
                            %)
                        </span>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value:
                                                            this.state.nbusers -
                                                            this.state
                                                                .nbavatars,
                                                        name: 'Pas de photo',
                                                    },
                                                    {
                                                        value: this.state
                                                            .nbavatars,
                                                        name: 'Photo',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box column f1">
                        <h2>Nombre de femmes avec une photo</h2>
                        <span>
                            {this.state.nbavatarswomen} (
                            {arrondi(
                                (this.state.nbavatarswomen * 100) /
                                    this.state.nbwomen,
                                2
                            )}
                            %)
                        </span>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value:
                                                            this.state.nbwomen -
                                                            this.state
                                                                .nbavatarswomen,
                                                        name: 'Pas de photo',
                                                    },
                                                    {
                                                        value: this.state
                                                            .nbavatarswomen,
                                                        name: 'Photo',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>

                    <div className="box column f1">
                        <h2>Nombre d'hommes avec une photo</h2>
                        <span>
                            {this.state.nbavatarsmen} (
                            {arrondi(
                                (this.state.nbavatarsmen * 100) /
                                    this.state.nbmen,
                                2
                            )}
                            %)
                        </span>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value:
                                                            this.state.nbmen -
                                                            this.state
                                                                .nbavatarsmen,
                                                        name: 'Pas de photo',
                                                    },
                                                    {
                                                        value: this.state
                                                            .nbavatarsmen,
                                                        name: 'Photo',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>

                    <div className="box column f1">
                        <h2>Nombre d'utilisateur avec une position</h2>
                        <span>
                            {this.state.nblocations} (
                            {arrondi(
                                (this.state.nblocations * 100) /
                                    this.state.nbusers,
                                2
                            )}
                            %)
                        </span>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 30,
                                height: 250,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value:
                                                            this.state.nbusers -
                                                            this.state
                                                                .nblocations,
                                                        name: 'Pas de position',
                                                    },
                                                    {
                                                        value: this.state
                                                            .nblocations,
                                                        name: 'Position',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box column f1">
                        <h2>Membres par rapport au segment d'âge</h2>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 10,
                                height: 350,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                            top: 'midle',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                roseType: 'area',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Homme 18 - 25'
                                                        ],
                                                        name:
                                                            'Segment 1: Homme de 18 à 25 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Homme 18 - 25'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Femme 18 - 25'
                                                        ],
                                                        name:
                                                            'Segment 2: Femme de 18 à 25 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Femme 18 - 25'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Homme 26 - 40'
                                                        ],
                                                        name:
                                                            'Segment 3: Homme de 26 à 40 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Homme 26 - 40'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Femme 26 - 40'
                                                        ],
                                                        name:
                                                            'Segment 4: Femme de 26 à 40 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Femme 26 - 40'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Homme 41 - 50'
                                                        ],
                                                        name:
                                                            'Segment 5: Homme de 41 à 50 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Homme 41 - 50'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Femme 41 - 50'
                                                        ],
                                                        name:
                                                            'Segment 6: Femme de 41 à 50 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Femme 41 - 50'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Homme + de 51'
                                                        ],
                                                        name:
                                                            'Segment 7: Homme de + de 51 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Homme + de 51'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygender[
                                                            'Femme + de 51'
                                                        ],
                                                        name:
                                                            'Segment 8: Femme de + de 51 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygender[
                                                                    'Femme + de 51'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box column f1">
                        <h2>Membres par rapport au segment d'âge premium</h2>

                        <div
                            style={{
                                justifyContent: 'center',
                                marginTop: 10,
                                height: 350,
                            }}
                        >
                            {this.state.loading ? (
                                <Spinner
                                    size={SpinnerSize.STANDARD}
                                    intent={'none'}
                                />
                            ) : (
                                <ReactECharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item',
                                        },
                                        legend: {
                                            orient: 'vertical',
                                            left: 'left',
                                            top: 'midle',
                                        },
                                        series: [
                                            {
                                                name: 'Inscription',
                                                type: 'pie',
                                                roseType: 'area',
                                                radius: '50%',
                                                data: [
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Homme 18 - 25'
                                                        ],
                                                        name:
                                                            'Segment 1: Homme de 18 à 25 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Homme 18 - 25'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Femme 18 - 25'
                                                        ],
                                                        name:
                                                            'Segment 2: Femme de 18 à 25 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Femme 18 - 25'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Homme 26 - 40'
                                                        ],
                                                        name:
                                                            'Segment 3: Homme de 26 à 40 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Homme 26 - 40'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Femme 26 - 40'
                                                        ],
                                                        name:
                                                            'Segment 4: Femme de 26 à 40 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Femme 26 - 40'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Homme 41 - 50'
                                                        ],
                                                        name:
                                                            'Segment 5: Homme de 41 à 50 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Homme 41 - 50'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Femme 41 - 50'
                                                        ],
                                                        name:
                                                            'Segment 6: Femme de 41 à 50 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Femme 41 - 50'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Homme + de 51'
                                                        ],
                                                        name:
                                                            'Segment 7: Homme de + de 51 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Homme + de 51'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                    {
                                                        value: this.state
                                                            .repartitionagebygenderpremium[
                                                            'Femme + de 51'
                                                        ],
                                                        name:
                                                            'Segment 8: Femme de + de 51 ans (' +
                                                            arrondi(
                                                                (this.state
                                                                    .repartitionagebygenderpremium[
                                                                    'Femme + de 51'
                                                                ] *
                                                                    100) /
                                                                    this.state
                                                                        .maxrepartitions,
                                                                2
                                                            ) +
                                                            '%)',
                                                    },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                    style={{ height: '100%', width: '100%' }}
                                    theme={'theme_name'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StatsUsers
