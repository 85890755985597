import React from 'react'
import Widget from '../widget/widget'
import ReactECharts from 'echarts-for-react'

import './widget-stat-nombre-bars.css'

class WidgetStatNombreBars extends React.Component<
    {
        loading: boolean
        title: String
        period: String
        mainColor: String
        color: String
        data: any
    },
    {}
> {
    render() {
        return (
            <Widget loading={this.props.loading}>
                <div className="widget-stat-nombre-bars">
                    <div className="title">
                        <div>
                            <h3>{this.props.title}</h3>
                        </div>
                        <div>
                            <span className="period">{this.props.period}</span>
                        </div>
                    </div>
                    <div className="full">
                        <ReactECharts
                            option={{
                                tooltip: {
                                    trigger: 'item',
                                },
                                title: {
                                    show: false,
                                },
                                legend: {
                                    show: false,
                                },
                                dataset: {
                                    dimensions: [
                                        'product',
                                        'ios',
                                        'android',
                                        'stripe',
                                        'total',
                                    ],
                                    source: [
                                        {
                                            product: '30 crédits',
                                            ios: this.props.data[
                                                '30creditsios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '30creditsandroid'
                                                ],
                                            stripe: this.props.data[
                                                '30creditsstripe'
                                            ],
                                            total: this.props.data[
                                                '30creditstotal'
                                            ],
                                        },
                                        {
                                            product: '200 crédits',
                                            ios: this.props.data[
                                                '200creditsios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '200creditsandroid'
                                                ],
                                            stripe: this.props.data[
                                                '200creditsstripe'
                                            ],
                                            total: this.props.data[
                                                '200creditstotal'
                                            ],
                                        },
                                        {
                                            product: '500 crédits',
                                            ios: this.props.data[
                                                '500creditsios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '500creditsandroid'
                                                ],
                                            stripe: this.props.data[
                                                '500creditsstripe'
                                            ],
                                            total: this.props.data[
                                                '500creditstotal'
                                            ],
                                        },
                                        {
                                            product: '1 mois',
                                            ios: this.props.data[
                                                '1moisabonnementios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '1moisabonnementandroid'
                                                ],
                                            stripe: this.props.data[
                                                '1moisabonnementstripe'
                                            ],
                                            total: this.props.data[
                                                '1moisabonnementtotal'
                                            ],
                                        },
                                        {
                                            product: '3 mois',
                                            ios: this.props.data[
                                                '3moisabonnementios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '3moisabonnementandroid'
                                                ],
                                            stripe: this.props.data[
                                                '3moisabonnementstripe'
                                            ],
                                            total: this.props.data[
                                                '3moisabonnementtotal'
                                            ],
                                        },
                                        {
                                            product: '6 mois',
                                            ios: this.props.data[
                                                '6moisabonnementios'
                                            ],
                                            android:
                                                this.props.data[
                                                    '6moisabonnementandroid'
                                                ],
                                            stripe: this.props.data[
                                                '6moisabonnementstripe'
                                            ],
                                            total: this.props.data[
                                                '6moisabonnementtotal'
                                            ],
                                        },
                                    ],
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        show: true,
                                    },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        show: false,
                                        gridIndex: 0,
                                    },
                                ],
                                series: [
                                    {
                                        type: 'bar',
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                    },
                                    {
                                        type: 'bar',
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                    },
                                    {
                                        type: 'bar',
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                    },
                                    {
                                        type: 'bar',
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                    },
                                ],
                                grid: [
                                    {
                                        top: 0,
                                        left: 5,
                                        bottom: 25,
                                        right: 5,
                                        show: true,
                                        borderWidth: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowBlur: 2,
                                    },
                                ],
                            }}
                            style={{
                                height: 150,
                                width: '100%',
                                padding: 0,
                                margin: 0,
                            }}
                            showLoading={this.props.loading}
                            theme={'theme_name'}
                        />
                    </div>
                </div>
            </Widget>
        )
    }
}

export default WidgetStatNombreBars
