import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Stats {
    /**
     * Get payments statistics
     * @param start start date
     * @param end end date
     */
    public static payments = async (
        start: string,
        end: string,
        callback: any
    ) => {
        if (!start) {
            throw new Error('startismissing')
        }
        if (!end) {
            throw new Error('endismissing')
        }

        Axios.get(
            API_URL + '/v1.0/stats/payments/' + start + "/" + end,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                // callback({
                //     status: "error",
                //     data: null
                // })               
            })
    }

    /**
     * Get payments statistics
     * @param start start date
     * @param end end date
     */
    public static financial = async (
        start: string,
        end: string,
        callback: any
    ) => {
        if (!start) {
            throw new Error('startismissing')
        }
        if (!end) {
            throw new Error('endismissing')
        }

        Axios.get(
            API_URL + '/v1.0/stats/financial/' + start + "/" + end,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                // callback({
                //     status: "error",
                //     data: null
                // })               
            })
    }

    /**
     * Get home statistics
     */
    public static home = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get users statistics
     * @param start start date
     * @param end end date
     */
    public static users = async (
        start: string,
        end: string,
        callback: any
    ) => {
        if (!start) {
            throw new Error('startismissing')
        }
        if (!end) {
            throw new Error('endismissing')
        }

        Axios.get(
            API_URL + '/v1.0/stats/users/' + start + "/" + end,
            system.headerswithjwt()
        )
            .then((response: any) => {
                console.log(response)
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }

    /**
     * Get marketing statistics
     * @param start start date
     * @param end end date
     */
    public static marketing = async (
        start: string,
        end: string,
        callback: any
    ) => {
        if (!start) {
            throw new Error('startismissing')
        }
        if (!end) {
            throw new Error('endismissing')
        }

        Axios.get(
            API_URL + '/v1.0/stats/marketing/' + start + "/" + end,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get maps statistics
     * @param start start date
     * @param end end date
     */
    public static maps = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats/maps',
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get swipeup statistics
     * @param start start date
     * @param end end date
     */
    public static swipeup = async (
        start: string,
        end: string,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats/swipeup/' + start + "/" + end,
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get swipeup statistics
     * @param start start date
     * @param end end date
     */
    public static cars = async (
        start: string,
        end: string,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats/cars/' + start + "/" + end,
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get swipeup statistics
     * @param start start date
     * @param end end date
     */
    public static flashs = async (
        start: string,
        end: string,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats/flashs/' + start + "/" + end,
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Get swipeup statistics
     * @param start start date
     * @param end end date
     */
    public static conversations = async (
        start: string,
        end: string,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/stats/conversations/' + start + "/" + end,
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }
}

export default Stats
