import React from 'react'
import { FaPlus } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core'
import Emails from '../../entity/emails'

import 'react-quill/dist/quill.snow.css'
import './add.css'

class Add extends React.Component<
    {
        history: any
    },
    {
        loading: boolean
        is_layout: boolean
        name: String
        body: String
        lang: String
        layout: String
        subject: String
        layouts: any
    }
> {
    state = {
        loading: false,
        is_layout: false,
        name: '',
        body: '',
        layout: 'us',
        subject: '',
        lang: 'us',
        layouts: [],
    }

    // Init page
    componentDidMount() {
        this.getLayout()
    }

    // Get ads
    getLayout = () => {
        Emails.layouts((res: any) => {
            this.setState({
                layouts: res.data,
            })
        })
    }

    add = async () => {
        Emails.add(
            this.state.name,
            this.state.body,
            this.state.lang,
            this.state.subject,
            this.state.layout,
            this.state.is_layout,
            (msg: any) => {
                if (msg['status'] === 'success') {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            this.props.history.push('/emails')
                        }
                    )
                }
            }
        )
    }

    public render() {
        return (
            <div id="add" className="p20">
                <div id="add_header">
                    <div>
                        <h3>Ajouter l'email</h3>
                        <p>Dans cette page vous pouvez</p>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Infos</h5>

                        <FormGroup
                            helperText=""
                            label="Title"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: Inactive account"
                                min={4}
                                value={this.state.name.toString()}
                                onChange={(msg) => {
                                    this.setState({
                                        name: msg.target.value,
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup
                            helperText=""
                            label="Subject"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: Carimmat - Votre compte est inactif"
                                min={4}
                                value={this.state.subject.toString()}
                                onChange={(msg) => {
                                    this.setState({
                                        subject: msg.target.value,
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup
                            helperText=""
                            label="Langue"
                            labelFor="text-input"
                        >
                            <div className="bp3-html-select">
                                <select
                                    value={this.state.lang}
                                    onChange={(msg: any) => {
                                        this.setState({
                                            lang: msg.target.value,
                                        })
                                    }}
                                >
                                    <option value="">Choose an item...</option>
                                    <option value="en">Anglais (UK)</option>
                                    <option value="us">Anglais (US)</option>
                                    <option value="de">Allemand</option>
                                    <option value="es">Espagnol</option>
                                    <option value="fr">Français</option>
                                    <option value="it">Italien</option>
                                    <option value="ru">Russe</option>
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                            </div>
                        </FormGroup>

                        <FormGroup
                            helperText=""
                            label="Layout"
                            labelFor="text-input"
                        >
                            <div className="bp3-html-select">
                                <select
                                    value={this.state.layout}
                                    onChange={(msg: any) => {
                                        this.setState({
                                            layout: msg.target.value,
                                        })
                                    }}
                                >
                                    {this.state.layouts.map((layout: any) => {
                                        return (
                                            <option value={layout._id}>
                                                {layout.name} -{' '}
                                                {layout.language}
                                            </option>
                                        )
                                    })}
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                            </div>
                        </FormGroup>

                        <FormGroup
                            helperText=""
                            label="Est ce que c'est un template"
                            labelFor="text-input"
                        >
                            <div className="bp3-html-select">
                                <select
                                    value={this.state.is_layout.toString()}
                                    onChange={(msg: any) => {
                                        this.setState({
                                            is_layout:
                                                msg.target.value === 'false'
                                                    ? false
                                                    : true,
                                        })
                                    }}
                                >
                                    <option value="false">Non</option>
                                    <option value="true">Oui</option>
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                            </div>
                        </FormGroup>
                    </div>
                </div>

                <div className="editor">
                    <h5>Email</h5>

                    <div className="bp3-callout bp3-intent-primary">
                        <h4 className="bp3-heading">Information</h4>
                        Les paramêtres qui sont utilisables dans le template
                        sont: <b>[PRENOM]</b>, <b>[YEAR]</b>, <b>[CODE]</b>.
                    </div>
                    <br />

                    <FormGroup helperText="" label="" labelFor="text-input">
                        <TextArea
                            growVertically={true}
                            large={true}
                            placeholder="Ex: Carimmat se mobilise pour votre bonheur. -15% jusqu'au 28 février. N'hésitez plus!"
                            style={{
                                fontSize: 14,
                                height: 700,
                            }}
                            className="bp3-fill"
                            onChange={(msg) => {
                                this.setState({
                                    body: msg.target.value,
                                })
                            }}
                            value={this.state.body}
                        />
                    </FormGroup>
                </div>

                <ButtonLoad
                    loading={this.state.loading}
                    callback={() => {
                        if (!this.state.loading) {
                            this.setState(
                                {
                                    loading: true,
                                },
                                () => this.add()
                            )
                        }
                    }}
                    enable="secondary"
                >
                    <b style={{ fontSize: 14 }}>
                        <FaPlus /> &nbsp;&nbsp;&nbsp;Ajouter l'email
                    </b>
                </ButtonLoad>
            </div>
        )
    }
}

export default Add
