import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core'
import React from 'react'
import {
    FaAndroid,
    FaApple,
    FaArrowsAltH,
    FaAt,
    FaBirthdayCake,
    FaEdit,
    FaHourglass,
    FaSignOutAlt,
    FaTimes,
    FaTrash,
} from 'react-icons/fa'
import UserEntity from '../../entity/user'
import system from '../../system/system'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import moment from 'moment'

import './watch.css'
import Notifs from '../../entity/notifs'

registerLocale('fr', fr)

class Users extends React.Component<
    any,
    {
        userfull: any
        loading: boolean
        dialog_credits: boolean
        dialog_premium: boolean
        dialog_notif: boolean
        fin_abo: Date
        date_abo: Date
        premium_gifted: String
        plateforme: String
        transac_id: String
        product: String
        currency: String
        notif_title: String
        notif_body: String
    }
> {
    state = {
        userfull: {
            user: new UserEntity(),
            cars: [],
            images: [],
            messages: [],
        },
        dialog_credits: false,
        dialog_premium: false,
        dialog_notif: false,
        loading: true,
        fin_abo: new Date(),
        date_abo: new Date(),
        premium_gifted: '',
        plateforme: '',
        transac_id: '',
        product: '',
        currency: '',
        notif_title: '',
        notif_body: '',
    }

    // Init page
    componentDidMount() {
        this.getUser()
    }

    // Get ads
    getUser = () => {
        UserEntity.get(this.props.match.params.id, (res: any) => {
            this.setState({
                userfull: res['data'],
                loading: false,
            })
        })
    }

    // Get ads
    ban = () => {
        UserEntity.ban(this.props.match.params.id, () => {
            let userfull = this.state.userfull
            userfull.user.status = 0
            this.setState({
                userfull,
            })
        })
    }

    public render() {
        return (
            <div id="watch-user">
                <Dialog
                    title="Envoi d'une notification à l'utilisateur"
                    isOpen={this.state.dialog_notif}
                    onClose={() => {
                        this.setState({
                            dialog_notif: false,
                        })
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            helperText=""
                            label="Title"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: Ticket bug premium"
                                value={this.state.notif_title}
                                onChange={(msg) => {
                                    this.setState({
                                        notif_title: msg.target.value,
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup
                            helperText=""
                            label="Body"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: Le bug est résolu"
                                value={this.state.notif_body}
                                onChange={(msg) => {
                                    this.setState({
                                        notif_body: msg.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        dialog_notif: false,
                                    })
                                }}
                                intent="none"
                            >
                                Fermer
                            </Button>
                            <Button
                                onClick={() => {
                                    Notifs.sendNotif(
                                        this.state.userfull.user.id,
                                        this.state.notif_title,
                                        this.state.notif_body,
                                        (msg: any) => {
                                            if (msg['status'] === 'success') {
                                                this.setState({
                                                    dialog_notif: false,
                                                })
                                            }
                                        }
                                    )
                                }}
                                intent="primary"
                            >
                                Envoyer
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    title="Modification du nombre de crédits"
                    isOpen={this.state.dialog_credits}
                    onClose={() => {
                        this.setState({
                            dialog_credits: false,
                        })
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            helperText=""
                            label="Nombre de crédits"
                            labelFor="text-input"
                        >
                            <InputGroup
                                id="text-input"
                                placeholder="Ex: 0"
                                min={3}
                                type={'number'}
                                value={this.state.userfull.user.credits}
                                onChange={(msg) => {
                                    let userfull = this.state.userfull
                                    userfull.user.credits = msg.target.value
                                    this.setState({
                                        userfull: userfull,
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        dialog_credits: false,
                                    })
                                }}
                                intent="none"
                            >
                                Fermer
                            </Button>
                            <Button
                                onClick={() => {
                                    UserEntity.editCredits(
                                        this.state.userfull.user.id,
                                        this.state.userfull.user.credits.toString(),
                                        (msg: any) => {
                                            if (msg['status'] === 'success') {
                                                this.setState({
                                                    dialog_credits: false,
                                                })
                                            }
                                        }
                                    )
                                }}
                                intent="primary"
                            >
                                Modifier
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    title="Donner un premium"
                    isOpen={this.state.dialog_premium}
                    onClose={() => {
                        this.setState({
                            dialog_premium: false,
                        })
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            helperText=""
                            label="Abonnement offert"
                            labelFor="text-input"
                            style={{
                                marginRight: 20,
                            }}
                        >
                            <div className="bp3-html-select">
                                <select
                                    value={this.state.premium_gifted.toString()}
                                    onChange={(msg: any) => {
                                        this.setState({
                                            premium_gifted: msg.target.value,
                                        })
                                    }}
                                >
                                    <option>Choose an item...</option>
                                    <option value="true">Oui</option>
                                    <option value="false">
                                        Non - il a payé sur un des stores
                                    </option>
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                            </div>
                        </FormGroup>
                        <FormGroup
                            helperText=""
                            label="Fin de l'abonnement"
                            labelFor="text-input"
                        >
                            <DatePicker
                                locale={'fr'}
                                selected={this.state.fin_abo}
                                onChange={(date: Date) => {
                                    this.setState({
                                        fin_abo: date,
                                    })
                                }}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>

                        {this.state.premium_gifted === 'false' ? (
                            <div>
                                <hr></hr>

                                <FormGroup
                                    helperText=""
                                    label="Sur quelle plateforme?"
                                    labelFor="text-input"
                                    style={{
                                        marginRight: 20,
                                    }}
                                >
                                    <div className="bp3-html-select">
                                        <select
                                            value={this.state.plateforme.toString()}
                                            onChange={(msg: any) => {
                                                this.setState({
                                                    plateforme:
                                                        msg.target.value,
                                                })
                                            }}
                                        >
                                            <option>Choose an item...</option>
                                            <option value="android">
                                                Android
                                            </option>
                                            <option value="ios">iOS</option>
                                            <option value="stripe">
                                                Stripe
                                            </option>
                                        </select>
                                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                    </div>
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Devise"
                                    labelFor="text-input"
                                    style={{
                                        marginRight: 20,
                                    }}
                                >
                                    <div className="bp3-html-select">
                                        <select
                                            value={this.state.currency.toString()}
                                            onChange={(msg: any) => {
                                                this.setState({
                                                    currency: msg.target.value,
                                                })
                                            }}
                                        >
                                            <option>Choose an item...</option>
                                            <option value="eur">€</option>
                                            <option value="dol">$</option>
                                            <option value="rub">₽</option>
                                        </select>
                                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                    </div>
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Combien de mois?"
                                    labelFor="text-input"
                                    style={{
                                        marginRight: 20,
                                    }}
                                >
                                    <div className="bp3-html-select">
                                        <select
                                            value={this.state.product.toString()}
                                            onChange={(msg: any) => {
                                                this.setState({
                                                    product: msg.target.value,
                                                })
                                            }}
                                        >
                                            <option>Choose an item...</option>
                                            <option value="sub_months_1">
                                                1 mois
                                            </option>
                                            <option value="sub_months_3">
                                                3 mois
                                            </option>
                                            <option value="sub_months_6">
                                                6 mois
                                            </option>
                                        </select>
                                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                    </div>
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Id de la transaction"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: sub_ / 000001177121950 / pejmgbhnifongmpdcbcblgea.AO-J1Oz9uY8RPNFxLgdNlIJJLQtm6RP9XuR67eEkX5gfo8p9ykHhk_CXHIGHsaKuvuZ2KAg0ZkXAb2Yb56_jZ72QLXBNiMWnAw"
                                        value={this.state.transac_id}
                                        onChange={(msg) => {
                                            this.setState({
                                                transac_id: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Date du paiement"
                                    labelFor="text-input"
                                >
                                    <DatePicker
                                        locale={'fr'}
                                        selected={this.state.date_abo}
                                        onChange={(date: Date) => {
                                            this.setState({
                                                date_abo: date,
                                            })
                                        }}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </FormGroup>
                            </div>
                        ) : null}
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        dialog_premium: false,
                                    })
                                }}
                                intent="none"
                            >
                                Fermer
                            </Button>
                            <Button
                                onClick={() => {
                                    console.log(this.state)
                                    if (
                                        this.state.premium_gifted === 'true' ||
                                        (this.state.premium_gifted ===
                                            'false' &&
                                            this.state.currency !== '' &&
                                            this.state.plateforme !== '' &&
                                            this.state.transac_id !== '')
                                    ) {
                                        UserEntity.givePremium(
                                            this.state.userfull.user.id,
                                            this.state.premium_gifted === 'true'
                                                ? true
                                                : false,
                                            moment(this.state.fin_abo).format(
                                                'YYYY-MM-DDT00:00:00'
                                            ) + 'Z',
                                            this.state.plateforme.toString(),
                                            this.state.transac_id.toString(),
                                            moment(this.state.date_abo).format(
                                                'YYYY-MM-DDT00:00:00'
                                            ) + 'Z',
                                            this.state.product.toString(),
                                            this.state.currency.toString(),
                                            (msg: any) => {
                                                if (
                                                    msg['status'] === 'success'
                                                ) {
                                                    let userfull =
                                                        this.state.userfull
                                                    userfull.user.premium_account = 1
                                                    userfull.user.premium_end =
                                                        this.state.fin_abo.toString()
                                                    userfull.user.premium_gifted =
                                                        this.state
                                                            .premium_gifted ===
                                                        'true'
                                                            ? true
                                                            : false
                                                    this.setState({
                                                        dialog_premium: false,
                                                    })
                                                }
                                            }
                                        )
                                    }
                                }}
                                intent="primary"
                            >
                                Ajouter l'abonnement
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <div id="user-banner"></div>

                <div id="user-top">
                    <img
                        id="avatar"
                        width={150}
                        height={150}
                        src={this.state.userfull.user.avatar.replace(
                            'http://cdn-eu.carimmat.com',
                            'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                        )}
                        alt="avatar"
                    />

                    <span
                        className={
                            'label ' +
                            (this.state.userfull.user.status !== 1
                                ? 'label-danger'
                                : 'label-success')
                        }
                    >
                        {this.state.userfull.user.status === 1
                            ? 'Normal'
                            : this.state.userfull.user.status === 0 &&
                              this.state.userfull.user.email_clair ===
                                  'deleted@carimmat.com'
                            ? 'Supprimé'
                            : 'Bloqué'}
                    </span>

                    {this.state.userfull.user.premium_account === 1 ? (
                        <div className="row">
                            <div>
                                <img
                                    width={40}
                                    height={40}
                                    alt={'premium'}
                                    src="/assets/images/diamant.png"
                                />
                            </div>

                            <div>
                                <Button
                                    onClick={() => {
                                        UserEntity.removePremium(
                                            this.state.userfull.user.id,
                                            (msg: any) => {
                                                if (
                                                    msg['status'] === 'success'
                                                ) {
                                                    let userfull =
                                                        this.state.userfull
                                                    userfull.user.premium_account = 0
                                                    this.setState({
                                                        userfull,
                                                    })
                                                }
                                            }
                                        )
                                    }}
                                    intent="none"
                                >
                                    <FaTrash />
                                </Button>
                            </div>
                        </div>
                    ) : null}

                    <div>
                        <h3>{this.state.userfull.user.firstname}</h3>
                        <span>
                            {this.state.userfull.user.city},{' '}
                            {this.state.userfull.user.country}
                        </span>
                    </div>

                    <div style={{ position: 'relative', paddingRight: 50 }}>
                        <h3>{this.state.userfull.user.credits}</h3>
                        <span>CREDITS</span>
                        <Button
                            style={{ position: 'absolute', right: 0 }}
                            onClick={() => {
                                this.setState({
                                    dialog_credits: true,
                                })
                            }}
                        >
                            <FaEdit />
                        </Button>
                    </div>

                    {this.state.userfull.user.premium_account === 1 ? null : (
                        <div>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        dialog_premium: true,
                                    })
                                }}
                            >
                                <FaEdit /> Donner un premium
                            </Button>
                        </div>
                    )}

                    {this.state.userfull.user.status === 1 ? (
                        <div>
                            <Button intent="warning" onClick={this.ban}>
                                <FaTimes color="white" />{' '}
                                <span style={{ color: 'white' }}>
                                    Bannir l'utilisateur
                                </span>
                            </Button>
                        </div>
                    ) : null}
                </div>

                <div id="user-info">
                    <div className="card" style={{ flexBasis: '25%' }}>
                        <h3>A propos de l'utilisateur</h3>
                        <p>{this.state.userfull.user.description}</p>
                        <hr />
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <FaBirthdayCake /> Date de naissance
                                    </td>
                                    <td>
                                        {this.state.userfull.user.age +
                                            ' - ' +
                                            this.state.userfull.user.birth_date}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FaAt /> Email
                                    </td>
                                    <td>
                                        {this.state.userfull.user.email_clair}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FaSignOutAlt /> Créé le
                                    </td>
                                    <td>
                                        {this.state.userfull.user.created_at !==
                                            '' &&
                                        this.state.userfull.user.created_at !==
                                            null
                                            ? system.getActualDateFromDateHourZ(
                                                  this.state.userfull.user
                                                      .created_at
                                              )
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FaArrowsAltH /> Dernière connexion
                                    </td>
                                    <td>
                                        {this.state.userfull.user
                                            .last_connexion !== '' &&
                                        this.state.userfull.user
                                            .last_connexion !== null
                                            ? system.getActualDateFromDateHourZ(
                                                  this.state.userfull.user
                                                      .last_connexion
                                              )
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FaHourglass /> Fin de l'abonnement
                                    </td>
                                    <td>
                                        {this.state.userfull.user
                                            .premium_end !== '' &&
                                        this.state.userfull.user.premium_end !==
                                            null
                                            ? system.getActualDateFromDateHourZ(
                                                  this.state.userfull.user
                                                      .premium_end
                                              )
                                            : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card" style={{ flexBasis: '40%' }}>
                        <h3>Derniers messages de l'utilisateur</h3>

                        {this.state.userfull.messages.map((msg: any) => {
                            return (
                                <div className="message" key={msg._id}>
                                    {system.getDateZ(msg.date)} - {msg.message}{' '}
                                    {msg.photo ? (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={msg.photo}
                                        >
                                            Photo
                                        </a>
                                    ) : null}
                                </div>
                            )
                        })}
                    </div>

                    <div className="card" style={{ flexBasis: '25%' }}>
                        <h3>Images</h3>

                        {this.state.userfull.images.map((img) => (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={img}
                            >
                                <img
                                    width={150}
                                    height={150}
                                    alt="avatars"
                                    src={img}
                                />
                            </a>
                        ))}
                    </div>

                    <div className="card">
                        <h3>Cars</h3>

                        {this.state.userfull.cars.map((car: any) => (
                            <div key={car._id}>
                                {car.type} - {car.brand} - {car.immatriculation}{' '}
                                - {car.color} - {car.country}
                            </div>
                        ))}
                    </div>

                    <div className="card" style={{ maxWidth: 700 }}>
                        <h3>Téléphone</h3>

                        {this.state.userfull.user.api_token !== '' ? (
                            <Button
                                onClick={() =>
                                    this.setState({
                                        dialog_notif: true,
                                    })
                                }
                            >
                                Envoyer une notification à l'utilisateur
                            </Button>
                        ) : null}

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        {this.state.userfull.user.platform ===
                                        'android' ? (
                                            <FaAndroid />
                                        ) : (
                                            <FaApple />
                                        )}
                                    </td>
                                    <td>{this.state.userfull.user.platform}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users
