import React from 'react'
import { FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import ReactPaginate from 'react-paginate'

import './metrics.css'
import system from '../../system/system'
import { FaMars, FaPlay, FaTransgender, FaVenus } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import { Link } from 'react-router-dom'
import SystemEntity from '../../entity/system'

class Metrics extends React.Component<
    any,
    {
        metrics: any
        loading: boolean
        page: number
        nbmetricsfilter: number
        search: String
    }
> {
    state = {
        metrics: [],
        loading: true,
        search: '',
        page: 0,
        nbmetricsfilter: 0,
    }

    // Init page
    componentDidMount() {
        this.getUsers()
    }

    // Get ads
    getUsers = () => {
        SystemEntity.metrics(this.state.page, this.state.search, (res: any) => {
            this.setState({
                metrics: res.data.metrics.sort((a: any, b: any) =>
                    a.created_at > b.created_at ? -1 : 1
                ),
                nbmetricsfilter: res.data.nbmetrics,
                loading: false,
            })
        })
    }

    public render() {
        return (
            <div id="metrics">
                <div id="users_header">
                    <div>
                        <h3>Dashboard metrics</h3>
                        <p>
                            Retrouver toutes les metrics concernant les
                            utilisateurs sur ce dashboard.
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div id="filter">
                        <div id="filter_header">
                            <h5>Filtre</h5>
                        </div>

                        <div id="filter_body">
                            <div id="filter_pref">
                                <div className="bp3-callout bp3-intent-primary">
                                    <h4 className="bp3-heading">
                                        Information non personnel
                                    </h4>
                                    Dans le champ texte vous pouvez mettre
                                    n'importe quel mot pour rechercher par
                                    rapport au : titre, id de l'user.
                                </div>

                                <FormGroup
                                    helperText="Entrez un mot que vous voulez rechercher"
                                    label="Mot(s)"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Mot à rechercher"
                                        max={100}
                                        min={10}
                                        value={this.state.search}
                                        onChange={(msg) => {
                                            this.setState({
                                                search: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <ButtonLoad
                            loading={this.state.loading}
                            callback={() => {
                                this.setState(
                                    {
                                        loading: true,
                                        page: 0,
                                    },
                                    () => this.getUsers()
                                )
                            }}
                            enable="secondary"
                        >
                            <b style={{ fontSize: 14 }}>
                                <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA RECHERCHE
                            </b>
                        </ButtonLoad>
                    </div>

                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Route</th>
                                    <th>Utilisateur</th>
                                    <th>Status</th>
                                    <th>Durée</th>
                                    <th>Params</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? (
                                    <tr>
                                        <td colSpan={12}>
                                            <Spinner />
                                        </td>
                                    </tr>
                                ) : null}

                                {!this.state.loading
                                    ? this.state.metrics.map((u: any) => {
                                          return (
                                              <tr key={u._id}>
                                                  <td>
                                                      <span
                                                          className={
                                                              'type_request ' +
                                                              u.type.toLowerCase()
                                                          }
                                                      >
                                                          {u.type}
                                                      </span>
                                                  </td>
                                                  <td>
                                                      {system.getDateZ(u.date)}
                                                  </td>
                                                  <td>{u.route}</td>
                                                  <td>
                                                      <Link
                                                          to={'users/' + u.from}
                                                      >
                                                          {u.sex === 1 ? (
                                                              <FaMars />
                                                          ) : u.sex === 2 ? (
                                                              <FaVenus />
                                                          ) : u.sex === 3 ? (
                                                              <FaTransgender />
                                                          ) : null}
                                                          <b>
                                                              {u.from !== ''
                                                                  ? u.from
                                                                  : 'Inconnu'}
                                                          </b>
                                                      </Link>
                                                  </td>
                                                  <td>{u.status}</td>
                                                  <td>{u.duration}ms</td>
                                                  <td>{u.params}</td>
                                              </tr>
                                          )
                                      })
                                    : null}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Suivant >"
                                onPageChange={(e) => {
                                    console.log('pagechange', e)
                                    this.setState(
                                        {
                                            loading: true,
                                            page: e.selected,
                                        },
                                        () => this.getUsers()
                                    )
                                }}
                                pageRangeDisplayed={5}
                                pageCount={Math.floor(
                                    this.state.nbmetricsfilter / 20
                                )}
                                previousLabel="< Précédent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Metrics
