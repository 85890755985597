import React from 'react'
import R from '../../entity/bugs'
import system from '../../system/system'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { FaCheck } from 'react-icons/fa'

import './bugs.css'

class Bugs extends React.Component<
    {},
    {
        bugs: any
        page: number
    }
> {
    state = {
        bugs: [],
        page: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        R.list(this.state.page, (msg: any) => {
            this.setState({
                bugs: msg['data'],
            })
        })
    }

    done = (_id: string) => {
        R.done(_id, (msg: any) => {
            if (msg.data === true) {
                let bugs: any = this.state.bugs
                for (let u in bugs) {
                    if (bugs[u]._id === _id) {
                        bugs[u].resolved = true
                    }
                }

                this.setState({
                    bugs,
                })
            }
        })
    }

    public render() {
        return (
            <div id="bugs" className="p20">
                <div id="bugs_header">
                    <div>
                        <h3>Bugs</h3>
                        <p>Retrouver tous les bugs</p>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date du report</th>
                            <th>Status</th>
                            <th>Titre</th>
                            <th>Message</th>
                            <th>Image</th>
                            <th>Utilisateur</th>
                            <th>Fait</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.bugs.map((k: any) => {
                            return (
                                <tr key={k._id}>
                                    <td>{system.getDateWithoutHour(k.date)}</td>
                                    <td>
                                        {k.resolved === true ? (
                                            <span className="label label-success">
                                                Fait
                                            </span>
                                        ) : (
                                            <span className="label label-warning">
                                                A faire
                                            </span>
                                        )}
                                    </td>

                                    <td>{k.subject}</td>
                                    <td>{k.message}</td>
                                    <td>
                                        {k.file !== null && k.file !== '' ? (
                                            <a
                                                href={k.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Lien
                                            </a>
                                        ) : null}
                                    </td>

                                    <td>
                                        <Link to={'users/' + k.from}>
                                            {k.from}
                                        </Link>
                                    </td>

                                    <td>
                                        {k.resolved === false ? (
                                            <Button
                                                onClick={() => this.done(k._id)}
                                            >
                                                <FaCheck />
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Bugs
