import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Map from '../../components/map/map'
import system from '../../system/system'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import ButtonLoad from '../../components/button-load/button-load'
import Axios from 'axios'
import { API_URL } from '../../system/const'
import { FaPlay, FaRetweet } from 'react-icons/fa'
import UserSmall from '../../components/user-small/user-small'
import { Link } from 'react-router-dom'

import './users-around.css'

class UsersAround extends React.Component<
    any,
    {
        filter: boolean
        loading: boolean
        users: any
        distance: number
        minage: number
        maxage: number
        attirance: number
        nbusers: number
    }
> {
    state = {
        filter: true,
        loading: false,
        users: [],
        distance: 100,
        minage: 18,
        maxage: 100,
        attirance: 3,
        nbusers: 0,
    }
    position: any

    usersAround = () => {
        if (this.position) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    Axios.post(
                        API_URL +
                            '/v1.0/geoloc/' +
                            this.position.lat +
                            '/' +
                            this.position.lng +
                            '/admin',
                        {
                            distance: this.state.distance,
                            minage: this.state.minage,
                            maxage: this.state.maxage,
                            attirance: this.state.attirance,
                        },
                        {
                            headers: {
                                Authorization: system.jwt,
                            },
                        }
                    )
                        .then((msg) => {
                            this.setState({
                                loading: false,
                                filter: false,
                                users: msg.data.results.users,
                                nbusers: msg.data.results.nbusers,
                            })
                        })
                        .catch((err) => {
                            this.setState({
                                loading: false,
                                filter: false,
                            })
                        })
                }
            )
        }
    }

    public render() {
        return (
            <div id="users_around">
                <div id="users_around_header">
                    <div>
                        <h3>Simulation de la geoloc</h3>
                        <p>
                            Tester la fonctionnalité de recherche d'utilisateurs
                            autour de soi partout dans le monde. Pratique pour
                            tester l'import de bots ou de ce que peuvent voir
                            certains utilisateurs.
                        </p>
                    </div>
                    <Button
                        onClick={() => {
                            this.setState({
                                filter: !this.state.filter,
                            })
                        }}
                        style={{ height: 30 }}
                    >
                        Filtre
                    </Button>
                </div>

                {this.state.filter ? (
                    <div id="filter">
                        <h5>Filtre</h5>

                        <div id="filter_body">
                            <div id="filter_pref">
                                <div className="bp3-callout bp3-intent-primary">
                                    <h4 className="bp3-heading">Information</h4>
                                    Cliquer sur la map à l'endroit où vous
                                    souhaitez rechercher. Vous pouvez modifier
                                    les préférences pour obtenir différent
                                    profil.
                                </div>

                                <FormGroup
                                    helperText="La distance est comprise entre 10 et 100 km"
                                    label="Distance"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Distance à rechercher"
                                        type="number"
                                        max={100}
                                        min={10}
                                        value={this.state.distance.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                distance: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    helperText="L'âge minimum"
                                    label="Âge minimum"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Âge minimum des utilisateurs"
                                        type="number"
                                        max={99}
                                        min={18}
                                        value={this.state.minage.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                minage: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    helperText="L'âge maximum"
                                    label="Âge maximum"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Âge maximum des utilisateurs"
                                        type="number"
                                        max={100}
                                        min={19}
                                        value={this.state.maxage.toString()}
                                        onChange={(msg) => {
                                            this.setState({
                                                maxage: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    />
                                </FormGroup>
                                <p>Attirance</p>
                                <div className="bp3-html-select">
                                    <select
                                        value={this.state.attirance}
                                        onChange={(msg) => {
                                            this.setState({
                                                attirance: parseInt(
                                                    msg.target.value
                                                ),
                                            })
                                        }}
                                    >
                                        <option value="1">Homme</option>
                                        <option value="2">Femme</option>
                                        <option value="3">Les deux</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                </div>

                                <ButtonLoad
                                    loading={this.state.loading}
                                    callback={() => {
                                        this.usersAround()
                                    }}
                                    enable="secondary"
                                >
                                    <b style={{ fontSize: 14 }}>
                                        <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA
                                        RECHERCHE
                                    </b>
                                </ButtonLoad>
                            </div>
                            <div id="filter_map">
                                <Map
                                    onClick={(position: any) =>
                                        (this.position = position)
                                    }
                                    markers={[]}
                                    center_follow={true}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div id="users">
                    <div id="users_header">
                        <div>
                            <h5>Utilisateurs autour de votre position</h5>
                        </div>
                        <Button
                            onClick={this.usersAround}
                            style={{ height: 30 }}
                        >
                            <FaRetweet />
                        </Button>
                    </div>

                    {this.state.users.length === 0 ? (
                        <div className="bp3-callout bp3-intent-primary">
                            <h4 className="bp3-heading">
                                Pas d'utilisateurs autour de vous
                            </h4>
                            Il n'y a personne autour de vous actuellement.
                            Veuillez modifier la position ou les préférences.
                        </div>
                    ) : (
                        <p>
                            {this.state.nbusers} utilisateurs autour de cetter
                            position
                        </p>
                    )}

                    <div id="users_list">
                        {this.state.users.map((user: any) => {
                            return (
                                <Link to={'users/' + user.id}>
                                    <UserSmall key={user.id} user={user} />
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default UsersAround
