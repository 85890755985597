import Axios from 'axios'
import jstz from 'jstimezonedetect'
import moment from 'moment-timezone'
import User from '../entity/user'
import { API_URL } from './const'
import Navigator from './navigator'
import 'moment/locale/fr';

class System {
    public user: string = ''
    public userobject: User = new User()
    public lang: string = 'fr'
    public timezone: string = ''
    public routes: any = []
    public jwt: string = ''
    public refresh: any = () => { }

    public headers = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    public getMomentFromApiDate = (date: string) => {
        return moment(date + '.000Z')
            .locale(this.lang)
            .tz(this.timezone)
    }

    public headerswithjwt = (options?: any) => {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.jwt,
            },
            ...options
        }
    }

    public headerswithjwtformdata = (options?: any) => {
        return {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': this.jwt,
            },
            ...options
        }
    }

    /**
     * Init the system
     */
    public init = async (callback: any) => {
        this.initDates()
        this.initLang()
        this.getUser(callback);
    }

    public getUser = async (callback: any) => {

        // Try to get the user
        const jwt = localStorage.getItem('jwt')
        this.jwt = jwt === null ? '' : jwt

        // If a user is saved
        if (jwt !== null) {
            // Get information about the user
            try {
                let response: any = await Axios.get(
                    API_URL + '/v1.0/users/admin',
                    this.headerswithjwt()
                )
                this.userobject.mail = response.data.results.email_clair
                this.userobject.avatar = response.data.results.avatar
                this.userobject.uuid = response.data.results.id
                this.userobject.firstname = response.data.results.firstname

                this.routes = Navigator.getRoutes()

                this.setUser(response.data.results.id)

                callback()
            } catch (e) {
                callback()
            }
        } else {
            callback()
        }
    }

    public hasUserDefined = () => {
        return localStorage.getItem('user')
    }

    public setUser = (user: string) => {
        this.user = user
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getDate = (date: string) => {
        date = date + '.000Z'
        return moment(date).locale(this.lang).tz(this.timezone).calendar()
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getDateZ = (date: string) => {
        return moment(date).locale(this.lang).tz(this.timezone).calendar()
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getDateWithoutHour = (date: string) => {
        return moment(date).locale(this.lang).tz(this.timezone).calendar()
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualMonthString = () => {
        return moment().locale(this.lang).tz(this.timezone).format('MMMM')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getMonthStringFromString = (date: string) => {
        return moment(date).locale(this.lang).tz(this.timezone).format('MMMM')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getMonthDateAndHourtStringFromString = (date: string) => {
        return moment(date)
            .locale(this.lang)
            .tz(this.timezone)
            .format('MMMM Do @hA')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualDate = () => {
        return moment().locale(this.lang).tz(this.timezone).format('YYYY-MM-DD')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualDateFromDate = (date: string) => {
        return moment(date)
            .locale(this.lang)
            .tz(this.timezone)
            .format('YYYY-MM-DD')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualDateFromDateHourZ = (date: string) => {
        return moment(date)
            .locale(this.lang)
            .tz(this.timezone)
            .format('YYYY-MM-DD HH:mm')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualDateFromDateHour = (date: string) => {
        date = date + '.000Z'
        return moment(date)
            .locale(this.lang)
            .tz(this.timezone)
            .format('YYYY-MM-DD HH:mm')
    }

    /**
     * Get the date in calendar mode with the lang and the timezone
     */
    public getActualDateFromMoment = (date: moment.Moment) => {
        return moment(date)
            .locale(this.lang)
            .tz(this.timezone)
            .format('YYYY-MM-DD HH:mm')
    }

    /**
     * Get the default langagnes
     */
    public initLang = () => {
        this.lang = navigator.language
        console.info('Init language ', this.lang)
    }

    /**
     * Init dates management
     * Get the local timezone
     */
    public initDates = () => {
        this.timezone = jstz.determine().name()
        console.info('Init dates ', this.timezone)
    }

    /**
     * Log out
     */
    public logOut = () => {
        this.user = ''
        localStorage.clear()
    }

    public roundFloat = (f: number) => {
        if (f < 10) {
            return Math.round(f * 10000) / 10000
        }
        return Math.round(f * 100) / 100
    }

    public beautify = (n: any) =>
        ((Math.log10(n) / 3) | 0) === 0
            ? n
            : Number(
                (n / Math.pow(10, ((Math.log10(n) / 3) | 0) * 3)).toFixed(1)
            ) + ['', 'K', 'M', 'B', 'T'][(Math.log10(n) / 3) | 0]

    public randomHexaColor = () =>
        '#' + Math.floor(Math.random() * 16777215).toString(16)
}

const system = new System()
export default system
