import React from 'react'
import { FaHourglass } from 'react-icons/fa'

import './widget.css'

class Widget extends React.Component<
    {
        loading: boolean
    },
    {}
> {
    render() {
        return (
            <div className={'widget'}>
                {this.props.loading ? (
                    <FaHourglass className="spin" size={35} />
                ) : (
                    this.props.children
                )}
            </div>
        )
    }
}

export default Widget
