import React from 'react'
import Widget from '../widget/widget'
import ReactECharts from 'echarts-for-react'

import './widget-stat-nombre-graph.css'

class WidgetStatNombreGraph extends React.Component<
    {
        loading: boolean
        title: String
        period: String
        value: number
        mainColor: String
        color: String
        pourcentage: number
        data: any
    },
    {}
> {
    render() {
        return (
            <Widget loading={this.props.loading}>
                <div className="widget-stat-nombre-graph">
                    <div className="title">
                        <div>
                            <h3>{this.props.title}</h3>
                            <h1>{this.props.value}</h1>
                        </div>
                        <div>
                            <span className="period">{this.props.period}</span>
                        </div>
                    </div>
                    <div className="full">
                        <ReactECharts
                            option={{
                                tooltip: {
                                    trigger: 'item',
                                },
                                title: {
                                    show: false,
                                },
                                legend: {
                                    show: false,
                                },
                                dataset: {
                                    dimensions: ['date', 'value'],
                                    source: this.props.data,
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        show: false,
                                        gridIndex: 0,
                                    },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        show: false,
                                        gridIndex: 0,
                                    },
                                ],
                                series: [
                                    {
                                        type: 'line',
                                        itemStyle: {
                                            color: this.props.mainColor,
                                        },
                                        areaStyle: {
                                            color: this.props.color,
                                        },
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                    },
                                ],
                                grid: [
                                    {
                                        top: 0,
                                        left: -10,
                                        bottom: 0,
                                        right: -10,
                                        show: true,
                                        borderWidth: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowBlur: 2,
                                    },
                                ],
                            }}
                            style={{
                                height: 100,
                                width: '100%',
                                padding: 0,
                                margin: 0,
                            }}
                            showLoading={this.props.loading}
                            theme={'theme_name'}
                        />
                    </div>
                </div>
            </Widget>
        )
    }
}

export default WidgetStatNombreGraph
