import React from 'react'
import { FaMars, FaVenus } from 'react-icons/fa'

import './user-small.css'

class UserSmall extends React.Component<
    {
        user: any
    },
    {}
> {
    render() {
        return (
            <div className="user-small">
                <img
                    style={{ objectFit: 'cover' }}
                    alt="avatar"
                    src={this.props.user.avatar}
                />
                <h3>{this.props.user.firstname}</h3>
                <p>
                    {' '}
                    {this.props.user.sex === 1 ? (
                        <FaMars />
                    ) : (
                        <FaVenus />
                    )} ~ {this.props.user.distance}
                </p>
            </div>
        )
    }
}

export default UserSmall
