import React from 'react'
import UserEntity from '../../entity/user'
import system from '../../system/system'
import { Button } from '@blueprintjs/core'

class Login extends React.Component<
    { logincompleted: boolean },
    { requestLoading: boolean; error: '' }
> {
    public mail: string = ''
    public password: string = ''

    constructor(props: any) {
        super(props)

        this.state = {
            requestLoading: false,
            error: '',
        }
    }

    /**
     * Change the mail
     */
    public mailChange = (res: any) => {
        this.mail = res.target.value
    }

    /**
     * Change the password
     */
    public passwordChange = (res: any) => {
        this.password = res.target.value
    }

    /**
     * Log in function. Send a request and manage alerts
     */
    public logIn = () => {
        this.setState({
            requestLoading: true,
        })

        // Send a request to the api
        UserEntity.loginPassword(this.mail, this.password, (data: any) => {
            if (data.status === 'success') {
                localStorage.setItem('jwt', data.data)
                system.getUser(() => {
                    system.refresh()
                })
            } else {
                this.setState({
                    requestLoading: false,
                    error: data.error,
                })
            }
        })
    }

    /**
     * Render the screen
     */
    public render = () => {
        return (
            <div id="login">
                {this.state.error === '' ? (
                    ''
                ) : (
                    <div
                        className="bp3-callout bp3-intent-danger"
                        style={{ marginBottom: 15 }}
                    >
                        <h4 className="bp3-heading">Error!!</h4>
                        <p style={{ color: 'white' }}>
                            Error: The email address or the password is
                            incorrect. Try again. @DEBUG: {this.state.error}
                        </p>
                    </div>
                )}

                <form>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <span>Email address</span>
                        <input
                            type="email"
                            placeholder="Enter email"
                            onChange={this.mailChange}
                        />
                        <span>
                            We'll never share your email with anyone else.
                        </span>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <span>Password</span>
                        <input
                            type="password"
                            placeholder="Password"
                            onChange={this.passwordChange}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <span>Se souvenir de moi</span>
                        <input type="checkbox" />
                    </div>

                    <Button
                        type="button"
                        className="bp3-button bp3-intent-primary"
                        loading={this.state.requestLoading}
                        onClick={this.logIn}
                    >
                        Submit
                    </Button>
                </form>
            </div>
        )
    }
}

export default Login
