import { Position, Tooltip } from '@blueprintjs/core'
import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import Widget from '../widget/widget'

import './widget-stat-nombre-small.css'

class WidgetStatNombreSmall extends React.Component<
    {
        loading: boolean
        title: String
        help?: string
        value: number | String
        after_value?: String
    },
    {}
> {
    render() {
        return (
            <Widget loading={this.props.loading}>
                <div className="widget-stat-nombre-small">
                    <div className="center_value">
                        <h3>{this.props.title}</h3>
                        {this.props.help ? (
                            <Tooltip
                                content={this.props.help}
                                position={Position.BOTTOM}
                                openOnTargetFocus={false}
                            >
                                <FaInfoCircle color="#C9CFD7" />
                            </Tooltip>
                        ) : null}
                    </div>
                    <div>
                        <h1>
                            {this.props.value}{' '}
                            {this.props.after_value
                                ? this.props.after_value
                                : ''}
                        </h1>
                    </div>
                </div>
            </Widget>
        )
    }
}

export default WidgetStatNombreSmall
