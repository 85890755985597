import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import system from './system/system'
import Connected from './templates/connected'
import SignIn from './templates/signin'
import Loading from './components/loading/loading'

import 'react-datepicker/dist/react-datepicker.css'

import './app.css'

class App extends React.Component<
    any,
    { loading: boolean; logincompleted: boolean }
> {
    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            logincompleted: false,
        }

        system.refresh = this.refresh

        setTimeout(() => {
            system.init(this.endLoading)
        }, 100)
    }

    /**
     * Refresh the screen
     */
    public endLoading = () => {
        this.setState({
            loading: false,
        })
    }

    /**
     * Refresh the screen
     */
    public refresh = () => {
        this.setState({
            logincompleted: !this.state.logincompleted,
        })
    }

    /**
     * Render the screen
     */
    public render() {
        return (
            <div id="app">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Carimmat</title>
                </Helmet>

                <Loading enable={this.state.loading} />

                {system.user === '' ? (
                    <SignIn logincompleted={this.state.logincompleted} />
                ) : (
                    <BrowserRouter>
                        <Connected
                            logincompleted={this.state.logincompleted}
                            refreshParent={this.refresh}
                        ></Connected>
                    </BrowserRouter>
                )}
            </div>
        )
    }
}

export default App
