import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core'
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import Promotions from '../../entity/promotions'
import DatePicker from 'react-datepicker'

import './add.css'
import moment from 'moment'

class Add extends React.Component<
    {
        history: any
    },
    {
        loading: boolean
        names: any
        descriptions: any
        start: moment.Moment
        end: moment.Moment
        link: String
        type: String
        value: String
        stripe_coupon: String
        prices: any
    }
> {
    state = {
        loading: false,
        names: {
            fr: '',
            es: '',
            it: '',
            de: '',
            en: '',
            us: '',
            ru: '',
        },
        descriptions: {
            fr: '',
            es: '',
            it: '',
            de: '',
            en: '',
            us: '',
            ru: '',
        },
        start: moment(),
        end: moment().add(10, 'days'),
        link: '',
        type: '',
        value: '',
        stripe_coupon: '',
        prices: {
            eur: {
                ios: '16.99',
                android: '16.99',
                stripe: '13.9',
            },
            usd: {
                ios: '16.99',
                android: '16.99',
                stripe: '13.9',
            },
            rub: {
                ios: '980',
                android: '980',
                stripe: '812',
            },
        },
    }

    add = async () => {
        Promotions.add(
            this.state.names,
            this.state.descriptions,
            this.state.start.format('YYYY-MM-DDT00:00:00') + 'Z',
            this.state.end.format('YYYY-MM-DDT00:00:00') + 'Z',
            this.state.type,
            this.state.value,
            this.state.link,
            this.state.stripe_coupon,
            this.state.prices,
            (msg: any) => {
                if (msg['status'] === 'success') {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            this.props.history.push('/promotions')
                        }
                    )
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }
        )
    }

    public render() {
        return (
            <div id="add" className="p20">
                <div id="add_header">
                    <div>
                        <h3>Ajouter une promotion</h3>
                        <p>
                            Dans cette page vous pourrez ajouter une promotion
                            sur l'application.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Informations</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <FormGroup
                                    helperText=""
                                    label="Nom"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Ex: St Valentin"
                                        min={3}
                                        value={this.state.names.fr.toString()}
                                        onChange={(msg) => {
                                            let names = this.state.names
                                            names.fr = msg.target.value
                                            this.setState({
                                                names: names,
                                            })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup
                                    helperText=""
                                    label="Description"
                                    labelFor="text-input"
                                >
                                    <TextArea
                                        growVertically={true}
                                        large={true}
                                        placeholder="Ex: Carimmat se mobilise pour votre bonheur. -15% jusqu'au 28 février. N'hésitez plus!"
                                        style={{
                                            fontSize: 14,
                                        }}
                                        className="bp3-fill"
                                        onChange={(msg) => {
                                            let descriptions =
                                                this.state.descriptions
                                            descriptions.fr = msg.target.value
                                            this.setState({
                                                descriptions: descriptions,
                                            })
                                        }}
                                        value={this.state.descriptions.fr}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Disponibilité</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Date de début"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.start.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    start: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsStart
                                            startDate={this.state.start.toDate()}
                                            endDate={this.state.end.toDate()}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        helperText=""
                                        label="Date de fin"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.end.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    end: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsEnd
                                            startDate={this.state.start.toDate()}
                                            endDate={this.state.end.toDate()}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>

                        {this.state.start.format('YYYY-mm-DD') ===
                        moment().format('YYYY-mm-DD') ? (
                            <div className="bp3-callout bp3-intent-warning">
                                <h4 className="bp3-heading">Information</h4>
                                Malheureusement la promotion est en cours est
                                sera donc mis à jour uniquement demain.
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Prix</h5>

                        <div className="bp3-callout bp3-intent-primary">
                            <h4 className="bp3-heading">Information</h4>
                            Le pourcentage de réduction est utilisé uniquement
                            dans les textes promotionnels.
                        </div>
                        <br />

                        <div className="filter_body">
                            <div className="filter_pref">
                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Type"
                                        labelFor="text-input"
                                    >
                                        <div className="bp3-html-select">
                                            <select
                                                value={this.state.type}
                                                onChange={(msg: any) => {
                                                    this.setState({
                                                        type: msg.target.value,
                                                    })
                                                }}
                                            >
                                                <option>
                                                    Choisissez un type de
                                                    promotion...
                                                </option>
                                                <option value="reduction">
                                                    Réduction
                                                </option>
                                            </select>
                                            <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                                        </div>
                                    </FormGroup>

                                    {this.state.type === 'reduction' ? (
                                        <FormGroup
                                            helperText=""
                                            label="Pourcentage de réduction"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Ex: 15"
                                                min={3}
                                                type="number"
                                                value={this.state.value.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        value: msg.target.value,
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    ) : null}
                                </div>

                                <div className="bp3-callout bp3-intent-warning">
                                    <h4 className="bp3-heading">Attention</h4>
                                    Vous devez mettre les prix directement dans
                                    l'app store, le play store et stripe.
                                    Attention sur l'app store et le play store
                                    vous ne pouvez pas mettre n'importe quel
                                    prix.
                                </div>
                                <br />

                                <h6>Android store</h6>

                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en €"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.eur.android.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.eur.android = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en $"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.usd.android.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.usd.android = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en ₽"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.rub.android.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.rub.android = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>

                                <h6>Apple store</h6>

                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en €"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.eur.ios.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.eur.ios = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en $"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.usd.ios.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.usd.ios = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en ₽"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.rub.ios.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.rub.ios = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>

                                <h6>Stripe</h6>

                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en €"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.eur.stripe.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.eur.stripe = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en $"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.usd.stripe.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.usd.stripe = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Nouveau prix en ₽"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15"
                                            min={3}
                                            type="number"
                                            value={this.state.prices.rub.stripe.toString()}
                                            onChange={(msg) => {
                                                let p = this.state.prices
                                                p.rub.stripe = msg.target.value
                                                this.setState({
                                                    prices: p,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Id du coupon"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="Ex: 15STVALENTIN"
                                            min={3}
                                            value={this.state.stripe_coupon.toString()}
                                            onChange={(msg) => {
                                                this.setState({
                                                    stripe_coupon:
                                                        msg.target.value,
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="filter">
                        <h5>Traductions</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Langue</th>
                                            <th>Nom</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            'es',
                                            'us',
                                            'en',
                                            'de',
                                            'it',
                                            'ru',
                                        ].map((lang: any) => {
                                            return (
                                                <tr>
                                                    <td>{lang}</td>
                                                    <td>
                                                        <InputGroup
                                                            id="text-input"
                                                            placeholder="Ex: "
                                                            min={3}
                                                            value={(
                                                                this.state
                                                                    .names as any
                                                            )[lang].toString()}
                                                            onChange={(msg) => {
                                                                let names: any =
                                                                    this.state
                                                                        .names
                                                                names[lang] =
                                                                    msg.target.value
                                                                this.setState({
                                                                    names: names,
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextArea
                                                            growVertically={
                                                                true
                                                            }
                                                            large={true}
                                                            placeholder="Ex: Carimmat se mobilise pour votre bonheur. -15% jusqu'au 28 février. N'hésitez plus!"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                            className="bp3-fill"
                                                            onChange={(msg) => {
                                                                let descriptions: any =
                                                                    this.state
                                                                        .descriptions
                                                                descriptions[
                                                                    lang
                                                                ] =
                                                                    msg.target.value
                                                                this.setState({
                                                                    descriptions:
                                                                        descriptions,
                                                                })
                                                            }}
                                                            value={
                                                                (
                                                                    this.state
                                                                        .descriptions as any
                                                                )[lang]
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <ButtonLoad
                    loading={this.state.loading}
                    callback={() => {
                        if (!this.state.loading) {
                            this.setState(
                                {
                                    loading: true,
                                },
                                () => this.add()
                            )
                        }
                    }}
                    enable="secondary"
                >
                    <b style={{ fontSize: 14 }}>
                        <FaPlus /> &nbsp;&nbsp;&nbsp;Ajouter la promotion
                    </b>
                </ButtonLoad>
            </div>
        )
    }
}

export default Add
