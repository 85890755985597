import Axios from 'axios'
import React from 'react'
import { FaBell, FaPlay } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import WidgetStatNombre from '../../components/widget-stat-nombre/widget-stat-nombre'
import Stats from '../../entity/stats'
import system from '../../system/system'

import './dashboard.css'

class Dashboard extends React.Component<
    any,
    {
        loading: boolean
        restart_servers: boolean
        nbusers: number
        nbbugs: number
        nbbugsnotdone: number
        nbnewusers: number
        nbpremiums: number
        nbpremiumsencours: number
        nbreports: number
        nbreportsnotdone: number
    }
> {
    state = {
        loading: true,
        restart_servers: false,
        nbusers: 0,
        nbbugs: 0,
        nbbugsnotdone: 0,
        nbnewusers: 0,
        nbpremiums: 0,
        nbpremiumsencours: 0,
        nbreports: 0,
        nbreportsnotdone: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        Stats.home((res: any) => {
            if (res.status === 'success') {
                this.setState({
                    nbbugs: res.data.nbbugs,
                    nbbugsnotdone: res.data.nbbugsnotdone,
                    nbusers: res.data.nbusers,
                    nbnewusers: res.data.nbnewusers,
                    nbpremiums: res.data.nbpremiums,
                    nbpremiumsencours: res.data.nbpremiumsencours,
                    nbreports: res.data.nbreports,
                    nbreportsnotdone: res.data.nbreportsnotdone,
                    loading: false,
                })
            }
        })
    }

    restartServerHttps = async (server_ip: String) => {
        await Axios.post(
            'https://' + server_ip + '/v1.1/system/restart/' + system.jwt,
            {},
            {
                headers: {
                    Authorization: system.jwt,
                },
            }
        )
            .then(() => {
                this.setState({
                    restart_servers: false,
                })
            })
            .catch((err) => {
                this.setState({
                    restart_servers: false,
                })
            })
    }

    public render() {
        return (
            <div id="dashboard">
                <div id="welcome">
                    <div className="avatar">
                        <img alt="avatar" src={system.userobject.avatar}></img>
                        <div>
                            <h3>Bon retour, {system.userobject.firstname}!</h3>
                            <p>
                                <FaBell /> Vous n'avez pas de notifications ou
                                de nouvelles tâches
                            </p>
                        </div>
                    </div>
                    <div></div>
                </div>

                <div id="stats">
                    <div id="numbers_main">
                        <WidgetStatNombre
                            value={this.state.nbusers}
                            subtitle={'Utilisateurs inscrits'}
                            footer_value={this.state.nbnewusers}
                            footer_title={'New users today'}
                            loading={this.state.loading}
                            color={'#333281'}
                            title="Nb d'utilisateurs"
                        />
                        <WidgetStatNombre
                            value={this.state.nbpremiums}
                            subtitle={'Premiums actuellement'}
                            footer_value={this.state.nbpremiumsencours}
                            footer_title={'Premiums en cours'}
                            loading={this.state.loading}
                            color={'#dd3e62'}
                            title="Nombre de premiums"
                        />
                        <WidgetStatNombre
                            value={this.state.nbbugsnotdone}
                            subtitle={'Nb bugs non traités'}
                            footer_value={this.state.nbbugs}
                            footer_title={'Nb bugs au total'}
                            loading={this.state.loading}
                            color={'#ff6b6b'}
                            title="Nombre de bugs non traités"
                        />
                        <WidgetStatNombre
                            value={this.state.nbreportsnotdone}
                            subtitle={'Nb reports non traités'}
                            footer_value={this.state.nbreports}
                            footer_title={'Nb reports au total'}
                            loading={this.state.loading}
                            color={'#feca57'}
                            title="Nombre de reports non traités"
                        />
                    </div>

                    <div id="last_users">
                        <div id="nb_sign_ups"></div>
                        <div id="last_users_list"></div>
                    </div>

                    <div>
                        <ButtonLoad
                            loading={this.state.restart_servers}
                            callback={() => {
                                this.setState(
                                    {
                                        restart_servers: true,
                                    },
                                    async () => {
                                        await this.restartServerHttps(
                                            'rest-ru.carimmat.com'
                                        )
                                        this.setState({
                                            restart_servers: false,
                                        })
                                    }
                                )
                            }}
                            enable="primary"
                        >
                            <b style={{ fontSize: 15 }}>
                                <FaPlay /> &nbsp;&nbsp;&nbsp;Redémarrer les
                                serveurs
                            </b>
                        </ButtonLoad>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard
