import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Bugs {

    /**
     * Get ads statistics
     */
    public static list = async (
        page: number,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/help/bugs/list?skip=' + page * 20 + '&limit=20',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static done = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/help/bugs/' + uuid + "/done",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
}

export default Bugs
