import React from 'react'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'

import './payments.css'
import Stats from '../../entity/stats'
import WidgetStatNombreGraph from '../../components/widget-stat-nombre-graph/widget-stat-nombre-graph'
import ButtonLoad from '../../components/button-load/button-load'
import { FaAndroid, FaApple, FaPlay, FaStripe } from 'react-icons/fa'
import moment from 'moment'
import PaymentEntity from '../../entity/payment'
import ReactPaginate from 'react-paginate'
import system from '../../system/system'
import { Link } from 'react-router-dom'

class Payments extends React.Component<
    any,
    {
        payments: any
        loading: boolean
        filtre: boolean
        nbpremiumsperday: any
        page: number
        nbpremiumstotal: number
        nbachatstotal: number
        nbachatperday: any
        newpremium: number
        nbpremiumactifs: number
        nbpremiumactifsperday: any
        finpremium: number
        nbpaymentsfilter: number
        search: String
    }
> {
    state = {
        payments: [],
        loading: true,
        filtre: false,
        nbpremiumsperday: [],
        page: 0,
        nbpremiumstotal: 0,
        nbachatstotal: 0,
        newpremium: 0,
        nbpaymentsfilter: 0,
        nbpremiumactifs: 0,
        finpremium: 0,
        nbachatperday: [],
        nbpremiumactifsperday: [],
        search: '',
    }

    // Init page
    componentDidMount() {
        this.stats(() => {
            this.getPayments()
        })
    }

    // Get ads
    getPayments = () => {
        PaymentEntity.list(this.state.page, this.state.search, (res: any) => {
            this.setState({
                payments: res.data.payments.sort((a: any, b: any) =>
                    a.date > b.date ? -1 : 1
                ),
                nbpaymentsfilter: res.data.nbpayments,
                loading: false,
            })
        })
    }

    stats = (callback: any) => {
        Stats.payments(
            moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000') +
                'Z',
            moment().format('YYYY-MM-DDT23:59:59.000') + 'Z',
            (msg: any) => {
                let nbpremiumsperday: any = []
                let nbpremiumactifsperday: any = []
                let nbachatperday: any = []

                let i: any
                for (i in msg.data.nbpremiumperday) {
                    nbpremiumsperday.push({
                        date: i,
                        value: msg.data.nbpremiumperday[i],
                    })
                }

                for (i in msg.data.nbpremiumactifsperday) {
                    nbpremiumactifsperday.push({
                        date: i,
                        value: msg.data.nbpremiumactifsperday[i],
                    })
                }

                for (i in msg.data.nbachatperday) {
                    nbachatperday.push({
                        date: i,
                        value: msg.data.nbachatperday[i],
                    })
                }

                nbpremiumsperday = nbpremiumsperday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                nbpremiumactifsperday = nbpremiumactifsperday.sort(
                    (a: any, b: any) =>
                        moment(a.date) > moment(b.date) ? 1 : -1
                )

                nbachatperday = nbachatperday.sort((a: any, b: any) =>
                    moment(a.date) > moment(b.date) ? 1 : -1
                )

                this.setState(
                    {
                        nbpremiumstotal: msg.data.nbpremiumtotal,
                        nbachatstotal: msg.data.nbpaymentstotal,
                        nbpremiumactifs: msg.data.abonnementsactifs,
                        nbpremiumsperday,
                        nbachatperday,
                        nbpremiumactifsperday,
                    },
                    callback
                )
            }
        )
    }

    public render() {
        return (
            <div id="payments">
                <div id="payments_header">
                    <div>
                        <h3>Dashboard premium</h3>
                        <p>
                            Retrouver toutes les données concernant les
                            paiements
                        </p>
                    </div>
                    <Button
                        onClick={() =>
                            this.setState({
                                filtre: true,
                            })
                        }
                        style={{ height: 30 }}
                    >
                        Refresh
                    </Button>
                </div>

                <div className="row">
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.nbpremiumsperday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Nombre de premiums"
                        value={this.state.nbpremiumstotal}
                    />

                    <WidgetStatNombreGraph
                        mainColor="#34D399"
                        color="#D1F5E8"
                        data={this.state.nbpremiumactifsperday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Nombre de premium actifs"
                        value={this.state.nbpremiumactifs}
                    />

                    <WidgetStatNombreGraph
                        mainColor="#FB7185"
                        color="#FEE1E5"
                        data={this.state.nbachatperday}
                        loading={this.state.loading}
                        period="30d"
                        pourcentage={-2}
                        title="Nombre d'achat"
                        value={this.state.nbachatstotal}
                    />
                </div>

                <div className="column">
                    <div id="filter">
                        <div id="filter_header">
                            <h5>Filtre</h5>
                        </div>

                        <div id="filter_body">
                            <div id="filter_pref">
                                <div className="bp3-callout bp3-intent-primary">
                                    <h4 className="bp3-heading">Information</h4>
                                    Dans le champ texte vous pouvez mettre
                                    n'importe quel mot pour rechercher par
                                    rapport au : user_id, id de la transaction,
                                    plateforme, status (refund, renew,
                                    no_renew).
                                </div>

                                <FormGroup
                                    helperText="Entrez un mot que vous voulez rechercher"
                                    label="Mot(s)"
                                    labelFor="text-input"
                                >
                                    <InputGroup
                                        id="text-input"
                                        placeholder="Mot à rechercher"
                                        max={100}
                                        min={10}
                                        value={this.state.search}
                                        onChange={(msg) => {
                                            this.setState({
                                                search: msg.target.value,
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <ButtonLoad
                            loading={this.state.loading}
                            callback={() => {
                                this.setState(
                                    {
                                        loading: true,
                                        page: 0,
                                    },
                                    () => this.getPayments()
                                )
                            }}
                            enable="secondary"
                        >
                            <b style={{ fontSize: 14 }}>
                                <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA RECHERCHE
                            </b>
                        </ButtonLoad>
                    </div>

                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Utilisateur</th>
                                    <th>Plateforme</th>
                                    <th>Produit</th>
                                    <th>Prix</th>
                                    <th>Renouvellement automatique</th>
                                    {/* <th>Supprimer</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? (
                                    <tr>
                                        <td colSpan={12}>
                                            <Spinner />
                                        </td>
                                    </tr>
                                ) : null}

                                {!this.state.loading
                                    ? this.state.payments.map((u: any) => {
                                          var cur = '€'
                                          switch (u.currency) {
                                              case 'eur':
                                                  cur = '€'
                                                  break

                                              case 'rub':
                                                  cur = '₽'
                                                  break

                                              case 'usd':
                                                  cur = '$'
                                                  break
                                          }

                                          return (
                                              <tr key={u.id}>
                                                  <td>
                                                      {system.getActualDateFromDateHourZ(
                                                          u.date
                                                      )}
                                                  </td>
                                                  {u.renew === true &&
                                                  u.refund !== true ? (
                                                      <td>
                                                          <label
                                                              style={{
                                                                  display:
                                                                      'inline-block',
                                                                  padding:
                                                                      '3px 10px',
                                                                  backgroundColor:
                                                                      '#ee5253',
                                                                  color: 'white',
                                                                  borderRadius:
                                                                      '15px',
                                                              }}
                                                          >
                                                              Renouvellement
                                                          </label>
                                                      </td>
                                                  ) : null}

                                                  {u.refund === true ? (
                                                      <td>
                                                          <label
                                                              style={{
                                                                  display:
                                                                      'inline-block',
                                                                  padding:
                                                                      '3px 10px',
                                                                  backgroundColor:
                                                                      '#8395a7',
                                                                  color: 'white',
                                                                  borderRadius:
                                                                      '15px',
                                                              }}
                                                          >
                                                              Remboursé
                                                          </label>
                                                      </td>
                                                  ) : null}

                                                  {u.refund !== true &&
                                                  u.renew !== true ? (
                                                      <td>
                                                          <label
                                                              style={{
                                                                  display:
                                                                      'inline-block',
                                                                  padding:
                                                                      '3px 10px',
                                                                  backgroundColor:
                                                                      '#0abde3',
                                                                  color: 'white',
                                                                  borderRadius:
                                                                      '15px',
                                                              }}
                                                          >
                                                              Nouveau
                                                          </label>
                                                      </td>
                                                  ) : null}
                                                  <td>
                                                      <Link
                                                          to={
                                                              'users/' +
                                                              u.user_id
                                                          }
                                                      >
                                                          {u.user_id}
                                                      </Link>
                                                  </td>
                                                  <td>
                                                      {u.os === 'android' ? (
                                                          <FaAndroid
                                                              color="#9FC037"
                                                              size={20}
                                                          />
                                                      ) : u.os === 'ios' ? (
                                                          <FaApple
                                                              color="#000"
                                                              size={20}
                                                          />
                                                      ) : u.os === 'stripe' ? (
                                                          <FaStripe
                                                              color="#626CD9"
                                                              size={20}
                                                          />
                                                      ) : null}
                                                  </td>
                                                  <td>{u.product}</td>
                                                  <td>
                                                      <label
                                                          style={{
                                                              display:
                                                                  'inline-block',
                                                              padding:
                                                                  '3px 10px',
                                                              backgroundColor:
                                                                  u.refund ===
                                                                  true
                                                                      ? '#8395a7'
                                                                      : '#10ac84',
                                                              color: 'white',
                                                              borderRadius:
                                                                  '15px',
                                                          }}
                                                      >
                                                          +{' '}
                                                          {cur === '$'
                                                              ? cur
                                                              : ''}{' '}
                                                          {u.price}{' '}
                                                          {cur !== '$'
                                                              ? cur
                                                              : ''}
                                                      </label>
                                                  </td>
                                                  <td>
                                                      <label
                                                          style={
                                                              u.auto_renew ===
                                                              true
                                                                  ? {
                                                                        display:
                                                                            'inline-block',
                                                                        padding:
                                                                            '3px 10px',
                                                                        backgroundColor:
                                                                            '#10ac84',
                                                                        color: 'white',
                                                                        borderRadius:
                                                                            '15px',
                                                                    }
                                                                  : {}
                                                          }
                                                      >
                                                          {u.auto_renew === true
                                                              ? 'Oui'
                                                              : '-'}
                                                      </label>
                                                  </td>
                                              </tr>
                                          )
                                      })
                                    : null}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Suivant >"
                                onPageChange={(e) => {
                                    console.log('pagechange', e)
                                    this.setState(
                                        {
                                            loading: true,
                                            page: e.selected,
                                        },
                                        () => this.getPayments()
                                    )
                                }}
                                pageRangeDisplayed={5}
                                pageCount={Math.floor(
                                    this.state.nbpaymentsfilter / 20
                                )}
                                previousLabel="< Précédent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Payments
