import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class ConvsEntity {
    /**
     * Login password
     * @param page mail of the user
     */
     public static list = async (
        page: number,
        premium: boolean,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.0/conversations/bots?skip=' + page * 20 + '&limit=20&premium=' + premium,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }

    /**
     * Login password
     * @param page mail of the user
     */
    public static messages = async (
        page: number,
        convid: string,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.0/conversations/' + convid + '/messages/admin?skip=' + page * 20 + '&limit=20',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }

    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static send = async (
        id: string,
        user_id: string,
        message: string,
        callback: any
    ) => {
        const formData = new FormData();
        formData.append("conv", id);
        formData.append("message", message);
        formData.append("user_id", user_id);

        Axios.put(
            API_URL + '/v1.0/messages/admin',
            formData,
            system.headerswithjwtformdata()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }

}

export default ConvsEntity
