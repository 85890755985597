import React from 'react'
import Login from '../screens/home/login'
import './signin.css'

class SignIn extends React.Component<
    { logincompleted: boolean },
    { nbusers: number }
> {
    public render() {
        return (
            <div id="signin">
                <div id="sidebarleft">
                    <div
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src="/assets/images/logo/logo_blue.png"
                            title="Logo"
                            alt="logo"
                        />
                    </div>
                    <div>
                        <h1>Welcome to Carimmat admin!</h1>
                        <p>
                            This website is only for carimmat employee. You
                            won't be able to create an account.
                        </p>
                    </div>
                    <div id="footer">
                        <div>© 2021 Carimmat</div>
                        <div>
                            <ul>
                                <li>
                                    <button>Privacy</button>
                                </li>
                                <li>
                                    <button>Legal</button>
                                </li>
                                <li>
                                    <button>Contact</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="container">
                    <h1>Login account</h1>
                    <div id="logincomponent">
                        <Login logincompleted={this.props.logincompleted} />
                    </div>
                </div>
            </div>
        )
    }
}

export default SignIn
