import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Ads {
    /**
     * Get ads statistics
     */
    public static ads = async (
        start: String,
        end: String,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/ads/stats/' + start + "/" + end,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Login password
     */
     public static enable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/ads/' + uuid + "/enable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static get = async (
        id: number,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/ads/' + id + "/get",
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     */
    public static disable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/ads/' + uuid + "/disable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     */
     public static add = async (
        name: string,
        description: string,
        link: string,
        entreprise: string,
        lang: string,
        start: string,
        end: string,
        price: number,
        minage: number,
        maxage: number,
        sexe: number,
        location: any,
        distance: number,
        country: string,
        picture: Blob | undefined,
        picture_portrait: Blob | undefined,
        callback: any
    ) => {
        const formData = new FormData();
        if(picture !== undefined) {
            formData.append("picture", picture);
        }
        if(picture_portrait !== undefined) {
            formData.append("picture_portrait", picture_portrait);
        }
        formData.append("name", name);
        formData.append("link", link);
        formData.append("entreprise", entreprise);
        formData.append("lang", lang);
        formData.append("start", start);
        formData.append("end", end);
        formData.append("price", price.toString());
        formData.append("minage", minage.toString());
        formData.append("maxage", maxage.toString());
        formData.append("sexe", sexe.toString());
        formData.append("location", location);
        formData.append("description", description);
        formData.append("distance", distance.toString());
        formData.append("country", country);

        Axios.put(
            API_URL + '/v1.0/ads',
            formData,
            system.headerswithjwtformdata()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     */
    public static edit = async (
        id: string,
        name: string,
        description: string,
        link: string,
        entreprise: string,
        lang: string,
        start: string,
        end: string,
        price: number,
        minage: number,
        maxage: number,
        sexe: number,
        location: any,
        distance: number,
        country: string,
        picture: Blob | undefined,
        picture_portrait: Blob | undefined,
        callback: any
    ) => {
        const formData = new FormData();
        if(picture !== undefined) {
            formData.append("picture", picture);
        }
        if(picture_portrait !== undefined) {
            formData.append("picture_portrait", picture_portrait);
        }
        formData.append("name", name);
        formData.append("link", link);
        formData.append("entreprise", entreprise);
        formData.append("lang", lang);
        formData.append("start", start);
        formData.append("end", end);
        formData.append("price", price.toString());
        formData.append("minage", minage.toString());
        formData.append("maxage", maxage.toString());
        formData.append("sexe", sexe.toString());
        formData.append("location", location);
        formData.append("description", description);
        formData.append("distance", distance.toString());
        formData.append("country", country);

        Axios.post(
            API_URL + '/v1.0/ads/' + id + "/edit",
            formData,
            system.headerswithjwtformdata()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
}

export default Ads
