import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class UserEntity {
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static loginPassword = async (
        mail: string,
        password: string,
        callback: any
    ) => {
        if (!mail) {
            throw new Error('mailismissing')
        }
        if (!password) {
            throw new Error('passwordismissing')
        }

        Axios.post(
            API_URL + '/v1.0/users/login/admin',
            {
                email: mail,
                password,
            },
            system.headers
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static list = async (
        page: number,
        search: String,
        search_personnal: String,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.0/users/list?skip=' + page * 20 + '&limit=20&search=' + search + "&search_personnal=" + search_personnal,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }
    
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static get = async (
        user_id: number,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.0/users/admin/' + user_id + '/get',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static ban = async (
        id: String,
        callback: any
    ) => {

        Axios.post(
            API_URL + '/v1.0/users/' + id + '/ban',
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static editCredits = async (
        id: String,
        credits: String,
        callback: any
    ) => {

        Axios.post(
            API_URL + '/v1.0/users/' + id + '/credits',
            {
                val: credits
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static givePremium = async (
        id: String,
        premium_gifted: boolean,
        premium_end: String,
        platform: String,
        transac_id: String,
        date_paiement: String,
        product: String,
        currency: String,
        callback: any
    ) => {

        Axios.post(
            API_URL + '/v1.0/users/' + id + '/premium',
            {
                premium_gifted,
                premium_end,
                transac_id,
                platform,
                date_paiement,
                currency,
                product,
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param page mail of the user
     */
     public static removePremium = async (
        id: String,
        callback: any
    ) => {

        Axios.delete(
            API_URL + '/v1.0/users/' + id + '/premium',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }


    public _id: string = ''
    public id: string = ''
    public uuid: string = ''
    public mail: string = ''
    public email_clair: string = ''
    public firstname: string = ''
    public avatar: string = ''
    public name: string = ''
    public rights: string = ''
    public birth_date: string = ''
    public city: string = ''
    public country: string = ''
    public created_at: string = ''
    public credits: string = ''
    public description: string = ''
    public last_connexion: string = ''
    public api_token: string = ''
    public platform: string = ''
    public premium_from: string = ''
    public premium_start: string = ''
    public premium_end: string = ''
    public stored: string = ''
    public stripe_id: string = ''
    public swipe_available: string = ''
    public location: any = {
        coordinates: []
    }
    public params: any = {
        attirance: 0,
        distance: 0,
        distance_type: 'km',
        geoloc: 0,
        invisible: 0,
        maxage: 0,
        minage: 0,
        search: 'weWillSee',
        trusted: false,
    }
    public age: number = 0
    public premium_account: number = 0
    public sex: number = 0
    public status: number = 0
    public premium_gifted: boolean = false
}

export default UserEntity
