import React from 'react'
import WidgetTitle from '../../components/widget-title/widget-title'
import SystemEntity from '../../entity/system'
import { version } from '../../../package.json'

import './dashboard.css'
import moment from 'moment'
import system from '../../system/system'

class DevDashboard extends React.Component<
    {},
    {
        loading: boolean
        situation: any
    }
> {
    state = {
        loading: true,
        situation: {
            version: '',
            need_update: false,
            system: {
                app_version: '',
                avatars: [],
                brands: [],
                countries: [],
                colors: [],
                tags: [],
                products: {},
            },
            activities: [],
        },
    }

    componentDidMount() {
        SystemEntity.situation((msg: any) => {
            this.setState({
                loading: false,
                situation: msg['data'],
            })
        })
    }

    public render() {
        return (
            <div id="dev_dashboard">
                <h3>Dashboard tech</h3>

                <div className="row">
                    <WidgetTitle
                        title="Config de l'app"
                        loading={this.state.loading}
                    >
                        <div className="table-responsive">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Version du bo</td>
                                        <td>{version}</td>
                                    </tr>
                                    <tr>
                                        <td>Version de l'api</td>
                                        <td>{this.state.situation.version}</td>
                                    </tr>
                                    <tr>
                                        <td>Version de l'app</td>
                                        <td>
                                            {
                                                this.state.situation.system
                                                    .app_version
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Mise à jour obligatoire</td>
                                        <td>
                                            {this.state.situation.need_update
                                                ? 'Oui'
                                                : 'Non'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </WidgetTitle>
                    <WidgetTitle
                        title="Config du système"
                        loading={this.state.loading}
                    >
                        <div className="table-responsive">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Avatars</td>
                                        <td>
                                            {
                                                this.state.situation.system
                                                    .avatars.length
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Countries</td>
                                        <td>
                                            {
                                                this.state.situation.system
                                                    .countries.length
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Marques</td>
                                        <td>
                                            {
                                                this.state.situation.system
                                                    .brands.length
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Colors</td>
                                        <td>
                                            {
                                                this.state.situation.system
                                                    .colors.length
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tags</td>
                                        <td>
                                            {
                                                this.state.situation.system.tags
                                                    .length
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Products</td>
                                        <td>
                                            {
                                                Object.keys(
                                                    this.state.situation.system
                                                        .products
                                                ).length
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </WidgetTitle>
                    <WidgetTitle
                        title="Dernières bugs détectés"
                        loading={this.state.loading}
                    ></WidgetTitle>
                    <WidgetTitle
                        title="Dernières actitivés"
                        loading={this.state.loading}
                    >
                        <div className="table-responsive">
                            <table>
                                <tbody>
                                    {this.state.situation.activities
                                        .sort((a: any, b: any) => {
                                            return moment(a).isAfter(moment(b))
                                                ? 1
                                                : 0
                                        })
                                        .map((activity: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <span
                                                            className={
                                                                'type_request ' +
                                                                activity.type.toLowerCase()
                                                            }
                                                        >
                                                            {activity.type}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {system.getDateZ(
                                                            activity.date
                                                        )}
                                                    </td>
                                                    <td>{activity.route}</td>
                                                    <td>
                                                        {activity.duration}ms
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </WidgetTitle>
                </div>
            </div>
        )
    }
}

export default DevDashboard
