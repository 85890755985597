export const arrondi = function(number: number, decimal_quantity: number): number{
    number *= Math.pow(10, decimal_quantity)
    number = Math.round(number)
    number /= Math.pow(10, decimal_quantity)

    return number;
}

/// When we want the day or month with obligatory two numbers (for exemple the month 9 --> 09)
export const get_month_or_day_two_number = function(month_or_day: String): String{
    if(month_or_day.length === 1){
        return `0${month_or_day}`
    }

    return month_or_day
}

export const percentage = function(value1: number, value2: number): number{        
    return (value2 - value1) / value1 * 100.
}
