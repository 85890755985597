import React from 'react'
import N from '../../entity/notifs'
import system from '../../system/system'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { Button } from '@blueprintjs/core'
import {
    FaChartArea,
    FaEdit,
    FaEye,
    FaEyeSlash,
    FaPaperPlane,
    FaPlus,
} from 'react-icons/fa'
import moment from 'moment'

import './notifs.css'

class Notifs extends React.Component<
    {},
    {
        campaigns: any
        loading: boolean
        nbcampaigns: number
        page: number
    }
> {
    state = {
        campaigns: [],
        nbcampaigns: 0,
        loading: true,
        page: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        N.list(this.state.page, (msg: any) => {
            console.log(msg)
            this.setState({
                campaigns: msg['data']['campaigns'].sort((a: any, b: any) =>
                    moment(a.send_date) > moment(b.send_date) ? -1 : 1
                ),
                nbcampaigns: msg['data']['nbcampaigns'],
                loading: false,
            })
        })
    }

    enable = (_id: string) => {
        N.enable(_id, (msg: any) => {
            if (msg.data === true) {
                let campaigns: any = this.state.campaigns
                for (let u in campaigns) {
                    if (campaigns[u]._id === _id) {
                        campaigns[u].enabled = true
                    }
                }

                this.setState({
                    campaigns,
                })
            }
        })
    }

    disable = (_id: string) => {
        N.disable(_id, (msg: any) => {
            if (msg.data === true) {
                let campaigns: any = this.state.campaigns
                for (let u in campaigns) {
                    if (campaigns[u]._id === _id) {
                        campaigns[u].enabled = false
                    }
                }

                this.setState({
                    campaigns,
                })
            }
        })
    }

    public render() {
        return (
            <div id="notifs" className="p20">
                <div id="notifs_header">
                    <div>
                        <h3>Notifications</h3>
                        <p>
                            Retrouver toutes les notifications envoyées aux
                            utilisateurs et celles qui sont programmées.
                        </p>
                    </div>

                    <div>
                        <Link to="/notifs/add" className="disabled">
                            <Button
                                intent="primary"
                                onClick={() => {}}
                                style={{ height: 30, marginRight: 30 }}
                            >
                                <FaPlus /> Ajouter une notification
                            </Button>
                        </Link>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date prévue d'envoi</th>
                            <th>Status</th>
                            <th>Nom</th>
                            <th>Cibles potentiels</th>
                            <th>Nb reçus</th>
                            <th>Nb clicks</th>
                            <th>Envoyer aux testeurs</th>
                            <th>Enable</th>
                            <th>Modifier/Stats</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.campaigns.map((k: any) => {
                            let status
                            let disabled = false
                            if (k.enabled === false) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-default">
                                        Brouillon
                                    </span>
                                )
                            } else if (moment().isBefore(moment(k.send_date))) {
                                status = (
                                    <span className="bp3-tag bp3-large bp3-intent-warning">
                                        Prêt
                                    </span>
                                )
                            } else if (moment().isAfter(moment(k.send_date))) {
                                if (k.done) {
                                    status = (
                                        <span className="bp3-tag bp3-large bp3-intent-success">
                                            Envoyé
                                        </span>
                                    )
                                } else if (k.inprogress) {
                                    status = (
                                        <span className="bp3-tag bp3-large bp3-intent-warning">
                                            En cours
                                        </span>
                                    )
                                } else {
                                    status = (
                                        <span className="bp3-tag bp3-large bp3-intent-danger">
                                            Erreur lors de l'envoi
                                        </span>
                                    )
                                }
                                disabled = true
                            }

                            return (
                                <tr key={k._id}>
                                    <td>
                                        {system.getActualDateFromMoment(
                                            k.send_date
                                        )}
                                    </td>
                                    <td>{status}</td>
                                    <td>{k.name}</td>
                                    <td>{k.nb_targets}</td>
                                    <td>{k.nb_received}</td>
                                    <td>{k.nb_clicks}</td>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                N.sendTesters(k._id, () => {})
                                            }}
                                        >
                                            <FaPaperPlane />
                                        </Button>
                                    </td>
                                    {!disabled ? (
                                        <td>
                                            {k.enabled ? (
                                                <Button
                                                    onClick={() =>
                                                        this.disable(k._id)
                                                    }
                                                >
                                                    <FaEyeSlash />
                                                </Button>
                                            ) : (
                                                <Button
                                                    intent="primary"
                                                    onClick={() =>
                                                        this.enable(k._id)
                                                    }
                                                >
                                                    <FaEye />
                                                </Button>
                                            )}
                                        </td>
                                    ) : (
                                        <td></td>
                                    )}
                                    <td>
                                        {!disabled ? (
                                            <Link
                                                to={
                                                    '/notifs/' + k._id + '/edit'
                                                }
                                                className="disabled"
                                            >
                                                <Button intent="none">
                                                    <FaEdit />
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link
                                                to={
                                                    '/notifs/' +
                                                    k._id +
                                                    '/stats'
                                                }
                                                className="disabled"
                                            >
                                                <Button intent="none">
                                                    <FaChartArea />
                                                </Button>
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Suivant >"
                        onPageChange={(e) => {
                            console.log('pagechange', e)
                            this.setState(
                                {
                                    loading: true,
                                    page: e.selected,
                                },
                                () => this.getData()
                            )
                        }}
                        pageRangeDisplayed={5}
                        pageCount={Math.floor(this.state.nbcampaigns / 20)}
                        previousLabel="< Précédent"
                    />
                </div>
            </div>
        )
    }
}

export default Notifs
