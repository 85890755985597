import React from 'react'
import Widget from '../widget/widget'
import ReactECharts from 'echarts-for-react'

import './widget-stat-nombre-graph-donut.css'
import { arrondi } from '../../system/functions'

class WidgetStatNombreGraphDonut extends React.Component<
    {
        loading: boolean
        title: String
        period: String
        max: number
        colors: any
        data: any
    },
    {}
> {
    render() {
        return (
            <Widget loading={this.props.loading}>
                <div className="widget-stat-nombre-graph-donut">
                    <div className="title">
                        <div>
                            <h3>{this.props.title}</h3>
                        </div>
                        <div>
                            <span className="period">{this.props.period}</span>
                        </div>
                    </div>
                    <div className="full">
                        <ReactECharts
                            option={{
                                tooltip: {
                                    trigger: 'item',
                                },
                                title: {
                                    show: false,
                                },
                                legend: {
                                    show: false,
                                },
                                series: [
                                    {
                                        type: 'pie',
                                        radius: ['40%', '60%'],
                                        animationEasing: 'quadraticOut',
                                        animationDuration: 1000,
                                        smooth: true,
                                        data: this.props.data,
                                    },
                                ],
                                color: this.props.colors,
                            }}
                            style={{
                                height: 200,
                                width: '100%',
                                padding: 0,
                                margin: 0,
                            }}
                            showLoading={this.props.loading}
                            theme={'theme_name'}
                        />
                    </div>
                    <div className="list">
                        <table>
                            <tbody>
                                {this.props.data.map((d: any, cpt: number) => {
                                    return (
                                        <tr>
                                            <td style={{ width: 20 }}>
                                                <div
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: 8,
                                                        backgroundColor:
                                                            this.props.colors[
                                                                cpt
                                                            ],
                                                    }}
                                                ></div>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        color: '#1E293B',
                                                    }}
                                                >
                                                    {d.name}
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: 16,
                                                        color: '#1E293B',
                                                    }}
                                                >
                                                    {d.value}
                                                </span>
                                            </td>
                                            <td>
                                                {arrondi(
                                                    (d.value / this.props.max) *
                                                        100,
                                                    0
                                                ) + '%'}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Widget>
        )
    }
}

export default WidgetStatNombreGraphDonut
