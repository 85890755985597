import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class SystemEntity {
    /**
     * Get swipeup statistics
     * @param start start date
     * @param end end date
     */
    public static situation = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.1/system/situation',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

    /**
     * Login password
     * @param page mail of the user
     */
     public static metrics = async (
        page: number,
        search: String,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.1/system/metrics/list?skip=' + page * 20 + '&limit=20&search=' + search,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }
}

export default SystemEntity