import React from 'react'
import Stats from '../../entity/stats'
import system from '../../system/system'

import 'react-datepicker/dist/react-datepicker.css'
import './maps.css'

class Maps extends React.Component<
    any,
    {
        nbusersbycountry: any
        nbusersbycountry_lastweek: any
        posusers: any
        posbots: any
        maxusers: number
        loading: boolean
        filtre: boolean
    }
> {
    state = {
        nbusersbycountry: [],
        nbusersbycountry_lastweek: [],
        posusers: [],
        posbots: [],
        maxusers: 0,
        loading: true,
        filtre: false,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        Stats.maps((res: any) => {
            let arr: any = []
            let arr_tmp: any = {}
            let maxusers = 0
            Object.keys(res.data.nbusersbycountry).map((k: any) => {
                arr.push({
                    country: k,
                    nb: res.data.nbusersbycountry[k][0],
                    flag: res.data.nbusersbycountry[k][1],
                })
                maxusers += res.data.nbusersbycountry[k][0]

                return arr
            })

            Object.keys(res.data.nbusersbycountry_lastweek).map((k: any) => {
                arr_tmp[k] = res.data.nbusersbycountry_lastweek[k][0]

                return arr_tmp
            })

            this.setState({
                nbusersbycountry: arr.sort((a: any, b: any) =>
                    a.nb < b.nb ? 1 : -1
                ),
                nbusersbycountry_lastweek: arr_tmp,
                maxusers: maxusers,
                posusers: res.data.posusers,
                posbots: res.data.posbots,
                loading: false,
            })
        })
    }

    public render() {
        return (
            <div id="maps">
                <div id="maps_header">
                    <div>
                        <h3>Analytics géographique</h3>
                        <p>
                            Retrouver toutes les stats nécessaire à la partie
                            géographique.
                        </p>
                    </div>
                    {/* <Button
                        onClick={() =>
                            this.setState({
                                filtre: !this.state.filtre,
                            })
                        }
                        style={{ height: 30 }}
                    >
                        Filtre
                    </Button> */}
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Drapeau</th>
                            <th>Nom</th>
                            <th>Nombre</th>
                            <th>Evolution</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.nbusersbycountry.map((k: any) => {
                            return (
                                <tr key={k.country}>
                                    <td>
                                        <img
                                            src={
                                                'https://flagcdn.com/16x12/' +
                                                k.flag.toLowerCase() +
                                                '.png'
                                            }
                                            width="16"
                                            height="12"
                                            alt="lang"
                                        />
                                    </td>
                                    <td className="left">{k.country}</td>
                                    <td>
                                        {k.nb} (
                                        {system.roundFloat(
                                            (k.nb * 100) / this.state.maxusers
                                        )}
                                        %)
                                    </td>
                                    <td>
                                        {this.state.nbusersbycountry_lastweek[
                                            k.country
                                        ] > 0 ? (
                                            <span>
                                                +
                                                {
                                                    this.state
                                                        .nbusersbycountry_lastweek[
                                                        k.country
                                                    ]
                                                }{' '}
                                                dernière semaine
                                            </span>
                                        ) : null}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Maps
