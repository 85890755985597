import React from 'react'
import ButtonLoad from '../../components/button-load/button-load'
import Axios from 'axios'
import { API_URL } from '../../system/const'
import system from '../../system/system'
import {
    FaBroom,
    FaComments,
    FaFileImport,
    FaGem,
    FaPlay,
} from 'react-icons/fa'
import Convs from '../../entity/convs'

import './bots.css'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import ConvsEntity from '../../entity/convs'

class Bots extends React.Component<
    any,
    {
        startbot: boolean
        startbotdev: boolean
        startbotimport: boolean
        loading: boolean
        pcv5min15minperday: any
        pcv5min15min: number
        pcv2h4hperday: any
        pcv2h4h: number
        pcv4h8hperday: any
        pcv4h8h: number
        pcv8h12hperday: any
        pcv8h12h: number
        page: number
        nbconvsfilter: number
        convs: any
        messages: any
        loading_messages: any
        loading_envoi: boolean
        premium: boolean
    }
> {
    state = {
        startbot: false,
        startbotdev: false,
        startbotimport: false,
        loading: true,
        pcv5min15minperday: [],
        pcv5min15min: 5,
        pcv2h4hperday: [],
        pcv2h4h: 15,
        pcv4h8hperday: [],
        pcv4h8h: 15,
        pcv8h12hperday: [],
        pcv8h12h: 15,
        page: 0,
        nbconvsfilter: 0,
        convs: [],
        messages: [],
        loading_messages: [],
        loading_envoi: false,
        premium: false,
    }

    componentDidMount() {
        this.getConvs()
    }

    // Get ads
    getConvs = () => {
        Convs.list(this.state.page, this.state.premium, (res: any) => {
            this.setState({
                convs: res.data.convs.sort((a: any, b: any) => {
                    return moment(a.date).isAfter(moment(b.date))
                }),
                nbconvsfilter: res.data.nbconvs,
                loading: false,
            })
        })
    }

    public render() {
        return (
            <div id="bots" className="p20">
                <div id="bots_header">
                    <h3>Bots</h3>
                    <p>Retrouver toutes les données concernant les bots</p>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <ButtonLoad
                            loading={this.state.startbot}
                            callback={() => {
                                this.setState(
                                    {
                                        startbot: true,
                                    },
                                    () => {
                                        Axios.post(
                                            API_URL + '/v1.0/bots/run',
                                            {},
                                            {
                                                headers: {
                                                    Authorization: system.jwt,
                                                },
                                            }
                                        )
                                            .then(() => {
                                                this.setState({
                                                    startbot: false,
                                                })
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    startbot: false,
                                                })
                                            })
                                    }
                                )
                            }}
                            enable="primary"
                        >
                            <b style={{ fontSize: 15 }}>
                                <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LES BOTS
                            </b>
                        </ButtonLoad>

                        <ButtonLoad
                            loading={this.state.startbot}
                            callback={() => {
                                this.setState(
                                    {
                                        startbot: true,
                                    },
                                    () => {
                                        Axios.post(
                                            API_URL + '/v1.0/bots/import',
                                            {},
                                            {
                                                headers: {
                                                    Authorization: system.jwt,
                                                },
                                            }
                                        )
                                            .then(() => {
                                                this.setState({
                                                    startbot: false,
                                                })
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    startbot: false,
                                                })
                                            })
                                    }
                                )
                            }}
                            enable="secondary"
                        >
                            <b style={{ fontSize: 15 }}>
                                <FaFileImport /> &nbsp;&nbsp;&nbsp;IMPORTER LES
                                BOTS
                            </b>
                        </ButtonLoad>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <ButtonLoad
                            loading={this.state.startbotdev}
                            callback={() => {
                                this.setState(
                                    {
                                        startbotdev: true,
                                    },
                                    () => {
                                        Axios.get(
                                            API_URL +
                                                '/v1.0/bots/cron/remove_already_visited',
                                            system.headerswithjwt()
                                        )
                                            .then(() => {
                                                this.setState({
                                                    startbotdev: false,
                                                })
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    startbotdev: false,
                                                })
                                            })
                                    }
                                )
                            }}
                            enable="enable"
                        >
                            <b style={{ fontSize: 15 }}>
                                <FaBroom /> &nbsp;&nbsp;&nbsp;CLEAN ALREADY
                                VISITED
                            </b>
                        </ButtonLoad>
                    </div>
                </div>

                <div id="bots_header" style={{ marginTop: 60 }}>
                    <h3>Conversations</h3>
                    <p>
                        Retrouvrer tous les messages envoyés aux bots par les
                        utilisateurs
                    </p>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <ButtonLoad
                        loading={this.state.loading}
                        callback={() => {
                            this.setState(
                                {
                                    loading: true,
                                    premium: !this.state.premium,
                                },
                                () => {
                                    this.getConvs()
                                }
                            )
                        }}
                        enable="primary"
                    >
                        <b style={{ fontSize: 15 }}>
                            <FaGem /> &nbsp;&nbsp;&nbsp;
                            {!this.state.premium
                                ? 'Uniquement les premiums'
                                : 'Tous les utilisateurs'}
                        </b>
                    </ButtonLoad>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Utilisateur</th>
                            <th>Last message date</th>
                            <th>Last message</th>
                            <th>Bot</th>
                            <th>Réponse rapide</th>
                            <th>Access to messages</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.loading ? (
                            <tr>
                                <td colSpan={12}>
                                    <Spinner />
                                </td>
                            </tr>
                        ) : null}

                        {!this.state.loading
                            ? this.state.convs.map((u: any) => {
                                  return (
                                      <tr key={u.uuid}>
                                          <td className="picture">
                                              <Link to={'users/' + u.user_id}>
                                                  {u.avatar !== '' &&
                                                  u.avatar !== null ? (
                                                      <img
                                                          src={u.avatar.replace(
                                                              'http://cdn-eu.carimmat.com',
                                                              'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                                          )}
                                                          alt="avatar"
                                                          className="logo"
                                                      />
                                                  ) : (
                                                      ''
                                                  )}
                                                  <br />
                                                  {u.firstname}
                                              </Link>
                                          </td>
                                          <td>
                                              {u.last_date_message !== '' &&
                                                  system.getDateZ(
                                                      u.last_date_message
                                                  )}
                                          </td>
                                          <td>
                                              {u.last_message}
                                              {u.photo !== '' ? (
                                                  <img
                                                      alt="msg_picture"
                                                      className="message_picture"
                                                      src={u.photo.replace(
                                                          'http://cdn-eu.carimmat.com',
                                                          'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                                      )}
                                                  />
                                              ) : null}
                                              {u.unread_messages > 0 ? (
                                                  <span className="unread_notifs">
                                                      {u.unread_messages}
                                                  </span>
                                              ) : null}
                                          </td>
                                          <td className="picture">
                                              <Link to={'users/' + u.bot_id}>
                                                  {u.bot_avatar !== '' &&
                                                  u.bot_avatar !== null ? (
                                                      <img
                                                          src={u.bot_avatar.replace(
                                                              'http://cdn-eu.carimmat.com',
                                                              'https://storage.gra.cloud.ovh.net/v1/AUTH_e73004733901435795750088fb2d7ac5/prod'
                                                          )}
                                                          alt="avatar"
                                                          className="logo"
                                                      />
                                                  ) : (
                                                      ''
                                                  )}
                                                  <br />
                                                  {u.bot_firstname}
                                              </Link>
                                          </td>
                                          <td>
                                              {this.state.loading_messages.indexOf(
                                                  u.conv_id as never
                                              ) === -1 ? (
                                                  <FormGroup
                                                      helperText=""
                                                      label=""
                                                      labelFor="text-input"
                                                  >
                                                      <InputGroup
                                                          id="text-input"
                                                          placeholder="Type your message"
                                                          value={
                                                              this.state
                                                                  .messages[
                                                                  u.conv_id
                                                              ]
                                                          }
                                                          onChange={(msg) => {
                                                              let messages: any =
                                                                  this.state
                                                                      .messages
                                                              messages[
                                                                  u.conv_id
                                                              ] =
                                                                  msg.target.value

                                                              this.setState({
                                                                  messages:
                                                                      messages,
                                                              })
                                                          }}
                                                          onKeyDown={(
                                                              event
                                                          ) => {
                                                              if (
                                                                  event.key ===
                                                                      'Enter' &&
                                                                  this.state
                                                                      .messages[
                                                                      u.conv_id
                                                                  ] !== ''
                                                              ) {
                                                                  let loading_messages =
                                                                      this.state
                                                                          .loading_messages
                                                                  loading_messages.push(
                                                                      u.conv_id as never
                                                                  )
                                                                  this.setState(
                                                                      {
                                                                          loading_messages:
                                                                              loading_messages,
                                                                      },
                                                                      () => {
                                                                          ConvsEntity.send(
                                                                              u.conv_id,
                                                                              u.user_id,
                                                                              this
                                                                                  .state
                                                                                  .messages[
                                                                                  u
                                                                                      .conv_id
                                                                              ],
                                                                              (
                                                                                  msg: any
                                                                              ) => {
                                                                                  if (
                                                                                      msg[
                                                                                          'status'
                                                                                      ] ===
                                                                                      'success'
                                                                                  ) {
                                                                                      let messages: any =
                                                                                          this
                                                                                              .state
                                                                                              .messages
                                                                                      messages[
                                                                                          u.conv_id
                                                                                      ] =
                                                                                          ''

                                                                                      let convs =
                                                                                          this.state.convs.filter(
                                                                                              (
                                                                                                  conv: any
                                                                                              ) =>
                                                                                                  conv.conv_id !==
                                                                                                  u.conv_id
                                                                                          )

                                                                                      let loading_messages =
                                                                                          this.state.convs.filter(
                                                                                              (
                                                                                                  conv: any
                                                                                              ) =>
                                                                                                  conv.conv_id !==
                                                                                                  u.conv_id
                                                                                          )

                                                                                      this.setState(
                                                                                          {
                                                                                              loading_messages:
                                                                                                  loading_messages,
                                                                                              convs: convs,
                                                                                              messages:
                                                                                                  messages,
                                                                                          },
                                                                                          () => {
                                                                                              this.getConvs()
                                                                                          }
                                                                                      )
                                                                                  } else {
                                                                                      this.setState(
                                                                                          {
                                                                                              loading_envoi:
                                                                                                  false,
                                                                                          }
                                                                                      )
                                                                                  }
                                                                              }
                                                                          )
                                                                      }
                                                                  )
                                                              }
                                                          }}
                                                      />
                                                  </FormGroup>
                                              ) : (
                                                  <Spinner />
                                              )}
                                          </td>
                                          <td>
                                              <Link
                                                  to={'/chat/' + u.conv_id}
                                                  className="disabled"
                                              >
                                                  <Button intent="none">
                                                      <FaComments />
                                                  </Button>
                                              </Link>
                                          </td>
                                      </tr>
                                  )
                              })
                            : null}
                    </tbody>
                </table>
                <div className="pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={(e) => {
                            console.log('pagechange', e)
                            this.setState(
                                {
                                    loading: true,
                                    page: e.selected,
                                },
                                () => this.getConvs()
                            )
                        }}
                        pageRangeDisplayed={5}
                        pageCount={Math.floor(this.state.nbconvsfilter / 20)}
                        previousLabel="< Prev"
                    />
                </div>
            </div>
        )
    }
}

export default Bots
