import React from 'react'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'

import './marketing.css'
import moment from 'moment'
import Stats from '../../entity/stats'
import WidgetStatNombreSmall from '../../components/widget-stat-nombre-small/widget-stat-nombre-small'
import WidgetStatNombreGraphDonut from '../../components/widget-stat-nombre-donut/widget-stat-nombre-graph-donut'
import ButtonLoad from '../../components/button-load/button-load'
import { FaPlay } from 'react-icons/fa'
import DatePicker from 'react-datepicker'
import { arrondi } from '../../system/functions'

class Marketing extends React.Component<
    any,
    {
        users: any
        loading: boolean
        filtre: boolean
        timingactivitesbyhour: any
        timingactivites: number
        timinginscriptionbyhour: any
        timinginscription: number
        timingachatsbyhour: any
        timingachats: number
        startdate: moment.Moment
        enddate: moment.Moment
        repartitionhommefemmeother: any
        repartitionusersfinishonboarding: any
        repartitionusersavatars: any
        repartitionfemmeavecavatar: any
        repartitionhommeavecavatar: any
        repartitionotheravecavatar: any
        repartitionlocations: any
        repartitionagebygenderpremium: any
        nbusersplatform: number
        nbusers: number
        nbusersothers: number
        nbuserswomen: number
        nbusersmen: number
        nbuserspremium: number
        nbusersfinishonboarding: number
        snapchat: number
        tiktok: number
        google: number
        fbinsta: number
        spotify: number
        twitter: number
        nbnouveauxusers: number
        nbpaiements: number
        repartitionmembreage: any
        repartitionplatform: any
    }
> {
    state = {
        nbnouveauxusers: 0,
        snapchat: 0,
        nbpaiements: 0,
        tiktok: 0,
        google: 0,
        fbinsta: 0,
        spotify: 0,
        twitter: 0,
        users: [],
        loading: true,
        filtre: false,
        startdate: moment().subtract(30, 'days'),
        enddate: moment(),
        timingactivitesbyhour: [],
        timingactivites: 0,
        nbuserspremium: 0,
        nbusersplatform: 0,
        nbusers: 0,
        nbuserswomen: 0,
        nbusersmen: 0,
        nbusersothers: 0,
        nbusersfinishonboarding: 0,
        timinginscriptionbyhour: [],
        timinginscription: 0,
        timingachatsbyhour: [],
        timingachats: 0,
        timingachatsinscriptionbyday: [],
        timingachatsinscription: 0,
        repartitionhommefemmeother: [],
        repartitionusersfinishonboarding: [],
        repartitionlocations: [],
        repartitionusersavatars: [],
        repartitionfemmeavecavatar: [],
        repartitionhommeavecavatar: [],
        repartitionotheravecavatar: [],
        repartitionmembreage: [],
        repartitionagebygenderpremium: [],
        repartitionplatform: [],
    }

    // Init page
    componentDidMount() {
        this.stats()
    }

    // Get ads
    stats = () => {
        Stats.marketing(
            this.state.startdate.format('YYYY-MM-DDT00:00:00.000') + 'Z',
            this.state.enddate.format('YYYY-MM-DDT23:59:59.000') + 'Z',
            (msg: any) => {
                let repartitionhommefemmeother: any = []
                let repartitionusersfinishonboarding: any = []
                let repartitionusersavatars: any = []
                let repartitionhommeavecavatar: any = []
                let repartitionfemmeavecavatar: any = []
                let repartitionotheravecavatar: any = []
                let repartitionlocations: any = []
                let repartitionmembreage: any = []
                let repartitionagebygenderpremium: any = []
                let repartitionplatform: any = []
                let nbuserspremium = 0

                repartitionhommefemmeother.push({
                    value: msg.data.nbusersmen,
                    name: 'Homme',
                })
                repartitionhommefemmeother.push({
                    value: msg.data.nbuserswomen,
                    name: 'Femme',
                })
                repartitionhommefemmeother.push({
                    value: msg.data.nbusersothers,
                    name: 'Other',
                })

                repartitionusersfinishonboarding.push({
                    value: msg.data.nbusersfinishonboarding,
                    name: 'Fini',
                })
                repartitionusersfinishonboarding.push({
                    value: msg.data.nbusers - msg.data.nbusersfinishonboarding,
                    name: 'Pas fini',
                })

                repartitionusersavatars.push({
                    value: msg.data.nbavatars,
                    name: 'Avatar',
                })
                repartitionusersavatars.push({
                    value:
                        msg.data.nbusersfinishonboarding - msg.data.nbavatars,
                    name: "Pas d'avatar",
                })

                repartitionfemmeavecavatar.push({
                    value: msg.data.nbavatarswomen,
                    name: 'Avatar',
                })
                repartitionfemmeavecavatar.push({
                    value: msg.data.nbuserswomen - msg.data.nbavatarswomen,
                    name: "Pas d'avatar",
                })

                repartitionhommeavecavatar.push({
                    value: msg.data.nbavatarsmen,
                    name: 'Avatar',
                })
                repartitionhommeavecavatar.push({
                    value: msg.data.nbusersmen - msg.data.nbavatarsmen,
                    name: "Pas d'avatar",
                })

                repartitionotheravecavatar.push({
                    value: msg.data.nbavatarsothers,
                    name: 'Avatar',
                })
                repartitionotheravecavatar.push({
                    value: msg.data.nbusersothers - msg.data.nbavatarsothers,
                    name: "Pas d'avatar",
                })

                repartitionlocations.push({
                    value: msg.data.nblocations,
                    name: 'Position GPS',
                })
                repartitionlocations.push({
                    value:
                        msg.data.nbusersfinishonboarding - msg.data.nblocations,
                    name: 'Pas de position GPS',
                })

                repartitionplatform.push({
                    value: msg.data.nbusersios,
                    name: 'Ios',
                })
                repartitionplatform.push({
                    value: msg.data.nbusersplatform - msg.data.nbusersios,
                    name: 'Android',
                })

                for (let i in msg.data.repartitionagebygender) {
                    if (i !== '' && i !== 'Unknown') {
                        repartitionmembreage.push({
                            value: msg.data.repartitionagebygender[i],
                            name: i,
                        })
                    }
                }

                for (let i in msg.data.repartitionagebygenderpremium) {
                    if (i !== '' && i !== 'Unknown') {
                        repartitionagebygenderpremium.push({
                            value: msg.data.repartitionagebygenderpremium[i],
                            name: i,
                        })

                        nbuserspremium +=
                            msg.data.repartitionagebygenderpremium[i]
                    }
                }

                this.setState({
                    loading: false,
                    nbpaiements: msg.data.nbpaiements,
                    nbnouveauxusers: msg.data.nbnouveauxusers,
                    nbusers: msg.data.nbusers,
                    nbusersplatform: msg.data.nbusersplatform,
                    repartitionhommefemmeother,
                    repartitionusersfinishonboarding,
                    repartitionusersavatars,
                    repartitionfemmeavecavatar,
                    repartitionotheravecavatar,
                    repartitionhommeavecavatar,
                    nbusersothers: msg.data.nbusersothers,
                    nbuserswomen: msg.data.nbuserswomen,
                    nbusersmen: msg.data.nbusersmen,
                    nbusersfinishonboarding: msg.data.nbusersfinishonboarding,
                    repartitionlocations,
                    repartitionmembreage,
                    repartitionagebygenderpremium,
                    nbuserspremium,
                    repartitionplatform,
                })
            }
        )
    }

    public render() {
        return (
            <div id="marketing">
                <div id="marketing_header">
                    <div>
                        <h3>Analytics marketing</h3>
                        <p>
                            Retrouver toutes les stats nécessaire à la partie
                            marketing.
                        </p>
                    </div>
                    <Button
                        onClick={() =>
                            this.setState({
                                filtre: !this.state.filtre,
                            })
                        }
                        style={{ height: 30 }}
                    >
                        Filtre
                    </Button>
                </div>

                {this.state.filtre ? (
                    <div className="filter">
                        <h5>Filtre</h5>

                        <div className="filter_body">
                            <div className="filter_pref">
                                <div className="row">
                                    <FormGroup
                                        helperText=""
                                        label="Date de début"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.startdate.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    startdate: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsStart
                                            startDate={this.state.startdate.toDate()}
                                            endDate={this.state.enddate.toDate()}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        helperText=""
                                        label="Date de fin"
                                        labelFor="text-input"
                                    >
                                        <DatePicker
                                            selected={this.state.enddate.toDate()}
                                            onChange={(date) =>
                                                this.setState({
                                                    enddate: moment(
                                                        date?.toString()
                                                    ),
                                                })
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            selectsEnd
                                            startDate={this.state.startdate.toDate()}
                                            endDate={this.state.enddate.toDate()}
                                            minDate={this.state.startdate.toDate()}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="row">
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur Snapchat"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur Snapchat"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.snapchat.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        snapchat: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur TikTok"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur TikTok"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.tiktok.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        tiktok: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur Twitter"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur Twitter"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.twitter.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        twitter: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur Facebook / Insta"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur Facebook / Insta"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.fbinsta.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        fbinsta: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur Google"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur Google"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.google.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        google: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup
                                            helperText=""
                                            label="Dépense sur Spotify"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                id="text-input"
                                                placeholder="Dépense sur Spotify"
                                                type="number"
                                                leftIcon="euro"
                                                max={100}
                                                min={10}
                                                value={this.state.spotify.toString()}
                                                onChange={(msg) => {
                                                    this.setState({
                                                        spotify: parseInt(
                                                            msg.target.value
                                                        ),
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <ButtonLoad
                                    loading={this.state.loading}
                                    callback={() => {
                                        this.setState(
                                            {
                                                loading: true,
                                                filtre: false,
                                            },
                                            () => {
                                                this.stats()
                                            }
                                        )
                                    }}
                                    enable="secondary"
                                >
                                    <b style={{ fontSize: 14 }}>
                                        <FaPlay /> &nbsp;&nbsp;&nbsp;LANCER LA
                                        RECHERCHE
                                    </b>
                                </ButtonLoad>
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* <div className="row">
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.timingactivitesbyhour}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Timing des activités"
                        value={this.state.timingactivites}
                    />
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.timinginscriptionbyhour}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Timing d'inscription"
                        value={this.state.timinginscription}
                    />
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.timingachatsbyhour}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Timing des achats"
                        value={this.state.timingachats}
                    />
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.timingachatsinscriptionbyday}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Timing des l'achat après inscription"
                        value={this.state.timingachatsinscription}
                    />
                </div> */}

                <div className="row">
                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de paiements"
                        value={this.state.nbpaiements}
                    />

                    <WidgetStatNombreSmall
                        loading={this.state.loading}
                        title="Nombre de nouveaux utilisateurs"
                        value={this.state.nbnouveauxusers}
                    />

                    <WidgetStatNombreSmall
                        help="Ce chiffre est le ratio du nombre de paiements par le nombre de nouveaux utilisateurs"
                        loading={this.state.loading}
                        title="Taux de conversion"
                        value={arrondi(
                            (100 * this.state.nbpaiements) /
                                this.state.nbnouveauxusers,
                            2
                        )}
                        after_value="%"
                    />

                    <WidgetStatNombreSmall
                        help="Ce chiffre est le ratio de la somme dépensée par le nombre de nouveaux utilisateurs"
                        loading={this.state.loading}
                        title="Coût d'acquisition utilisateur (CAU)"
                        value={arrondi(
                            (this.state.spotify +
                                this.state.tiktok +
                                this.state.fbinsta +
                                this.state.google +
                                this.state.snapchat) /
                                this.state.nbnouveauxusers,
                            2
                        )}
                        after_value="€"
                    />

                    <WidgetStatNombreSmall
                        help="Ce chiffre est le ratio de la somme dépensée par le nombre de nouveaux clients"
                        loading={this.state.loading}
                        title="Coût d'acquisition client (CAC)"
                        value={arrondi(
                            (this.state.spotify +
                                this.state.tiktok +
                                this.state.fbinsta +
                                this.state.google +
                                this.state.snapchat) /
                                this.state.nbpaiements,
                            2
                        )}
                        after_value="€"
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreGraphDonut
                        colors={['#74b9ff', '#fd79a8']}
                        data={this.state.repartitionhommefemmeother}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Ratio homme / femme"
                        max={
                            this.state.nbusersmen +
                            this.state.nbuserswomen +
                            this.state.nbusersothers
                        }
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#10ac84', '#ff7675']}
                        data={this.state.repartitionusersfinishonboarding}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Taux d'utilisateurs qui ont terminé l'inscription"
                        max={this.state.nbusers}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionusersavatars}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Taux d'utilisateurs qui ont un avatar"
                        max={this.state.nbusersfinishonboarding}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionplatform}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Répartition des plateformes"
                        max={this.state.nbusersplatform}
                    />
                </div>
                <div className="row">
                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionfemmeavecavatar}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Nombre de femmes avec une photo"
                        max={this.state.nbuserswomen}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionhommeavecavatar}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Nombre d'hommes avec une photo"
                        max={this.state.nbusersmen}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionotheravecavatar}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Nombre d'autres avec une photo"
                        max={this.state.nbusersothers}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={['#9c88ff', '#dcdde1']}
                        data={this.state.repartitionlocations}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Nombre d'utilisateurs avec une position"
                        max={this.state.nbusersfinishonboarding}
                    />
                </div>

                <div className="row">
                    <WidgetStatNombreGraphDonut
                        colors={[
                            '#9c88ff',
                            '#dcdde1',
                            '#fbc531',
                            '#487eb0',
                            '#00a8ff',
                            '#e84118',
                            '#273c75',
                        ]}
                        data={this.state.repartitionmembreage}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Membres segment d'âge"
                        max={this.state.nbusersfinishonboarding}
                    />

                    <WidgetStatNombreGraphDonut
                        colors={[
                            '#9c88ff',
                            '#dcdde1',
                            '#fbc531',
                            '#487eb0',
                            '#00a8ff',
                            '#e84118',
                            '#273c75',
                        ]}
                        data={this.state.repartitionagebygenderpremium}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Premium segment d'âge"
                        max={this.state.nbuserspremium}
                    />
                    {/* 
                    <WidgetStatNombreGraphDonut
                        colors={[]}
                        data={this.state.timingactivitesbyhour}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        title="Actifs segment d'âge"
                        max={this.state.timingactivites}
                    /> */}
                </div>
                {/* <div className="row">
                    <WidgetStatNombreGraph
                        mainColor="#38BDF8"
                        color="#C7ECFD"
                        data={this.state.timingactivitesbyhour}
                        loading={this.state.loading}
                        period={
                            this.state.enddate.diff(
                                this.state.startdate,
                                'days'
                            ) + ' jours'
                        }
                        pourcentage={-2}
                        title="Evolution du nombre d'actifs"
                        value={this.state.timingactivites}
                    />
                </div> */}
            </div>
        )
    }
}

export default Marketing
