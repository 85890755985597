import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'
import { version } from '../../../package.json'

import {
    FaAd,
    FaAt,
    FaBan,
    FaBong,
    FaBug,
    FaGlobeEurope,
    FaGoogleWallet,
    FaHome,
    FaHotjar,
    FaLanguage,
    FaMap,
    FaMeteor,
    FaMobile,
    FaMoneyBill,
    FaPaperPlane,
    FaPercentage,
    FaUser,
    FaUserCircle,
} from 'react-icons/fa'

import system from '../../system/system'

import './menu-left.css'
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core'

class MenuLeft extends React.Component<
    {
        logOut: () => void
        hidden: boolean
    },
    {
        mobile_menu_open: boolean
    }
> {
    public render() {
        switch (system.userobject.rights) {
            default:
                return (
                    <div
                        id="menu-left"
                        className={this.props.hidden ? 'hide' : ''}
                    >
                        <Scrollbars
                            autoHide
                            renderThumbVertical={({ style, ...props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...style,
                                        backgroundColor: '#777',
                                    }}
                                />
                            )}
                        >
                            <div id="sidebar-logo">
                                <Link to="/">
                                    <img
                                        src="/assets/images/logo/logo.png"
                                        alt="logo"
                                    />
                                </Link>

                                <Popover
                                    content={
                                        <Menu>
                                            <MenuItem
                                                onClick={this.props.logOut}
                                                text="Deconnexion"
                                            />
                                        </Menu>
                                    }
                                    position={Position.BOTTOM_RIGHT}
                                >
                                    <FaUserCircle size={25} color={'#fff'} />
                                </Popover>
                            </div>

                            <div className="avatar">
                                <img
                                    alt="avatar"
                                    src={system.userobject.avatar}
                                ></img>
                                <h3>{system.userobject.firstname}</h3>
                                <p>{system.userobject.mail}</p>
                                <span>v{version}</span>
                            </div>

                            <div className="flex-column">
                                <div className="category">Dashboards</div>
                                <div className="subcategory">
                                    Résumé rapide important
                                </div>

                                <Link to="/">
                                    <span className="icon-holder">
                                        <FaHome />
                                    </span>{' '}
                                    <span className="">Général</span>
                                </Link>

                                <Link to="/paiements">
                                    <span className="icon-holder">
                                        <FaMoneyBill />
                                    </span>{' '}
                                    Paiements
                                </Link>

                                <Link to="/users">
                                    <span className="icon-holder">
                                        <FaUser />
                                    </span>{' '}
                                    Users
                                </Link>

                                <div className="category">Analytics</div>
                                <div className="subcategory">
                                    Voir toutes les stats
                                </div>

                                <Link to="/financial">
                                    <span className="icon-holder">
                                        <FaMoneyBill />
                                    </span>{' '}
                                    Financial
                                </Link>

                                <Link to="/marketing">
                                    <span className="icon-holder">
                                        <FaGoogleWallet />
                                    </span>{' '}
                                    Marketing
                                </Link>

                                {/* <Link to="/convs" className="disabled">
                                    <span className="icon-holder">
                                        <FaFacebookMessenger />
                                    </span>{' '}
                                    Conversations
                                </Link> */}

                                <Link to="/maps">
                                    <span className="icon-holder">
                                        <FaMap />
                                    </span>{' '}
                                    Map
                                </Link>

                                {/* <Link to="/cars" className="disabled">
                                    <span className="icon-holder">
                                        <FaCar />
                                    </span>{' '}
                                    Voitures
                                </Link>

                                <Link to="/swipeup" className="disabled">
                                    <span className="icon-holder">
                                        <FaSleigh />
                                    </span>{' '}
                                    Swipeup
                                </Link> */}

                                <div className="category">Marketing</div>
                                <div className="subcategory">
                                    Configurer les éléments marketing de l'app
                                </div>

                                <Link to="/promotions">
                                    <span className="icon-holder">
                                        <FaPercentage />
                                    </span>{' '}
                                    Promotions
                                </Link>

                                <Link to="/ad">
                                    <span className="icon-holder">
                                        <FaAd />
                                    </span>{' '}
                                    Publicité
                                </Link>

                                <Link to="/notifs">
                                    <span className="icon-holder">
                                        <FaMobile />
                                    </span>{' '}
                                    Notifications
                                </Link>

                                <div className="category">App</div>
                                <div className="subcategory">
                                    Configurer l'application
                                </div>

                                <Link to="/traductions">
                                    <span className="icon-holder">
                                        <FaLanguage />
                                    </span>{' '}
                                    Traductions
                                </Link>

                                <Link to="/emails">
                                    <span className="icon-holder">
                                        <FaPaperPlane />
                                    </span>{' '}
                                    Emails
                                </Link>

                                <div className="category">Help</div>
                                <div className="subcategory">
                                    Problème sur l'app
                                </div>

                                <Link to="/reports">
                                    <span className="icon-holder">
                                        <FaBan />
                                    </span>{' '}
                                    Report
                                </Link>

                                <Link to="/bugs">
                                    <span className="icon-holder">
                                        <FaBug />
                                    </span>{' '}
                                    Bugs
                                </Link>

                                <Link to="/contacts">
                                    <span className="icon-holder">
                                        <FaAt />
                                    </span>{' '}
                                    Contacts
                                </Link>

                                <div className="category">Admin</div>
                                <div className="subcategory">
                                    Fonctionnalitées dangereuses
                                </div>

                                <Link to="/bots">
                                    <span className="icon-holder">
                                        <FaBong />
                                    </span>{' '}
                                    Bots
                                </Link>

                                <Link to="/users_around">
                                    <span className="icon-holder">
                                        <FaGlobeEurope />
                                    </span>{' '}
                                    Users around
                                </Link>

                                <div className="category">Technique</div>
                                <div className="subcategory">
                                    Uniquement réservé aux développeurs
                                </div>

                                <Link to="/dev_dashboard">
                                    <span className="icon-holder">
                                        <FaMeteor />
                                    </span>{' '}
                                    Dashboard
                                </Link>

                                <Link to="/metrics">
                                    <span className="icon-holder">
                                        <FaHotjar />
                                    </span>{' '}
                                    Performances
                                </Link>
                            </div>
                        </Scrollbars>
                    </div>
                )
        }
    }
}

export default MenuLeft
