import StatsUsers from '../components/stats-users/stats-users'
import Financial from '../screens/financial/financial'
import Bots from '../screens/bots/bots'
import Dashboard from '../screens/home/dashboard'
import Traduction from '../screens/traductions/traductions'
import UsersAround from '../screens/users-around/users-around'
import Users from '../screens/users/users'
import WatchUser from '../screens/users/watch'
import Marketing from '../screens/marketing/marketing'
import Payments from '../screens/payments/payments'
import Ad from '../screens/ad/ad'
import Maps from '../screens/maps/maps'
import Promotions from '../screens/promotions/promotions'
import AddPromotions from '../screens/promotions/add'
import EditPromotions from '../screens/promotions/edit'
import AddAd from '../screens/ad/add'
import EditAd from '../screens/ad/edit'
import Reports from '../screens/help/reports'
import Bugs from '../screens/help/bugs'
import Contacts from '../screens/help/contacts'
import AddNotifs from '../screens/marketing/add'
import Notifs from '../screens/marketing/notifs'
import EditNotifs from '../screens/marketing/edit'
import StatsNotifs from '../screens/marketing/stats'
import Emails from '../screens/emails/emails'
import AddEmails from '../screens/emails/add'
import EditEmails from '../screens/emails/edit'
import Messages from '../screens/chat/messages'
import DevDashboard from '../screens/dev/dashboard'
import Metrics from '../screens/dev/metrics'

class Navigator {
    public static getRoutes = () => {
        let routes = []

        routes = [
            {
                path: '/metrics',
                component: Metrics,
                exact: true,
            },
            {
                path: '/dev_dashboard',
                component: DevDashboard,
                exact: true,
            },
            {
                path: '/contacts',
                component: Contacts,
                exact: true,
            },
            {
                path: '/bugs',
                component: Bugs,
                exact: true,
            },
            {
                path: '/notifs/:id/stats',
                component: StatsNotifs,
                exact: true,
            },
            {
                path: '/chat/:id',
                component: Messages,
                exact: true,
            },
            {
                path: '/notifs/:id/edit',
                component: EditNotifs,
                exact: true,
            },
            {
                path: '/notifs/add',
                component: AddNotifs,
                exact: true,
            },
            {
                path: '/notifs',
                component: Notifs,
                exact: true,
            },
            {
                path: '/reports',
                component: Reports,
                exact: true,
            },
            {
                path: '/emails/:id/edit',
                component: EditEmails,
                exact: true,
            },
            {
                path: '/emails/add',
                component: AddEmails,
                exact: true,
            },
            {
                path: '/emails',
                component: Emails,
                exact: true,
            },
            {
                path: '/promotions/:id/edit',
                component: EditPromotions,
                exact: true,
            },
            {
                path: '/promotions/add',
                component: AddPromotions,
                exact: true,
            },
            {
                path: '/promotions',
                component: Promotions,
                exact: true,
            },
            {
                path: '/financial',
                component: Financial,
                exact: true,
            },
            {
                path: '/marketing',
                component: Marketing,
                exact: true,
            },
            {
                path: '/users/:id',
                component: WatchUser,
                exact: true,
            },
            {
                path: '/users',
                component: Users,
                exact: true,
            },
            {
                path: '/traductions',
                component: Traduction,
                exact: true,
            },
            {
                path: '/users_around',
                component: UsersAround,
                exact: true,
            },
            {
                path: '/maps',
                component: Maps,
                exact: true,
            },
            {
                path: '/users',
                component: StatsUsers,
                exact: true,
            },
            {
                path: '/paiements',
                component: Payments,
                exact: true,
            },
            {
                path: '/ad/:id/edit',
                component: EditAd,
                exact: true,
            },
            {
                path: '/ad/add',
                component: AddAd,
                exact: true,
            },
            {
                path: '/ad',
                component: Ad,
                exact: true,
            },
            {
                path: '/bots',
                component: Bots,
                exact: true,
            },
            {
                path: '/',
                component: Dashboard,
                exact: true,
            },
        ]

        return routes
    }
}

export default Navigator
