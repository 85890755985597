import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Promotions {
    /**
     * Get ads statistics
     */
    public static list = async (
        page: number,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/promotions/list?skip=' + page * 20 + '&limit=20',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static enable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/promotions/' + uuid + "/enable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static disable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/promotions/' + uuid + "/disable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static add = async (
        names: any,
        descriptions: any,
        start: String,
        end: String,
        type: String,
        value: String,
        link: String,
        stripe_coupon: String,
        prices: any,
        callback: any
    ) => {
        Axios.put(
            API_URL + '/v1.0/promotions',
            {
                names,
                descriptions,
                start,
                end,
                type,
                value,
                link,
                stripe_coupon,
                prices,
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static edit = async (
        id: String,
        names: any,
        descriptions: any,
        start: String,
        end: String,
        type: String,
        value: String,
        link: String,
        stripe_coupon: String,
        prices: any,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/promotions/' + id + "/edit",
            {
                names,
                descriptions,
                start,
                end,
                type,
                value,
                link,
                stripe_coupon,
                prices,
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Get ads statistics
     */
     public static get = async (
        id: String,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/promotions/' + id + "/get", 
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
}

export default Promotions
