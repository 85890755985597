import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Emails {
    /**
     * Get ads statistics
     */
    public static list = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.1/system/emails',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Get ads statistics
     */
    public static layouts = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.1/system/emails/layouts',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static get = async (
        id: number,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.1/system/emails/' + id + "/get",
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static send_testers = async (
        id: String,
        email: String,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.1/system/emails/' + id + "/send",
            {
                email
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static add = async (
        name: String,
        body: String,
        lang: String,
        subject: String,
        layout: String,
        is_layout: boolean,
        callback: any
    ) => {
        Axios.put(
            API_URL + '/v1.1/system/emails',
            {
                name,
                body,
                lang,
                subject,
                is_layout,
                layout,
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static edit = async (
        id: String,
        name: String,
        body: String,
        lang: String,
        subject: String,
        layout: String,
        is_layout: boolean,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.1/system/emails/' + id + '/edit',
            {
                name,
                body,
                lang,
                subject,
                is_layout,
                layout,
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
}

export default Emails
