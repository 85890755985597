import React from 'react'
import { FaHourglass } from 'react-icons/fa'

import './button-load.css'

class ButtonLoad extends React.Component<
    {
        request?: () => Promise<any>
        callback?: any
        enable: String | 'enable' | 'disable' | 'warning'
        loading: boolean
    },
    {
        loading: boolean
    }
> {
    state = {
        loading: false,
    }

    request = () => {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props.request) {
                    this.props
                        .request()
                        .then((res: any) => {
                            setTimeout(() => {
                                this.setState(
                                    {
                                        loading: false,
                                    },
                                    () => {
                                        this.props.callback(res)
                                    }
                                )
                            }, 1000)
                        })
                        .catch((err: any) => {
                            console.error(err)
                        })
                } else {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            this.props.callback()
                        }
                    )
                }
            }
        )
    }

    render() {
        return (
            <button
                className={'button-load ' + this.props.enable}
                onClick={() =>
                    !this.props.loading ? this.request() : () => {}
                }
            >
                {this.state.loading || this.props.loading ? (
                    <FaHourglass className="spin" size={15} />
                ) : (
                    this.props.children
                )}
            </button>
        )
    }
}

export default ButtonLoad
