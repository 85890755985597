import React from 'react'
import { arrondi } from '../../system/functions'
import P from '../../entity/promotions'

import './promotions.css'
import system from '../../system/system'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { FaEdit, FaEye, FaEyeSlash, FaPlus } from 'react-icons/fa'

class Promotions extends React.Component<
    {},
    {
        promotions: any
        page: number
    }
> {
    state = {
        promotions: [],
        page: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        P.list(this.state.page, (msg: any) => {
            this.setState({
                promotions: msg['data'],
            })
        })
    }

    enable = (_id: string) => {
        P.enable(_id, (msg: any) => {
            if (msg.data === true) {
                let promotions: any = this.state.promotions
                for (let u in promotions) {
                    if (promotions[u]._id === _id) {
                        promotions[u].status = 1
                    }
                }

                this.setState({
                    promotions,
                })
            }
        })
    }

    disable = (_id: string) => {
        P.disable(_id, (msg: any) => {
            if (msg.data === true) {
                let promotions: any = this.state.promotions
                for (let u in promotions) {
                    if (promotions[u]._id === _id) {
                        promotions[u].status = 0
                    }
                }

                this.setState({
                    promotions,
                })
            }
        })
    }

    public render() {
        return (
            <div id="promotions" className="p20">
                <div id="promotions_header">
                    <div>
                        <h3>Promotions</h3>
                        <p>Retrouver toutes les promotions dans l'app</p>
                    </div>

                    <div>
                        <Link to="/promotions/add" className="disabled">
                            <Button
                                intent="primary"
                                onClick={() => {}}
                                style={{ height: 30, marginRight: 30 }}
                            >
                                <FaPlus /> Ajouter une promotion
                            </Button>
                        </Link>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>Status</th>
                            <th>Nom</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Nombre de vues</th>
                            <th>Nombre d'achats liés</th>
                            <th>Activer/Désactiver</th>
                            <th>Modifier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.promotions.map((k: any) => {
                            return (
                                <tr key={k._id}>
                                    <td>
                                        {system.getDateWithoutHour(k.start)}
                                    </td>
                                    <td>{system.getDateWithoutHour(k.end)}</td>
                                    <td>
                                        {k.status === 1 ? (
                                            moment(k.start) > moment() ? (
                                                <span className="label label-warning">
                                                    En attente
                                                </span>
                                            ) : moment(k.end) > moment() ? (
                                                <span className="label label-success">
                                                    En cours
                                                </span>
                                            ) : (
                                                <span className="label label-danger">
                                                    Fini
                                                </span>
                                            )
                                        ) : (
                                            <span className="label label-default">
                                                Brouillon
                                            </span>
                                        )}
                                    </td>
                                    <td>{k.name}</td>
                                    <td>{k.description}</td>

                                    {k.type === 'reduction' ? (
                                        <td>
                                            <h4>Réduction des prix</h4>
                                            <p>
                                                Baisse de {arrondi(k.value, 2)}%
                                                des prix
                                            </p>
                                        </td>
                                    ) : null}

                                    <td>{k.nbviews}</td>
                                    <td>{k.nbachats}</td>
                                    <td>
                                        {k.status === 1 ? (
                                            <Button
                                                onClick={() =>
                                                    this.disable(k._id)
                                                }
                                            >
                                                <FaEyeSlash />
                                            </Button>
                                        ) : (
                                            <Button
                                                intent="primary"
                                                onClick={() =>
                                                    this.enable(k._id)
                                                }
                                            >
                                                <FaEye />
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        <Link
                                            to={
                                                '/promotions/' + k._id + '/edit'
                                            }
                                            className="disabled"
                                        >
                                            <Button intent="none">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Promotions
