import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class PaymentEntity {
    /**
     * Login password
     * @param page mail of the user
     */
     public static list = async (
        page: number,
        search: String,
        callback: any
    ) => {

        Axios.get(
            API_URL + '/v1.0/payments/list?skip=' + page * 20 + '&limit=20&search=' + search,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
            
    }
}

export default PaymentEntity
