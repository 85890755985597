import { FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'
import { FaArrowLeft, FaPaperPlane } from 'react-icons/fa'
import ButtonLoad from '../../components/button-load/button-load'
import ConvsEntity from '../../entity/convs'
import User from '../../entity/user'
import system from '../../system/system'

import './messages.css'

class Messages extends React.Component<
    {
        match: any
        history: any
    },
    {
        loading: boolean
        loading_envoi: boolean
        messages: any
        user: any
        message: String
    }
> {
    state = {
        loading: true,
        loading_envoi: false,
        messages: [],
        user: new User(),
        message: '',
    }

    componentDidMount() {
        this.getMessages()
    }

    getMessages = () => {
        ConvsEntity.messages(0, this.props.match.params.id, (msg: any) => {
            this.setState({
                messages: msg.data['messages'].sort((m1: any, m2: any) => {
                    return moment(m1.date).isAfter(moment(m2.date)) ? 1 : -1
                }),
                user: msg.data['user'],
                loading: false,
            })
        })
    }

    public render() {
        return (
            <div id="messages" className="p20">
                <div id="messages_header">
                    <button onClick={() => this.props.history.goBack()}>
                        <FaArrowLeft size={20} />
                    </button>
                    <img
                        alt="avatar"
                        className="avatar"
                        src={this.state.user.avatar}
                    />
                    <h3>
                        {this.state.user
                            ? this.state.user.firstname
                            : 'Unknown'}
                    </h3>
                </div>

                {this.state.loading ? <Spinner /> : null}

                <div id="content_messages">
                    {!this.state.loading
                        ? this.state.messages.map((u: any) => {
                              return (
                                  <div
                                      key={u.id}
                                      className={
                                          u.from !== this.state.user.id
                                              ? 'us'
                                              : 'them'
                                      }
                                  >
                                      <div className="content_message">
                                          {u.message}
                                      </div>
                                      {system.getDateZ(u.date)}
                                  </div>
                              )
                          })
                        : null}
                </div>

                <div id="messageBottom">
                    <FormGroup helperText="" label="" labelFor="text-input">
                        <InputGroup
                            id="text-input"
                            placeholder="Type your message"
                            value={this.state.message.toString()}
                            onChange={(msg) => {
                                this.setState({
                                    message: msg.target.value,
                                })
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    ConvsEntity.send(
                                        this.props.match.params.id,
                                        this.state.user.id,
                                        this.state.message,
                                        (msg: any) => {
                                            if (msg['status'] === 'success') {
                                                let m: any = this.state.messages
                                                m.push({
                                                    id: '',
                                                    from: 'carimmat',
                                                    message: this.state.message,
                                                    date: moment(),
                                                })
                                                this.setState({
                                                    message: '',
                                                    messages: m.sort(
                                                        (m1: any, m2: any) => {
                                                            return moment(
                                                                m1.date
                                                            ).isAfter(
                                                                moment(m2.date)
                                                            )
                                                        }
                                                    ),
                                                    loading_envoi: false,
                                                })
                                            } else {
                                                this.setState({
                                                    loading_envoi: false,
                                                })
                                            }
                                        }
                                    )
                                }
                            }}
                        />
                    </FormGroup>

                    <ButtonLoad
                        enable="enable"
                        loading={this.state.loading_envoi}
                        callback={() => {
                            if (this.state.message !== '') {
                                this.setState(
                                    {
                                        loading_envoi: true,
                                    },
                                    () => {
                                        ConvsEntity.send(
                                            this.props.match.params.id,
                                            this.state.user.id,
                                            this.state.message,
                                            (msg: any) => {
                                                if (
                                                    msg['status'] === 'success'
                                                ) {
                                                    let m: any =
                                                        this.state.messages
                                                    m.push({
                                                        id: '',
                                                        from: 'carimmat',
                                                        message:
                                                            this.state.message,
                                                        date: moment(),
                                                    })
                                                    this.setState({
                                                        message: '',
                                                        messages: m.sort(
                                                            (
                                                                m1: any,
                                                                m2: any
                                                            ) => {
                                                                return moment(
                                                                    m1.date
                                                                ).isAfter(
                                                                    moment(
                                                                        m2.date
                                                                    )
                                                                )
                                                            }
                                                        ),
                                                        loading_envoi: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        loading_envoi: false,
                                                    })
                                                }
                                            }
                                        )
                                    }
                                )
                            }
                        }}
                    >
                        <FaPaperPlane />
                    </ButtonLoad>
                </div>
            </div>
        )
    }
}

export default Messages
