// Local Server
let API_URL = 'https://rest.carimmat.com'
if (window.location.hostname === 'localhost') {
    API_URL = 'http://localhost:4000'
}
// } else if (window.location.hostname === 'bo-dev.carimmat.com') {
// //     API_URL = 'https://api-dev.carimmat.com'
// }

const COLOR_RED = '#ff6b6b'
const COLOR_GREEN = '#1dd1a1'

// Online Server
export { API_URL, COLOR_RED, COLOR_GREEN }
