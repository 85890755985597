import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Traductions {
    /**
     * Get all trad keys
     */
    public static all = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/traductions',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }

    /**
     * Save a key
     */
    public static save = async (
        key: any,
        callback: any
    ) => {
        Axios.put(
            API_URL + '/v1.0/traductions',
            key,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }

    /**
     * Export and return the zip with all trads
     */
    public static export = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/traductions/export',
            system.headerswithjwt({
                responseType: 'blob'
            })
        )
            .then((response: any) => {
                console.log(response)
                callback({
                    status: "success",
                    data: response.data
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
}

export default Traductions
