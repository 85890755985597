import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Campaigns {

    /**
     * Get marketing statistics
     * @param start start date
     * @param end end date
     */
    public static stats = async (
        id: String,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/campaigns/' + id + "/stats",
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })               
            })
    }

}

export default Campaigns
