import Axios from 'axios'
import { API_URL } from '../system/const'
import system from '../system/system'

class Notifs {

    /**
     * Get ads statistics
     */
    public static list = async (
        page: number,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/campaigns/?skip=' + page * 20 + '&limit=20',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    

    /**
     * Get ads statistics
     */
    public static get = async (
        id: string,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/campaigns/' + id,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Get ads statistics
     */
    public static getTesters = async (
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/campaigns/testers',
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Get ads statistics
     */
    public static sendTesters = async (
        id: String,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/campaigns/' + id + '/testers',
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Get ads statistics
     */
    public static sendNotif = async (
        token: String,
        title: String,
        body: String,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/notifications/token/' + token + '/send',
            {
                title,
                body
            },
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }

    /**
     * Get ads statistics
     */
    public static getNbTargets = async (
        agemin: number,
        agemax: number,
        platform: string,
        premium: string,
        genre: number,
        langs: any,
        callback: any
    ) => {
        Axios.get(
            API_URL + '/v1.0/campaigns/nb_targets?agemin=' + agemin + '&agemax=' + agemax + '&genre=' + genre + '&platform=' + platform + '&premium=' + premium + '&langs=' + langs,
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: "success",
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static done = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/help/bugs/' + uuid + "/done",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     */
     public static add = async (
        name: string,
        titre: any,
        texte: any,
        date: string,
        minage: number,
        maxage: number,
        premium: string,
        platform: string,
        internal_screen: string,
        link: string,
        attirance: number,
        nb_targets: number,
        icone: string,
        testers_chosen: any,
        picture: Blob | undefined,
        callback: any
    ) => {
        const formData = new FormData();
        if(picture !== undefined) {
            formData.append("picture", picture);
        }
        formData.append("name", name);
        formData.append("titre", JSON.stringify(titre));
        formData.append("texte", JSON.stringify(texte));
        formData.append("date", date);
        formData.append("minage", minage.toString());
        formData.append("maxage", maxage.toString());
        formData.append("premium", premium);
        formData.append("icone", icone);
        formData.append("nb_targets", nb_targets.toString());
        formData.append("platform", platform);
        formData.append("internal_screen", internal_screen);
        formData.append("link", link);
        formData.append("attirance", attirance.toString());
        formData.append("testers", testers_chosen);

        Axios.put(
            API_URL + '/v1.0/campaigns',
            formData,
            system.headerswithjwtformdata()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     */
     public static edit = async (
        id: string,
        name: string,
        titre: any,
        texte: any,
        date: string,
        minage: number,
        maxage: number,
        premium: string,
        platform: string,
        internal_screen: string,
        link: string,
        attirance: number,
        nb_targets: number,
        icone: string,
        testers_chosen: any,
        picture: Blob | undefined,
        callback: any
    ) => {
        const formData = new FormData();
        if(picture !== undefined) {
            formData.append("picture", picture);
        }
        formData.append("name", name);
        formData.append("titre", JSON.stringify(titre));
        formData.append("texte", JSON.stringify(texte));
        formData.append("date", date);
        formData.append("minage", minage.toString());
        formData.append("maxage", maxage.toString());
        formData.append("premium", premium);
        formData.append("icone", icone);
        formData.append("nb_targets", nb_targets.toString());
        formData.append("platform", platform);
        formData.append("internal_screen", internal_screen);
        formData.append("link", link);
        formData.append("attirance", attirance.toString());
        formData.append("testers", testers_chosen);

        Axios.post(
            API_URL + '/v1.0/campaigns/' + id,
            formData,
            system.headerswithjwtformdata()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
     public static enable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/campaigns/' + uuid + "/enable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
    /**
     * Login password
     * @param mail mail of the user
     * @param password password of the user
     */
    public static disable = async (
        uuid: string,
        callback: any
    ) => {
        Axios.post(
            API_URL + '/v1.0/campaigns/' + uuid + "/disable",
            {},
            system.headerswithjwt()
        )
            .then((response: any) => {
                callback({
                    status: response.data.status,
                    data: response.data.results
                })
            })
            .catch((error: any) => {
                callback({
                    status: "error",
                    data: null
                })              
            })
    }
    
}

export default Notifs
