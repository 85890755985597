import React from 'react'
import R from '../../entity/contacts'
import system from '../../system/system'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { FaCheck } from 'react-icons/fa'

import './contacts.css'

class Contacts extends React.Component<
    {},
    {
        contacts: any
        page: number
    }
> {
    state = {
        contacts: [],
        page: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        R.list(this.state.page, (msg: any) => {
            this.setState({
                contacts: msg['data'],
            })
        })
    }

    done = (_id: string) => {
        R.done(_id, (msg: any) => {
            if (msg.data === true) {
                let contacts: any = this.state.contacts
                for (let u in contacts) {
                    if (contacts[u]._id === _id) {
                        contacts[u].resolved = true
                    }
                }

                this.setState({
                    contacts,
                })
            }
        })
    }

    public render() {
        return (
            <div id="contacts" className="p20">
                <div id="contacts_header">
                    <div>
                        <h3>Contacts</h3>
                        <p>Retrouver tous les contacts</p>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date du report</th>
                            <th>Status</th>
                            <th>Titre</th>
                            <th>Message</th>
                            <th>Utilisateur</th>
                            <th>Fait</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.contacts.map((k: any) => {
                            return (
                                <tr key={k._id}>
                                    <td>{system.getDateWithoutHour(k.date)}</td>
                                    <td>
                                        {k.resolved === true ? (
                                            <span className="label label-success">
                                                Fait
                                            </span>
                                        ) : (
                                            <span className="label label-warning">
                                                A faire
                                            </span>
                                        )}
                                    </td>

                                    <td>{k.subject}</td>
                                    <td>{k.message}</td>
                                    <td>
                                        <Link to={'users/' + k.from}>
                                            {k.from}
                                        </Link>
                                    </td>

                                    <td>
                                        {k.resolved === false ? (
                                            <Button
                                                onClick={() => this.done(k._id)}
                                            >
                                                <FaCheck />
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Contacts
