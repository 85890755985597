import { Spinner, SpinnerSize } from '@blueprintjs/core'
import React from 'react'

import './loading.css'

class Loading extends React.Component<any, any> {
    public render() {
        return (
            <div className="loading">
                {this.props.enable ? (
                    <div id="loading">
                        <img src="/assets/images/logo/logo_blue.png" alt="logo" />

                        <Spinner size={SpinnerSize.STANDARD} intent={'none'} />

                        <p>Loading the system...</p>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default Loading
