import React from 'react'
import Traductions from '../../entity/traductions'
import { Button, Spinner } from '@blueprintjs/core'

import './traductions.css'
import { FaCheck, FaTimes } from 'react-icons/fa'
import fileDownload from 'js-file-download'

class InputTraduction extends React.Component<
    {
        onBlur: any
        loading: boolean
        success: boolean
        error: boolean
        defaultValue: string
    },
    {}
> {
    public render() {
        return (
            <div>
                <textarea
                    className="bp3-input"
                    defaultValue={this.props.defaultValue}
                    onBlur={(v) => this.props.onBlur(v.target.value)}
                />
                <div className="loading">
                    {this.props.loading ? <Spinner size={15} /> : null}
                    {this.props.success ? <FaCheck color={'green'} /> : null}
                    {this.props.error ? <FaTimes color={'red'} /> : null}
                </div>
            </div>
        )
    }
}

class Traduction extends React.Component<
    any,
    {
        traductions: any
        loadingKeys: String[]
        successKeys: String[]
        errorKeys: String[]
    }
> {
    state = {
        traductions: [],
        loadingKeys: [],
        successKeys: [],
        errorKeys: [],
    }

    // Init page
    componentDidMount() {
        this.getTraductions()
    }

    // Get ads
    getTraductions = () => {
        Traductions.all((res: any) => {
            this.setState({
                traductions: res.data,
            })
        })
    }

    changeValue = (_id: string, champ: string, value: string) => {
        let t: any = this.state.traductions
        for (let a of t) {
            if (a._id === _id && value !== a[champ]) {
                a[champ] = value
                a.modification = true
            }
        }
        this.setState({
            traductions: t,
        })
    }

    saveKey = (_id: string, champ: string) => {
        let t: any = this.state.traductions
        let v: any
        for (let a of t) {
            if (a._id === _id) {
                v = a
            }
        }

        if (v != null && v.modification === true) {
            let l: any = this.state.loadingKeys
            l.push(v._id + ';' + champ)

            this.setState(
                {
                    loadingKeys: l,
                    errorKeys: this.state.errorKeys.filter(function (k) {
                        return k !== v._id + ';' + champ
                    }),
                    successKeys: this.state.successKeys.filter(function (k) {
                        return k !== v._id + ';' + champ
                    }),
                },
                () =>
                    Traductions.save(v, (res: any) => {
                        let l: String[] = this.state.loadingKeys.filter(
                            function (k) {
                                return k !== v._id + ';' + champ
                            }
                        )

                        if (res.status === 'success' && res.data === true) {
                            let sl: String[] = this.state.successKeys
                            sl.push(v._id + ';' + champ)

                            this.setState({
                                loadingKeys: l,
                                successKeys: sl,
                            })
                        } else {
                            let el: String[] = this.state.errorKeys
                            el.push(v._id + ';' + champ)

                            this.setState({
                                loadingKeys: l,
                                errorKeys: el,
                            })
                        }
                    })
            )
        }
    }

    export = () => {
        Traductions.export((res: any) => {
            if (res.status === 'success') {
                fileDownload(res.data, 'all.zip')
            }
        })
    }

    public render() {
        return (
            <div id="traductions">
                <div id="traductions_header">
                    <div>
                        <h3>Liste des clés de traduction</h3>
                        <p>
                            Retrouver toutes les données concernant les
                            paiements
                        </p>
                    </div>
                    <Button onClick={this.export} style={{ height: 30 }}>
                        Exporter
                    </Button>
                </div>

                <div
                    id="table"
                    style={{
                        width: window.innerWidth - 400,
                        maxWidth: window.innerWidth - 400,
                        height: window.innerHeight - 195,
                    }}
                >
                    <table
                        style={{
                            height: window.innerHeight - 235,
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Clé - Interface</th>
                                <th>Description</th>
                                <th>Fr</th>
                                <th>En</th>
                                <th>Us</th>
                                <th>Es</th>
                                <th>Ru</th>
                                <th>It</th>
                                <th>Pt</th>
                                <th>De</th>
                                <th>Ch</th>
                                <th>Ja</th>
                            </tr>
                        </thead>
                        <tbody
                            style={{
                                width: window.innerWidth - 280,
                                maxWidth: window.innerWidth - 280,
                                height: window.innerHeight - 195,
                            }}
                        >
                            {this.state.traductions.map((a: any, i: number) => {
                                return (
                                    <tr key={a._id}>
                                        <td>{i}</td>
                                        <td>
                                            <input
                                                className="bp3-input bp3-disabled"
                                                disabled={true}
                                                style={{
                                                    marginBottom: 3,
                                                }}
                                                defaultValue={a.key}
                                                onBlur={(v) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'key',
                                                        v.target.value
                                                    )
                                                    this.saveKey(a._id, 'key')
                                                }}
                                            />
                                            <input
                                                className="bp3-input bp3-disabled"
                                                disabled={true}
                                                defaultValue={a.interface}
                                                onBlur={(v) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'interface',
                                                        v.target.value
                                                    )
                                                    this.saveKey(
                                                        a._id,
                                                        'interface'
                                                    )
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.description}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id +
                                                            ';description') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id +
                                                            ';description') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id +
                                                            ';description') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'description',
                                                        v
                                                    )
                                                    this.saveKey(
                                                        a._id,
                                                        'description'
                                                    )
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.fr}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';fr') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';fr') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';fr') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'fr',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'fr')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.en}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';en') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';en') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';en') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'en',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'en')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.us}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';us') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';us') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';us') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'us',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'us')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.es}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';es') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';es') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';es') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'es',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'es')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.ru}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';ru') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';ru') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';ru') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'ru',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'ru')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.it}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';it') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';it') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';it') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'it',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'it')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.pt}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';pt') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';pt') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';pt') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'pt',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'pt')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.de}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';de') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';de') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';de') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'de',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'de')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.ch}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';ch') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';ch') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';ch') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'ch',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'ch')
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputTraduction
                                                defaultValue={a.ja}
                                                error={
                                                    this.state.errorKeys.indexOf(
                                                        (a._id + ';ja') as never
                                                    ) !== -1
                                                }
                                                loading={
                                                    this.state.loadingKeys.indexOf(
                                                        (a._id + ';ja') as never
                                                    ) !== -1
                                                }
                                                success={
                                                    this.state.successKeys.indexOf(
                                                        (a._id + ';ja') as never
                                                    ) !== -1
                                                }
                                                onBlur={(v: string) => {
                                                    this.changeValue(
                                                        a._id,
                                                        'ja',
                                                        v
                                                    )
                                                    this.saveKey(a._id, 'ja')
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Traduction
